import React from 'react';
import * as Svg from '../../common/icons';
import tableData from './fakeTasks';
import '../../../styles/dashboard/TasksNotes.css';

const UpcomingTasks = () => {
  return (
    <table className='table-tasks'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col'>Category</th>
          <th scope='col'>Department</th>
          <th scope='col'>Location</th>
          <th scope='col'>Assigned To</th>
          <th scope='col'>Created By</th>
          <th scope='col'>Due Date</th>
          <th scope='col'></th>
          <th scope='col'></th>
          <th scope='col'></th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.title}</td>
                <td>{data.category}</td>
                <td>{data.dept}</td>
                <td>{data.location}</td>
                <td>{data.assignedTo}</td>
                <td>{data.createdBY}</td>
                <td>{data.dueDate}</td>
                <td className='p-0 text-right'>
                  <Svg.Repeat />
                </td>
                <td className='p-0 text-center'>
                  <Svg.Comment />
                </td>
                <td className='p-0 text-left'>
                  <Svg.Lock />
                </td>
                <td className='p-0 text-right'>
                  <button>Close</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default UpcomingTasks;
