import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { ClickAwayListener } from '@material-ui/core';
import * as Svg from '../../components/common/icons';
import '../../styles/SignUp/SignUp.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500
  },
  popoverRoot: {
    maxWidth: 1000,
    boxShadow: '0 15px 30px 20px rgb(172 180 184 / 20%) !important'
  },
  popper: {
    zIndex: 2000
  },
  popperRoot: {
    top: '6px !important',
    left: '24px !important'
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em',
    boxSizing: 'border-box',
    left: 0,
    marginLeft: '-.25rem',
    color: 'grey',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      backgroundColor: 'currentColor',
      transform: 'rotate(124deg)'
    }
  }
}));

export default function PositionedPopper({
  anchorEl,
  openPop,
  setOpenPop,
  passwordCheck
}) {
  const classes = useStyles();
  const arrow = true;
  const [arrowRef, setArrowRef] = useState(null);

  const getStyle = () => {
    let bgColor = '';
    if (
      passwordCheck.length &&
      passwordCheck.number &&
      passwordCheck.symbol &&
      passwordCheck.upperCase
    ) {
      bgColor = '#47B39C';
    } else {
      bgColor = '#DE6552';
    }
    return bgColor;
  };

  console.log(getStyle());
  return (
    <div className={classes.root}>
      <Popper
        className={classes.popperRoot}
        open={openPop}
        anchorEl={anchorEl}
        placement={'right'}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: arrow,
            element: arrowRef
          }
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenPop(false)}>
                <Paper
                  className={classes.popoverRoot}
                  style={{ background: getStyle() }}
                >
                  {arrow ? (
                    <span
                      className={classes.arrow}
                      ref={setArrowRef}
                      style={{ color: getStyle() }}
                    />
                  ) : null}
                  <div
                    className='pass-check'
                    style={{ backgroundColor: getStyle() }}
                  >
                    <div className='pass-msg d-flex align-items-center'>
                      <Svg.Tick
                        color={
                          passwordCheck.length === true
                            ? '#ffffff'
                            : 'RGBA(255, 255, 255, 0.35)'
                        }
                      />
                      <p
                        className='my-0'
                        style={{
                          color:
                            passwordCheck.length === true
                              ? '#ffffff'
                              : 'RGBA(255, 255, 255, 0.35)'
                        }}
                      >
                        Min 8 characters
                      </p>
                    </div>
                    <div className='pass-msg d-flex align-items-center'>
                      <Svg.Tick
                        color={
                          passwordCheck.upperCase === true
                            ? '#ffffff'
                            : 'RGBA(255, 255, 255, 0.35)'
                        }
                      />
                      <p
                        className='my-0'
                        style={{
                          color:
                            passwordCheck.upperCase === true
                              ? '#ffffff'
                              : 'RGBA(255, 255, 255, 0.35)'
                        }}
                      >
                        1 upper case
                      </p>
                    </div>
                    <div className='pass-msg d-flex align-items-center'>
                      <Svg.Tick
                        color={
                          passwordCheck.symbol === true
                            ? '#ffffff'
                            : 'RGBA(255, 255, 255, 0.35)'
                        }
                      />
                      <p
                        className='my-0'
                        style={{
                          color:
                            passwordCheck.symbol === true
                              ? '#ffffff'
                              : 'RGBA(255, 255, 255, 0.35)'
                        }}
                      >
                        1 symbol
                      </p>
                    </div>
                    <div className='pass-msg d-flex align-items-center'>
                      <Svg.Tick
                        color={
                          passwordCheck.number === true
                            ? '#ffffff'
                            : 'RGBA(255, 255, 255, 0.35)'
                        }
                      />
                      <p
                        className='my-0'
                        style={{
                          color:
                            passwordCheck.number === true
                              ? '#ffffff'
                              : 'RGBA(255, 255, 255, 0.35)'
                        }}
                      >
                        1 number
                      </p>
                    </div>
                  </div>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
