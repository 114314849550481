var fakeRoomTypes = [
  {
    id: 0,
    title: 'King Bed'
  },
  {
    id: 1,
    title: 'Two Queen Beds'
  },
  {
    id: 2,
    title: 'Handicap Room'
  },
  {
    id: 3,
    title: 'Twin Beds'
  }
];

export default fakeRoomTypes;
