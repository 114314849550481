var fakeActivity = [
  {
    name: 'Task was marked as done by Dwight.',
    time: 'Just now'
  },
  {
    name: 'Task Priority changed to Urgent by Micahel.',
    time: '2 minutes ago'
  },
  {
    name: 'Task was set as private by Micahel.',
    time: '3 minutes ago'
  },
  {
    name: 'Department changed to Maintenance by Dwight.',
    time: '12 minutes ago'
  },
  {
    name: 'Task was edited by Michael.',
    time: 'Nov 14, 2019 at 2:00pm'
  },
  {
    name: 'Task was created by Michael.',
    time: 'Nov 12, 2019 at 12:00pm'
  }
];

export default fakeActivity;
