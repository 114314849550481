var fakeCheckList = [
  {
    id: 0,
    task: 'This is a sample daily checklist task',
    shift: 'All Shift',
    workDay: 'Everyday',
    assignedTo: 'Everyone',
    reminder: 'No Reminder',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur mollitia perspiciatis illum atque quam, quos cumque. Dolorem voluptates eveniet facere.'
  },
  {
    id: 1,
    task: 'This is a sample daily checklist task',
    shift: 'Morning Shift',
    workDay: 'Everyday',
    assignedTo: 'Everyone',
    reminder: 'No Reminder',
    details:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolores voluptatem dolorem, neque veniam facere qui voluptates eum! Veniam eius, possimus consequuntur est officia quisquam necessitatibus!'
  },
  {
    id: 2,
    task: 'This is a sample daily checklist task',
    shift: 'Morning Shift',
    workDay: 'Everyday',
    assignedTo: 'Everyone',
    reminder: 'No Reminder',
    details:
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur mollitia perspiciatis illum atque quam, quos cumque. Dolorem voluptates eveniet facere.'
  },
  {
    id: 3,
    task: 'This is a sample daily checklist task',
    shift: 'Morning Shift',
    workDay: 'Everyday',
    assignedTo: 'Everyone',
    reminder: 'No Reminder',
    details:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi facere pariatur voluptates magnam enim accusantium reiciendis atque necessitatibus itaque, voluptatum tenetur excepturi. Atque quod cum delectus a aliquid minus sequi!'
  },
  {
    id: 4,
    task: 'This is a sample daily checklist task',
    shift: 'Evening Shift',
    workDay: 'Everyday',
    assignedTo: 'Everyone',
    reminder: 'No Reminder',
    details:
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur mollitia perspiciatis illum atque quam.'
  },
  {
    id: 5,
    task: 'This is a sample daily checklist task',
    shift: 'Evening Shift',
    workDay: 'Sunday',
    assignedTo: 'Dwight S.',
    reminder: '11:00 AM',
    details:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reprehenderit totam illo harum cupiditate voluptatibus iusto recusandae dolore? Amet ut quisquam ipsum, nulla laboriosam cum repudiandae similique suscipit. Quia labore mollitia odit dolores, nulla, molestias dolor fugiat fuga, impedit excepturi nobis.'
  }
];

export default fakeCheckList;
