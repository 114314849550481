import React from 'react';
import '../../../styles/managerView/Employee.css';
import tableData from './fakeEmployees';

const HousekeepingShort = () => {
  return (
    <table className='table-employees short-employees-table'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Full Name</th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.name}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default HousekeepingShort;
