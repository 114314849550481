var fakeCheckList = [
  {
    id: 0,
    title:
      'This is your first checklist item. You need to mark it by clicking on it',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus ea facilis delectus reprehenderit atque esse. Tenetur, cupiditate odit!'
  },
  {
    id: 1,
    title: 'This is your 2nd checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam!'
  },
  {
    id: 2,
    title: 'This is your 3rd checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus ea facilis delectus reprehenderit atque esse. Tenetur, cupiditate odit!'
  },
  {
    id: 3,
    title: 'This is your 4th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum.'
  },
  {
    id: 4,
    title: 'This is your 5th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum.Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus'
  },
  {
    id: 5,
    title: 'This is your 6th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum.Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus'
  },
  {
    id: 6,
    title: 'This is your 7th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum.Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus'
  },
  {
    id: 7,
    title: 'This is your 8th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus ea facilis delectus reprehenderit atque esse. Tenetur, cupiditate odit!'
  },
  {
    id: 8,
    title: 'This is your 9th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit.Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus ea facilis delectus reprehenderit atque esse. Tenetur, cupiditate odit!'
  },
  {
    id: 9,
    title: 'This is your 10th checklist item.',
    desc:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit.Quaerat dolore veniam eius ullam! Aliquid, molestiae dolorum. Animi cum ex aliquid necessitatibus ea facilis delectus reprehenderit atque esse. Tenetur, cupiditate odit!'
  }
];
export default fakeCheckList;
