import React, { useState } from 'react';
import signUpImage from '../../assets/images/registration-illustration.png';
import successImage from '../../assets/images/register-success-illustration.png';
import '../../styles/SignUp/SignUp.css';
import PopOver from './PopOver';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  agreeCheck: {
    webkitAppearance: 'initial',
    appearance: 'initial',
    width: '21px',
    height: '14px',
    background: 'rgb(255, 255, 255)',
    position: 'relative',
    border: '2px solid #3B3B3B',
    '&:checked': {
      background: 'rgb(255, 255, 255)'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const CreateAccount = () => {
  const classes = useStyles();
  const [openPop, setOpenPop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [isValid, setIsValid] = useState({
    firstName: true,
    lastName: true,
    email: true,
    password: true
  });

  const [passwordCheck, setPasswordCheck] = useState({
    length: true,
    upperCase: true,
    symbol: true,
    number: true
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreement, setAgreement] = useState(false);

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenPop(true);
    setPassword(e.target.value);

    // test length
    /(?=.{8,})/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          length: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          length: false
        }));

    // test uppercase
    /(?=.*[A-Z])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          upperCase: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          upperCase: false
        }));

    // test symbol
    /(?=.*[!@#$%^&*])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          symbol: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          symbol: false
        }));

    // test number
    /(?=.*[0-9])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          number: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          number: false
        }));
  };

  const handleSignUpForm = (e) => {
    e.preventDefault();
    // check first name
    firstName === ''
      ? setIsValid((prevState) => ({
          ...prevState,
          firstName: false
        }))
      : setIsValid((prevState) => ({
          ...prevState,
          firstName: true
        }));
    // check last name
    lastName === ''
      ? setIsValid((prevState) => ({
          ...prevState,
          lastName: false
        }))
      : setIsValid((prevState) => ({
          ...prevState,
          lastName: true
        }));

    // check email
    /\S+@\S+\.\S+/.test(email) === true
      ? setIsValid((prevState) => ({
          ...prevState,
          email: true
        }))
      : setIsValid((prevState) => ({
          ...prevState,
          email: false
        }));

    // check password
    if (password === '') {
      setIsValid((prevState) => ({
        ...prevState,
        password: false
      }));
    } else if (
      passwordCheck.length === false &&
      passwordCheck.number === false &&
      passwordCheck.symbol === false &&
      passwordCheck.upperCase === false
    ) {
      setIsValid((prevState) => ({
        ...prevState,
        password: false
      }));
    } else {
      setIsValid((prevState) => ({
        ...prevState,
        password: true
      }));
    }

    // //Redirect if success
    if (
      firstName === '' ||
      isValid.firstName === false ||
      lastName === '' ||
      isValid.lastName === false ||
      email === '' ||
      isValid.email === false ||
      password === '' ||
      isValid.password === false
    ) {
      setSignUpSuccess(false);
    } else {
      setSignUpSuccess(true);
    }
  };

  return (
    <div>
      {!signUpSuccess && (
        <div className='sign-up d-flex'>
          {' '}
          <div className='signUp-img-section'>
            <div className='app-text'>
              <p className='my-0 brand-title'>strēmlīnd</p>
              <p className='my-0 brand-subTitle'>
                Connect, automate, organize, and simplify your daily hotel
                operations.
              </p>
            </div>
            <img src={signUpImage} alt='' />
          </div>
          <div className='signUp-form-section'>
            <p className='my-0 signUp-form-title'>Sign up to Stremlind</p>

            <div className='signUp-form'>
              <form action='submit' onSubmit={handleSignUpForm}>
                {/* Input First Name */}
                <div className='signUp-input-box'>
                  <label
                    htmlFor='First Name'
                    style={{ color: !isValid.firstName && '#DE6552' }}
                  >
                    First Name
                  </label>
                  <input
                    style={{
                      borderBottom: !isValid.firstName
                        ? '1px solid #DE6552'
                        : firstName !== ''
                        ? '1px solid #434343'
                        : '1px solid RGBA(172, 180, 184, 0.2)'
                    }}
                    type='text'
                    name='firstName'
                    id=''
                    placeholder='Enter first name'
                    value={firstName}
                    onChange={handleFirstName}
                  />
                  {!isValid.firstName && (
                    <span className='error-msg'>This is a mandatory field</span>
                  )}
                </div>

                {/* Input Last Name */}
                <div className='signUp-input-box'>
                  <label
                    htmlFor='Last Name'
                    style={{ color: !isValid.lastName && '#DE6552' }}
                  >
                    Last Name
                  </label>
                  <input
                    style={{
                      borderBottom: !isValid.lastName
                        ? '1px solid #DE6552'
                        : lastName !== ''
                        ? '1px solid #434343'
                        : '1px solid RGBA(172, 180, 184, 0.2)'
                    }}
                    type='text'
                    name='lastName'
                    id=''
                    placeholder='Enter last name'
                    value={lastName}
                    onChange={handleLastName}
                  />
                  {!isValid.lastName && (
                    <span className='error-msg'>This is a mandatory field</span>
                  )}
                </div>

                {/* Input Email*/}
                <div className='signUp-input-box'>
                  <label
                    htmlFor='Email'
                    style={{ color: !isValid.email && '#DE6552' }}
                  >
                    Email
                  </label>
                  <input
                    style={{
                      color: !isValid.email && '#DE6552',
                      borderBottom: !isValid.email
                        ? '1px solid #DE6552'
                        : email !== ''
                        ? '1px solid #434343'
                        : '1px solid RGBA(172, 180, 184, 0.2)'
                    }}
                    type='email'
                    name='email'
                    id=''
                    placeholder='Enter email address'
                    value={email}
                    onChange={handleEmail}
                  />
                  {!isValid.email && (
                    <span className='error-msg'>Invalid email address</span>
                  )}
                </div>

                {/* Input password */}
                <div className='signUp-input-box'>
                  <label
                    htmlFor='Password'
                    style={{ color: !isValid.password && '#DE6552' }}
                  >
                    Password
                  </label>
                  <input
                    style={{
                      color: !isValid.password && '#DE6552',
                      borderBottom: !isValid.password
                        ? '1px solid #DE6552'
                        : password !== ''
                        ? '1px solid #434343'
                        : '1px solid RGBA(172, 180, 184, 0.2)'
                    }}
                    type='password'
                    name='password'
                    id=''
                    placeholder='Min 8 characters, 1 uppercase, 1 symbol, 1 number'
                    value={password}
                    onChange={handlePassword}
                  />
                  {!isValid.password && (
                    <span className='error-msg'>Password is weak</span>
                  )}
                  <PopOver
                    openPop={openPop}
                    setOpenPop={setOpenPop}
                    anchorEl={anchorEl}
                    passwordCheck={passwordCheck}
                  />
                </div>

                <div className='signUp-check-box d-flex align-items-center'>
                  <input
                    className={classes.agreeCheck}
                    type='checkbox'
                    name='agree'
                    id='agreeBox'
                    value={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                  />
                  <label htmlFor='agreeBox'>
                    Creating an account means you are agreeing with our Terms of
                    service, Policy and default notification settings.
                  </label>
                </div>

                <button type='submit' className='signUp-btn'>
                  Create Account
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {signUpSuccess && (
        <div className='signUp-success d-block'>
          <div className='success-img'>
            <img src={successImage} alt='' />
          </div>
          <p className='my-0 success-title'>Success!</p>
          <p className='my-0 success-text'>
            We have sent you a confirmation email.
          </p>
          <button type='submit' className='start-btn'>
            Get Started
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateAccount;
