import React from 'react';
import '../../../styles/maintenance/PreventiveMaintenance.css';
import tableData from './dataToday';
import * as Svg from '../../common/icons';

const TodayTable = ({
  setReadDetails,
  setDetailsID,
  setFormOpen,
  setCreateMaintenanceOpen
}) => {
  const handleRead = (id) => {
    setFormOpen(true);
    setReadDetails(true);
    setDetailsID(id);
    setCreateMaintenanceOpen(false);
  };
  return (
    <table className='table-preventive'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col'>Status</th>
          <th scope='col'>Location</th>
          <th scope='col'>Start Date</th>
          <th scope='col'>Due On</th>
          <th scope='col'>Task Starts In</th>
          <th scope='col'>Next Maintenance</th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id} onClick={() => handleRead(data.id)}>
                <td>{data.title}</td>
                <td className='td-status' style={{ width: '154px' }}>
                  <p
                    className='m-0'
                    style={{
                      background:
                        data.status === 'Open'
                          ? '#47B39C'
                          : data.status === 'Not started'
                          ? '#DE6552'
                          : '#FBBE53'
                    }}
                  >
                    {data.status}
                  </p>
                </td>
                <td>{data.location}</td>
                <td>{data.startDate}</td>
                <td>{data.dueON}</td>
                <td>
                  {' '}
                  <span
                    style={{
                      color: data.startIN <= 7 ? '#FBBE53' : '#47B39C'
                    }}
                  >
                    {data.startIN} Days
                  </span>
                </td>
                <td>{data.nextDate}</td>
                <td className='px-0'>
                  <Svg.Comment />
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TodayTable;
