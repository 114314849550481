import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FilterMenu from '../../components/common/FilterMenu';
import Statistics from '../../components/operation/cashCount/Statistics';
import TableCashCount from '../../components/operation/cashCount/TabelCashCount';
import '../../styles/operations/Operations.css';
import AddNewCashCount from '../../components/operation/cashCount/AddNewCashCount';
import PopUp from '../../components/operation/cashCount/PopUp';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 48px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      marginTop: '15px',
      marginBottom: '15px',
      height: '50px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});

const CashCount = () => {
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];
  const filterByDiscrepancyOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Not Started', value: 'not-started' },
    { label: 'In Progress', value: 'in-progress' }
  ];

  const classes = useStyles();
  const [addNewForm, setAddNewForm] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [filters, setFilters] = useState({
    date: null,
    discrepancy: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAddNewClick = (event, index) => {
    setAddNewForm(true);
  };
  return (
    <div className='main-section operations'>
      <Statistics />

      <div className='cashCount-head d-flex justify-content-between align-items-center'>
        <p className='main-title my-0'>Cash Count</p>

        <div className='d-flex justify-content-between align-items-center'>
          <div className='filters'>
            <FilterMenu
              value={filters.date}
              onChange={(e) => filterChange('date', e)}
              options={filterByDateOptions}
              placeholder='Filter Date'
            />
            <FilterMenu
              value={filters.discrepancy}
              onChange={(e) => filterChange('discrepancy', e)}
              options={filterByDiscrepancyOptions}
              placeholder='Filter By Discrepancy'
            />
          </div>

          <div className={classes.addNewButton}>
            <Button className='main-button' onClick={handleAddNewClick}>
              Add New
            </Button>
          </div>
        </div>
      </div>

      {/* CashCount Table */}
      <div className='cashCount-tables'>
        <hr className='main-devider' />
        <div className='cashCount-table-wrap'>
          <TableCashCount setAddNewForm={setAddNewForm} />
        </div>
      </div>
      {addNewForm && (
        <AddNewCashCount
          setAddNewForm={setAddNewForm}
          addNewForm={addNewForm}
          setPopUpOpen={setPopUpOpen}
        />
      )}
      {popUpOpen && (
        <PopUp
          setAddNewForm={setAddNewForm}
          setPopUpOpen={setPopUpOpen}
          popUpOpen={popUpOpen}
        />
      )}
    </div>
  );
};

export default CashCount;
