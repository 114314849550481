import React, { useState } from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as Svg from '../../../components/common/icons';
import '../../../styles/maintenance/PreventiveMaintenance.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DotMenu from '../../common/DotMenu';
import CreateDrawer from './CreateDrawer';
import DeletePopUp from './DeletePopUp';
import FilterMenu from '../../common/FilterMenu';
import SelectDropdown from '../../common/SelectDropdown';

const openStyle = {
  visibility: 'visible'
};
const hideStyle = {
  visibility: 'hidden'
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  },
  largePicker: {
    '& .react-datepicker-wrapper': {
      '& .react-datepicker__input-container input': {
        width: '115px'
      }
    }
  },
  pickerSelect: {
    '& .makeStyles-root-21': {
      '& label': {
        '& .makeStyles-clear-22': {
          display: 'none'
        },
        '& .triangle': {
          marginLeft: '34px'
        }
      }
    }
  },

  mrChecklist: {
    '& li': {
      '& input': {
        webkitAppearance: 'initial',
        appearance: 'initial',
        width: '15px',
        height: '15px',
        background: 'rgb(255, 255, 255)',
        position: 'relative',
        border: '2px solid #3B3B3B',
        '&:checked': {
          background: 'rgb(255, 255, 255)'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
});

const CreatePreMaintenance = ({ setCreateMaintenanceOpen, setFormOpen }) => {
  const filterByRepeatOptions = [
    { label: 'Every Month', value: 'every-month' },
    { label: 'Every 03 Months', value: '3-month' },
    { label: 'Every 06 Months', value: '6-month' },
    { label: 'Once A Year', value: 'year' }
  ];

  const assignedOptions = ['Dwight Schrute', 'Jim Halpert', 'Auvik', 'Matthew'];
  const classes = useStyles();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [checklistInputOpen, setChecklistInputOpen] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [newCheckItem, setNewCheckItem] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [repeatDate, setRepeatDate] = useState(null);
  const [dropSelect, setDropSelect] = useState({
    assignedTo: null
  });

  const selectChange = (name, value) => {
    setDropSelect({ ...dropSelect, [name]: value });
  };
  const [filters, setFilters] = useState({
    repeat: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };
  const handleCancle = () => {
    setCreateMaintenanceOpen(false);
    setFormOpen(false);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setNewCheckItem('');
      setChecklistInputOpen(false);
    }
  };
  const handleInputCheckItem = (e) => {
    setNewCheckItem(e.target.value);
  };

  return (
    <div>
      <form action='submit' onSubmit={handleFormSubmit}>
        <div className='preM-half-header d-flex justify-content-between align-items-center'>
          <Button
            variant='contained'
            color='primary'
            className='primary-button'
          >
            Create Preventive Maintenance
          </Button>

          <button type='submit' className='close-normal' onClick={handleCancle}>
            Close
          </button>
        </div>
        <div className='inputs'>
          <input
            type='text'
            name=''
            id='preM-title-field'
            placeholder='Write a short title of the request'
          />

          <hr className='form-devider' />
          <div className='select-menus'>
            <div className='d-flex'>
              <div className={`${classes.largePicker} due-date`}>
                <Svg.Calendar />
                <DatePicker
                  dateFormat='MMM d'
                  placeholderText='Start & Due Date'
                  className='custom-datePicker'
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                >
                  <div className='footer-plugin d-flex'>
                    <div className='start-date mr-3 align-items-center'>
                      <p className='start-date-title text-left mb-2'>
                        Start Date
                      </p>
                      <div className='start-date-box'>
                        <span className='text-left'>
                          {startDate !== null && startDate.toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div className='end-date'>
                      <p className='end-date-title text-left mb-2'>Due Date</p>
                      <div className='end-date-box'>
                        <span className='text-left'>
                          {' '}
                          {endDate !== null && endDate.toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </DatePicker>
              </div>
              <div className={`${classes.largePicker} due-date`}>
                <Svg.Calendar />
                <DatePicker
                  dateFormat='MMMM d'
                  placeholderText='Repeats On'
                  selected={repeatDate}
                  onChange={(date) => setRepeatDate(date)}
                >
                  <hr className='form-devider' />
                  <div className='footer-plugin'>
                    <p className='my-0 quick-select ml-3'>Quick Selection</p>
                    <div
                      className={`${classes.pickerSelect} datePicker-select d-flex justify-content-center`}
                    >
                      <FilterMenu
                        value={filters.repeat}
                        onChange={(e) => filterChange('repeat', e)}
                        options={filterByRepeatOptions}
                        placeholder='Select'
                      />
                    </div>
                  </div>
                </DatePicker>
              </div>
              <div className='assigned d-flex align-items-center'>
                <Svg.Assigned />
                <SelectDropdown
                  options={assignedOptions}
                  value={dropSelect.assignedTo}
                  onChange={(e) => selectChange('assignedTo', e)}
                  placeholder='Assigned To'
                />
              </div>
            </div>
            <div className='addExpense d-flex align-items-center'>
              <button
                className='addExpense-btn'
                onClick={() => setCreateDrawerOpen(true)}
              >
                <Svg.PlusCircle />
              </button>
              <p className='my-0 addExpense-text'>Add Item and Expense</p>
            </div>
          </div>

          <hr className='form-devider' />
          <input
            type='text'
            name=''
            id='preM-room-field'
            placeholder='Room number or location'
          />
          <hr className='form-devider' />
          <textarea
            name=''
            id='preM-details-field'
            cols='30'
            rows='5'
            placeholder='Preventive maintenance details'
          ></textarea>
          <hr className='form-devider' />
        </div>

        <div className='details-checkList'>
          <div className='d-flex form-checklist-head'>
            <p className='my-0 py-0 mr-3'>Checklist</p>
            <button
              className='form-checklist-btn'
              onClick={() => setChecklistInputOpen(true)}
            >
              <Svg.PlusCircle />
            </button>
          </div>
          {checklistInputOpen && (
            <div className='form-checklist-addBox d-flex'>
              <div className='checklist-custom-squre'></div>
              <input
                type='text'
                value={newCheckItem}
                onKeyDown={handleKeyDown}
                onChange={handleInputCheckItem}
              />
            </div>
          )}

          <ul className={classes.mrChecklist}>
            <li>
              <input type='checkbox' name='morning-dawns' id='morning-dawns' />
              <label htmlFor='morning-dawns'> When The Morning Dawns</label>
            </li>
            <li>
              <input type='checkbox' name='being-human' id='being-human' />
              <label htmlFor='being-human'> On Being Human</label>
            </li>
            <li>
              <input type='checkbox' name='peace-wish' id='peace-wish' />
              <label htmlFor='peace-wish'>
                {' '}
                Peace On Earth A Wonderful Wish But No Way
              </label>
            </li>
            <li>
              <input type='checkbox' name='phil-science' id='phil-science' />
              <label htmlFor='phil-science'> Philosophy As A Science</label>
            </li>
            <li>
              <input type='checkbox' name='vampires' id='vampires' />
              <label htmlFor='vampires'>
                Vampires The Romantic Ideology Behind Them
              </label>
            </li>
          </ul>
        </div>
        <hr className='form-devider' />
      </form>

      <div className='expense-table'>
        <div className='addExpense expense-table-head d-flex align-items-center'>
          <p className='expense-table-title my-0 mr-3'>
            Items Needed & Expenses
          </p>
          <button
            className='addExpense-btn'
            onClick={() => setCreateDrawerOpen(true)}
          >
            <Svg.PlusCircle />
          </button>
        </div>

        <table class='table-borderless table-expenseItem'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '0 !important' }}></th>
              <th scope='col'>Items</th>
              <th scope='col'>Qty.</th>
              <th scope='col'>Price</th>
              <th scope='col'>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New Cable Box</td>
              <td>1</td>
              <td>$200</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New TV</td>
              <td>1</td>
              <td>$300</td>
              <td>$300</td>
            </tr>

            <tr className='total-row'>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>$500</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className='drawer-wraper'
        style={createDrawerOpen ? openStyle : hideStyle}
      >
        <CreateDrawer
          createDrawerOpen={createDrawerOpen}
          setCreateDrawerOpen={setCreateDrawerOpen}
        />
      </div>

      {deletePopUp && (
        <DeletePopUp
          deletePopUp={deletePopUp}
          setDeletePopUp={setDeletePopUp}
        />
      )}
    </div>
  );
};

export default CreatePreMaintenance;
