import React from 'react';
import '../../../styles/dashboard/Requests.css';
import tableData from './maintenanceData';

const MaintenanceTable = () => {
  return (
    <table className='table-requests'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col'>Status</th>
          <th scope='col'>Location</th>
          <th scope='col'>Department</th>
          <th scope='col'>Created By</th>
          <th scope='col'>Due Date</th>
          <th scope='col'></th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.title}</td>
                <td className='td-status' style={{ width: '151px' }}>
                  <p
                    className='my-0'
                    style={{
                      background:
                        data.status === 'Open'
                          ? '#47B39C'
                          : data.status === 'Not started'
                          ? '#DE6552'
                          : '#FBBE53'
                    }}
                  >
                    {data.status}
                  </p>
                </td>
                <td>{data.location}</td>
                <td>{data.dept}</td>
                <td>{data.createdBY}</td>
                <td>{data.dueDate}</td>
                <td className='p-0 text-center'></td>
                <td className='p-0 text-right' style={{ width: '150px' }}></td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default MaintenanceTable;
