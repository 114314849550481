import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import * as Svg from '../../../components/common/icons';
import '../../../styles/maintenance/MaintenanceRequests.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import WorkOrderDrawer from './WorkOrderDrawer';
import DotMenu from '../../common/DotMenu';
import { makeStyles, MenuItem } from '@material-ui/core';
import DeletePopUp from './DeletePopUp';
import SelectDropdown from '../../common/SelectDropdown';

const openStyle = {
  visibility: 'visible'
};
const hideStyle = {
  visibility: 'hidden'
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  },
  mrChecklist: {
    '& li': {
      '& input': {
        webkitAppearance: 'initial',
        appearance: 'initial',
        width: '15px',
        height: '15px',
        background: 'rgb(255, 255, 255)',
        position: 'relative',
        border: '2px solid #3B3B3B',
        '&:checked': {
          background: 'rgb(255, 255, 255)'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
});
const AddWorkOrder = ({ setWorkOrderOpen, setFormOpen }) => {
  const priorityOptions = [
    'Urgent',
    'Important',
    'Normal',
    'Complaints',
    'Closed'
  ];
  const assignedOptions = ['Dwight Schrute', 'Jim Halpert', 'Auvik', 'Matthew'];
  const classes = useStyles();
  const [workDrawerOpen, setWorkDrawerOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [dropSelect, setDropSelect] = useState({
    dept: null,
    priority: null,
    assignedTo: null
  });

  const selectChange = (name, value) => {
    setDropSelect({ ...dropSelect, [name]: value });
  };

  const handleCancle = () => {
    setWorkOrderOpen(false);
    setFormOpen(false);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <form action='submit' onSubmit={handleFormSubmit}>
        <div className='request-half-header d-flex justify-content-between align-items-center'>
          <Button
            variant='contained'
            color='primary'
            className='primary-button'
          >
            Request Work Order
          </Button>

          <button type='submit' className='close-normal' onClick={handleCancle}>
            Close
          </button>
        </div>
        <div className='inputs'>
          <input
            type='text'
            name=''
            id='requests-title-field'
            placeholder='Write a short title of the work order'
          />

          <hr className='form-devider' />
          <div className='select-menus'>
            <div className='d-flex'>
              <div className='due-date'>
                <Svg.Calendar />
                <DatePicker
                  dateFormat='MMMM d'
                  placeholderText='Due date'
                  selected={dueDate}
                  onChange={(date) => setDueDate(date)}
                />
              </div>
              <div className='priority d-flex align-items-center'>
                <Svg.Priority />
                <SelectDropdown
                  options={priorityOptions}
                  value={dropSelect.priority}
                  onChange={(e) => selectChange('priority', e)}
                  placeholder='Priority'
                />
              </div>
              <div className='assigned d-flex align-items-center'>
                <Svg.Assigned />
                <SelectDropdown
                  options={assignedOptions}
                  value={dropSelect.assignedTo}
                  onChange={(e) => selectChange('assignedTo', e)}
                  placeholder='Assigned To'
                />
              </div>
            </div>
            <div className='addExpense d-flex align-items-center'>
              <button
                className='addExpense-btn'
                onClick={() => setWorkDrawerOpen(true)}
              >
                <Svg.PlusCircle />
              </button>
              <p className='my-0 addExpense-text'>Add Item and Expense</p>
            </div>
          </div>

          <hr className='form-devider' />
          <input
            type='text'
            name=''
            id='requests-room-field'
            placeholder='Room number or location'
          />
          <hr className='form-devider' />
          <textarea
            name=''
            id='requests-details-field'
            cols='30'
            rows='5'
            placeholder='Work Order Details'
          ></textarea>
          <hr className='form-devider' />
        </div>

        <div className='details-checkList'>
          <p className='my-0'>Checklist</p>
          <ul className={classes.mrChecklist}>
            <li>
              <input type='checkbox' name='morning-dawns' id='morning-dawns' />
              <label htmlFor='morning-dawns'> When The Morning Dawns</label>
            </li>
            <li>
              <input type='checkbox' name='being-human' id='being-human' />
              <label htmlFor='being-human'> On Being Human</label>
            </li>
            <li>
              <input type='checkbox' name='peace-wish' id='peace-wish' />
              <label htmlFor='peace-wish'>
                {' '}
                Peace On Earth A Wonderful Wish But No Way
              </label>
            </li>
            <li>
              <input type='checkbox' name='phil-science' id='phil-science' />
              <label htmlFor='phil-science'> Philosophy As A Science</label>
            </li>
            <li>
              <input type='checkbox' name='vampires' id='vampires' />
              <label htmlFor='vampires'>
                Vampires The Romantic Ideology Behind Them
              </label>
            </li>
          </ul>
        </div>
        <hr className='form-devider' />
      </form>

      <div className='expense-table'>
        <p className='expense-table-head my-0'>Items Needed & Expenses</p>
        <table class='table-borderless table-expenseItem'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '0 !important' }}></th>
              <th scope='col'>Items</th>
              <th scope='col'>Qty.</th>
              <th scope='col'>Price</th>
              <th scope='col'>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New Cable Box</td>
              <td>1</td>
              <td>$200</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New TV</td>
              <td>1</td>
              <td>$300</td>
              <td>$300</td>
            </tr>

            <tr className='total-row'>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>$500</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className='drawer-wraper'
        style={workDrawerOpen ? openStyle : hideStyle}
      >
        <WorkOrderDrawer
          workDrawerOpen={workDrawerOpen}
          setWorkDrawerOpen={setWorkDrawerOpen}
        />
      </div>

      {deletePopUp && (
        <DeletePopUp
          deletePopUp={deletePopUp}
          setDeletePopUp={setDeletePopUp}
        />
      )}
    </div>
  );
};

export default AddWorkOrder;
