import React from 'react';
import tableData from './fakeCashCount';
import * as Svg from '../../common/icons';
import { makeStyles, MenuItem } from '@material-ui/core';
import DotMenu from '../../common/DotMenu';
import '../../../styles/operations/Operations.css';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  }
});
const TabelCashCount = ({ setAddNewForm }) => {
  const classes = useStyles();
  return (
    <table className='table-cashCount'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Agent</th>
          <th scope='col'>$100</th>
          <th scope='col'>$50</th>
          <th scope='col'>$20</th>
          <th scope='col'>$10</th>
          <th scope='col'>$5</th>
          <th scope='col'>$1</th>
          <th scope='col'>$0.25</th>
          <th scope='col'>$0.10</th>
          <th scope='col'>$0.05</th>
          <th scope='col'>$0.01</th>
          <th scope='col'>Total</th>
          <th scope='col'>Discrepancy</th>
          <th scope='col'>Cash Drops</th>
          <th scope='col'>Note</th>
          <th scope='col'>Time Added</th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.name}</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>$200</td>
                <td>${data.total}</td>
                <td
                  style={{
                    color:
                      data.discrepancy < 0
                        ? '#DE6552'
                        : data.discrepancy > 0
                        ? '#FBBE53'
                        : '#47B39C'
                  }}
                >
                  ${' '}
                  {data.discrepancy < 0
                    ? `${Math.abs(data.discrepancy)} Short`
                    : data.discrepancy > 0
                    ? `${Math.abs(data.discrepancy)} Extra`
                    : Math.abs(data.discrepancy)}
                </td>
                <td>${data.cashDrop}</td>
                <td className='text-center'>
                  <Svg.Comment />
                </td>
                <td>{data.time}</td>
                <td>
                  <DotMenu inline openLeft>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => setAddNewForm(true)}
                    >
                      Edit
                    </MenuItem>
                  </DotMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TabelCashCount;
