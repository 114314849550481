import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import * as Svg from '../../common/icons';

const useStyles = makeStyles({
  optionList: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& li': {
      fontFamily: 'Open sans',
      fontSize: 12,
      fontWeight: '700',
      color: '#434343',
      padding: '4px 8px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#bdbdbd',
        color: '#434343'
      }
    },
    '& .selected': {
      fontFamily: 'Open sans',
      fontSize: 12,
      fontWeight: '700',
      color: '#ffffff',
      backgroundColor: '#3B3B3B'
    }
  }
});

const WeekdaySelect = ({ weekDay, setWeekDay }) => {
  const classes = useStyles();
  const options = [
    { name: 'M', value: 'monday' },
    { name: 'T', value: 'tuesday' },
    { name: 'W', value: 'wednesday' },
    { name: 'T', value: 'thursday' },
    { name: 'F', value: 'friday' },
    { name: 'S', value: 'saturday' },
    { name: 'S', value: 'sunday' }
  ];

  const selectOption = (option) => {
    const filtered = weekDay.find((item) => item === option.value);
    console.log(filtered);
    if (filtered === undefined) {
      setWeekDay((prevState) => [...prevState, option.value]);
    } else {
      setWeekDay(weekDay.filter((item) => !filtered.includes(item)));
    }
  };

  const optionIsSelected = (option) => {
    const filtered = weekDay.find((item) => item === option.value);
    if (filtered === undefined) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div>
      {' '}
      <ul className={classes.optionList}>
        {options.map((option) => (
          <li
            key={option.value}
            value={option.value}
            onClick={() => selectOption(option)}
            className={optionIsSelected(option) ? 'selected' : ''}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WeekdaySelect;
