import React, { useState } from 'react';
import '../../../styles/managerView/RoomTypeMV.css';
import EditRoomType from './EditRoomType';
import preData from './fakeRoomType';

const TableRoomTypes = ({ setOpenPopUp, setPopUpMSG, setPopUpType }) => {
  const [openEditRoomType, setOpenEditRoomType] = useState(false);
  const [editID, setEditID] = useState(null);

  const handleEdit = (id) => {
    setOpenEditRoomType(true);
    setEditID(id);
  };

  const handleDelete = () => {
    setOpenPopUp(true);
    setPopUpMSG('Room type deleted successfully');
    setPopUpType('success');
  };
  return (
    <>
      <table className='table-RoomTypes'>
        <thead className='thead'>
          <tr>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'></th>
          </tr>
        </thead>
        {preData.length > 0 ? (
          <tbody>
            {preData.map((data, id) => {
              return (
                <tr key={id}>
                  <td>{data.title}</td>
                  <td>
                    <button className='edit-btn' onClick={() => handleEdit(id)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className='delete-btn' onClick={handleDelete}>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <p className='loading'>Loading Data...</p>
          </tbody>
        )}
      </table>

      {openEditRoomType && (
        <EditRoomType
          openEditRoomType={openEditRoomType}
          setOpenEditRoomType={setOpenEditRoomType}
          editID={editID}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </>
  );
};

export default TableRoomTypes;
