import React from 'react';
import { withRouter } from 'react-router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/navs/Header.css';
import Profile from '../../components/navs/Profile';
import Employee from './Employee';
import Departments from './Departments';
import ChecklistsMV from './ChecklistsMV';
import EfficiencyMatrics from './EfficiencyMatrics';
import RoomType from './RoomType';

const useStyles = makeStyles({
  menu: {
    position: 'relative',
    zIndex: 0,
    overflowY: 'visible !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-40px',
      width: 'calc(100% + 80px)',
      height: '254px',
      zIndex: 0
    },
    '& .MuiTabs-fixed, .MuiTabs-root': {
      overflow: 'visible !important'
    },

    '& .MuiTabs-flexContainer': {
      display: 'inline-block',
      background: '#fff',
      boxShadow: '0 15px 30px -4px rgba(172,180,184,0.2)',

      '& .MuiTab-root': {
        zIndex: 2,
        textTransform: 'none',
        color: '#797B88',
        '&.Mui-selected': {
          color: '#434343'
        },

        '& .MuiTab-wrapper': {
          width: 'initial',
          fontFamily: 'Open sans !important',
          fontWeight: '600',
          padding: '0 16px',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            bottom: '-2px',
            borderBottom: '2px solid #252427'
          }
        }
      }
    },
    '& .MuiTabs-indicator': {
      bottom: 0,
      height: 3,
      background: '#252427',
      borderRadius: 0,
      zIndex: 1,
      transform: 'scaleX(0.75)'
    }
  }
});
const ManagerView = (props) => {
  const classes = useStyles();

  const { match, history } = props;
  const { params } = match;
  const { page } = params;

  const tabNameToIndex = {
    0: 'employee',
    1: 'departments',
    2: 'efficiencyMatrics',
    3: 'checklists',
    4: 'roomType'
  };

  const indexToTabName = {
    employee: 0,
    departments: 1,
    efficiencyMatrics: 2,
    checklists: 3,
    roomType: 4
  };

  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);

  const handleChange = (event, newValue) => {
    history.push(`/manager/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center top-navs'>
        <div className='head-navs'>
          <div className={classes.menu}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              aria-label='full width tabs example'
            >
              <Tab label='Employee' default />
              <Tab label='Departments' />
              <Tab label='Efficiency Matrics' />
              <Tab label='Checklists' />
              <Tab label='Room Type' />
            </Tabs>
          </div>
        </div>
        <Profile />
      </div>

      {selectedTab === 0 && <Employee />}
      {selectedTab === 1 && <Departments />}
      {selectedTab === 2 && <EfficiencyMatrics />}
      {selectedTab === 3 && <ChecklistsMV />}
      {selectedTab === 4 && <RoomType />}
    </div>
  );
};

export default withRouter(ManagerView);
