import React, { useState } from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import userIMG from '../../../assets/images/auvik.jpg';
import DotMenu from '../../common/DotMenu';
import '../../../styles/dashboard/Checklist.css';
import { Editor } from '@tinymce/tinymce-react';
import activites from './fakeActivity';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  },

  postButton: {
    backgroundColor: '#434343',
    color: '#ffffff !important',
    padding: '9px 22px !important',
    margin: '8px',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    fontFamily: 'Open sans, sans-serif !important',
    fontWeight: 600,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#ffffff !important',
      backgroundColor: '#434343 !important',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    }
  }
});

const Comment = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [editorOpen, setEditorOpen] = useState(false);
  const [showActivity, setShowActivity] = useState(false);

  const customButton = {
    text: 'Post',
    onAction: function () {
      setText('');
      setEditorOpen(false);
    }
  };

  return (
    <div className='checklist-comment-section'>
      {!showActivity && (
        <div>
          <div className='show-checklist-comment'>
            <p className='checklist-comment-head my-0'>Comments</p>
            <div className='checklist-commenter-info d-flex align-items-center'>
              <img src={userIMG} alt='Commenter' />
              <p className='checklist-commenter-name my-0'>User Name</p>
              <p className='checklist-comment-date my-0'>
                Dec 32, 2019 at 12:10pm
              </p>
            </div>

            <div className='checklist-comment d-flex align-items-baseline justify-content-between'>
              <div className='checklist-comment-text-box'>
                <p className='checklist-comment-text my-0'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit,
                  aut. Ea sapiente odit aliquam nam magnam obcaecati
                  voluptatibus quasi vero. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Odit, aut. Ea sapiente odit
                  aliquam nam magnam obcaecati voluptatibus quasi vero.
                </p>
              </div>

              <DotMenu inline openLeft>
                <MenuItem className={classes.menuItem}>Edit Comment</MenuItem>
                <MenuItem className={classes.menuItem}>Delete Comment</MenuItem>
              </DotMenu>
            </div>
          </div>
          <div className='checklist-post-comment'>
            <div className='checklist-comment-post-box d-flex align-items-end'>
              <img src={userIMG} alt='User' />

              {editorOpen && (
                <div className='editor-area'>
                  <div className='d-flex justify-content-end'>
                    {' '}
                    <button
                      onClick={() => setShowActivity(true)}
                      className='activity-btn mb-2'
                    >
                      Activity
                    </button>
                  </div>
                  <div className='checklist-comment-input'>
                    <Editor
                      apiKey='nytdwdejhgozrvq9yqk274bsczhm8weh0g7m9p79p9ht9hue'
                      value={text}
                      onEditorChange={(newText) => setText(newText)}
                      init={{
                        selector: 'textarea#custom-toolbar-button',
                        plugins: 'link lists image emoticons',
                        toolbar:
                          ' bold italic numlist bullist image emoticons custom-toolbar-button',
                        emoticons_images_url:
                          'http://www.iemoji.com/meanings-gallery/smileys-people',
                        toolbar_location: 'bottom',
                        menubar: false,
                        statusbar: false,
                        max_height: 130,
                        images_upload_url: 'postAcceptor.php',
                        images_upload_handler: function (
                          blobInfo,
                          success,
                          failure
                        ) {
                          setTimeout(function () {
                            success(
                              'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png'
                            );
                          }, 2000);
                        },
                        setup: function (editor) {
                          editor.ui.registry.addButton(
                            'custom-toolbar-button',
                            customButton
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              {!editorOpen && (
                <div
                  className='editor-clickBox'
                  onClick={() => setEditorOpen(true)}
                >
                  <div className='d-flex justify-content-end'>
                    {' '}
                    <button
                      onClick={() => setShowActivity(true)}
                      className='activity-btn mb-2'
                    >
                      Activity
                    </button>
                  </div>
                  <div className='click-box'></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showActivity && (
        <div className='activity-section'>
          <div className='show-activity'>
            <p className='my-0 activity-head'>Activities</p>
            {activites.map((activity) => (
              <div
                className='activity-list d-flex align-items-center justify-content-between pb-2'
                key={activity.name}
              >
                <p className='my-0 activity-name'>{activity.name}</p>
                <p className='my-0 activity-time'>{activity.time}</p>
              </div>
            ))}
            <button className='show-more-btn'>
              Show 10 previous activities
            </button>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              className='comment-btn'
              onClick={() => setShowActivity(false)}
            >
              Comments
            </button>
          </div>

          <hr className='activity-devider' />
        </div>
      )}
    </div>
  );
};

export default Comment;
