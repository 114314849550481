import React, { useState } from 'react';
import * as Svg from '../../../components/common/icons';
import { makeStyles, MenuItem } from '@material-ui/core';
import '../../../styles/maintenance/PreventiveMaintenance.css';
import DotMenu from '../../common/DotMenu';
import DetailsDrawer from './DetailsDrawer';
import fakeData from './dataToday';
import Comment from './Comment';
import DeletePopUp from './DeletePopUp';

const openStyle = {
  visibility: 'visible'
};
const hideStyle = {
  visibility: 'hidden'
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  },
  mrChecklist: {
    '& li': {
      '& input': {
        webkitAppearance: 'initial',
        appearance: 'initial',
        width: '15px',
        height: '15px',
        background: 'rgb(255, 255, 255)',
        position: 'relative',
        border: '2px solid #3B3B3B',
        '&:checked': {
          background: 'rgb(255, 255, 255)'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
});

const DetailsPage = ({
  setFormOpen,
  detailsID,
  setReadDetails,
  setDetailsDrawerOpen,
  detailsDrawerOpen,
  setCreateMaintenanceOpen
}) => {
  const classes = useStyles();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const singleData = fakeData.find((data) => data.id === detailsID);
  const handleMarkButton = () => {
    setReadDetails(false);
    setCreateMaintenanceOpen(false);
    setFormOpen(false);
  };
  return (
    <div>
      <div className='preM-half-header d-flex justify-content-between align-items-center'>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button
            type='button'
            className='btn-mark'
            onClick={handleMarkButton}
            style={{
              background:
                singleData.status === 'Open'
                  ? '#47B39C'
                  : singleData.status === 'Not started'
                  ? '#DE6552'
                  : '#FBBE53'
            }}
            disabled={singleData.status !== 'Open' && true}
          >
            {singleData.status === 'Open'
              ? 'Accept Task'
              : singleData.status === 'Not started'
              ? 'Start Task'
              : 'Mark As Done'}
          </button>
        </div>

        <button className='close-normal' onClick={() => setFormOpen(false)}>
          Close
        </button>
      </div>
      <div className='details-info'>
        <p className='details-Title'>{singleData.title}</p>

        <hr className='form-devider' />
        <div className='details-filters'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='due-date-info'>
              <Svg.Calendar />
              <p className='detailsDueDate m-0 pl-2'>Dec 12 - Dec 13</p>
            </div>
            <div className='due-date-info'>
              <Svg.Calendar />
              <p className='detailsDueDate m-0 pl-2'>{singleData.dueON}</p>
            </div>
            <div className='priority-info'>
              <Svg.Priority />
              <p className='detailsPriority m-0 pl-2'>Priority</p>
            </div>
            <div className='assigned-info'>
              <Svg.Assigned />
              <p className='detailsAssigned m-0 pl-2'>{singleData.createdBY}</p>
            </div>
          </div>

          <div className='addExpense d-flex align-items-center'>
            <button
              className='addExpense-btn'
              onClick={() => setDetailsDrawerOpen(true)}
            >
              <Svg.PlusCircle />
            </button>
            <p className='my-0 addExpense-text'>Add Item and Expense</p>
          </div>
        </div>

        <hr className='form-devider' />
        <p className='detailsRoom my-0'>{singleData.location}</p>

        <hr className='form-devider' />

        <p className='detailsText my-0'>{singleData.description}</p>
        <hr className='form-devider' />
        <div className='details-checkList'>
          <p className='my-0'>Checklist</p>
          <ul className={classes.mrChecklist}>
            <li>
              <input type='checkbox' name='morning-dawns' id='morning-dawns' />
              <label htmlFor='morning-dawns'> When The Morning Dawns</label>
            </li>
            <li>
              <input type='checkbox' name='being-human' id='being-human' />
              <label htmlFor='being-human'> On Being Human</label>
            </li>
            <li>
              <input type='checkbox' name='peace-wish' id='peace-wish' />
              <label htmlFor='peace-wish'>
                {' '}
                Peace On Earth A Wonderful Wish But No Way
              </label>
            </li>
            <li>
              <input type='checkbox' name='phil-science' id='phil-science' />
              <label htmlFor='phil-science'> Philosophy As A Science</label>
            </li>
            <li>
              <input type='checkbox' name='vampires' id='vampires' />
              <label htmlFor='vampires'>
                Vampires The Romantic Ideology Behind Them
              </label>
            </li>
          </ul>
        </div>
        <hr className='form-devider' />
      </div>
      <div
        className='drawer-wraper'
        style={detailsDrawerOpen ? openStyle : hideStyle}
      >
        <DetailsDrawer
          detailsDrawerOpen={detailsDrawerOpen}
          setDetailsDrawerOpen={setDetailsDrawerOpen}
        />
      </div>

      <div className='expense-table'>
        <div className='addExpense expense-table-head d-flex align-items-center'>
          <p className='expense-table-title my-0 mr-3'>
            Items Needed & Expenses
          </p>
          <button
            className='addExpense-btn'
            onClick={() => setDetailsDrawerOpen(true)}
          >
            <Svg.PlusCircle />
          </button>
        </div>

        <table class='table-borderless table-expenseItem'>
          <thead>
            <tr>
              <th scope='col' style={{ width: '0 !important' }}></th>
              <th scope='col'>Items</th>
              <th scope='col'>Qty.</th>
              <th scope='col'>Price</th>
              <th scope='col'>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New Cable Box</td>
              <td>1</td>
              <td>$200</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>
                <DotMenu inline>
                  <MenuItem className={classes.menuItem}>Edit Item</MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setDeletePopUp(true)}
                  >
                    Delete Item
                  </MenuItem>
                </DotMenu>
              </td>
              <td>New TV</td>
              <td>1</td>
              <td>$300</td>
              <td>$300</td>
            </tr>

            <tr className='total-row'>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>$500</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className='form-devider' />
      <Comment />

      {deletePopUp && (
        <DeletePopUp
          deletePopUp={deletePopUp}
          setDeletePopUp={setDeletePopUp}
        />
      )}
    </div>
  );
};

export default DetailsPage;
