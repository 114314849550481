import React from 'react';
import '../../../styles/maintenance/DailyChecklists.css';
import Comment from './Comment';
import fakeCheckList from './fakeCheckList';

const DetailsPage = ({ checkListID }) => {
  const singleData = fakeCheckList.find((data) => data.id === checkListID);
  return (
    <div className='checklist-details'>
      <div className='details-info'>
        <div className='details-index'>
          <p className='my-0'>
            {singleData.id < 9 ? `0${singleData.id + 1}` : singleData.id + 1}
          </p>
        </div>

        <p className='details-title my-0'>{singleData.title}</p>
        <hr className='details-devider' />
        <p className='details-description my-0'>{singleData.desc}</p>
        <hr className='details-devider' />
      </div>

      <Comment />
    </div>
  );
};

export default DetailsPage;
