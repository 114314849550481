var todaysFakeData = [
  {
    id: 0,
    title: 'TV not working',
    location: 9999,
    createdBY: 'Guest',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Toilet flush broken',
    location: 9999,
    createdBY: 'Guest',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Not started',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'TV not working',
    location: 9999,
    createdBY: 'Guest',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'In progress',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 3,
    title: 'TV not working',
    location: 9999,
    createdBY: 'Guest',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 4,
    title: 'TV not working',
    location: 9999,
    createdBY: 'Guest',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'In progress',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default todaysFakeData;
