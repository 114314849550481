import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import '../../../styles/managerView/ChecklistsMV.css';
import FormSelect from './FormSelect';
import FormCheckbox from './FormCheckbox';
const useStyles = makeStyles({
  list: {
    height: 'auto',
    width: 500,
    margin: '64px 24px 0px 24px'
  },
  fullList: {
    width: 'auto'
  }
});

const CreateDrawer = ({
  openAddNewDrawer,
  setOpenAddNewDrawer,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const departmentOptions = [
    'Front Desk',
    'Housekeeping',
    'Maintenance',
    'Sales'
  ];
  const shiftOptions = [
    { name: 'Morning Shift', value: 'Morning Shift' },
    { name: 'Evening Shift', value: 'Evening Shift' },
    { name: 'Night Shift', value: 'Night Shift' }
  ];
  const workdayOptions = [
    { name: 'Sunday', value: 'Sunday' },
    { name: 'Monday', value: 'Monday' },
    { name: 'Tuesday', value: 'Tuesday' },
    { name: 'Wednesday', value: 'Wednesday' },
    { name: 'Thursday', value: 'Thursday' },
    { name: 'Friday', value: 'Friday' },
    { name: 'Saturday', value: 'Saturday' }
  ];
  const assignedOptions = [
    { name: 'Dwight Schrute', value: 'Dwight Schrute' },
    { name: 'Jim Halpert', value: 'Jim Halpert' }
  ];
  const reminderOptions = [
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM'
  ];
  const [checklistDept, setChecklistDept] = useState(null);
  const [checklistReminder, setChecklistReminder] = useState(null);

  const [shiftAllCheck, setShiftAllCheck] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState([]);

  const [workdayAllCheck, setWorkdayAllCheck] = useState(false);
  const [selectedWorkday, setSelectedWorkday] = useState([]);

  const [assignedAllCheck, setAssignedAllCheck] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState([]);

  const classes = useStyles();
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDetails, setTaskDetails] = useState('');

  // Handle Input change event
  const departmentSelect = (e) => {
    setChecklistDept(e);
  };
  const reminderSelect = (e) => {
    setChecklistReminder(e);
  };
  const handleTaskTitle = (e) => {
    setTaskTitle(e.target.value);
  };
  const handleTaskDetails = (e) => {
    setTaskDetails(e.target.value);
  };

  //Handle Button actions
  const closeDrawer = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenAddNewDrawer(false);
    }
  };

  const handleCancle = (e) => {
    e.preventDefault();

    setChecklistDept(null);
    setChecklistReminder(null);
    setTaskTitle('');
    setTaskDetails('');
    setSelectedShifts([]);
    setSelectedWorkday([]);
    setSelectedPerson([]);

    closeDrawer();
  };
  const handleSave = (e) => {
    e.preventDefault();

    setChecklistDept(null);
    setChecklistReminder(null);
    setTaskTitle('');
    setTaskDetails('');
    setSelectedShifts([]);
    setSelectedWorkday([]);
    setSelectedPerson([]);

    setOpenPopUp(true);
    setPopUpMSG('New checklist item added successfully');
    setPopUpType('success');
  };
  return (
    <div>
      <Drawer
        anchor='right'
        open={openAddNewDrawer}
        onClose={closeDrawer}
        transitionDuration={{ appear: 200, enter: 200, exit: 200 }}
      >
        <div className={classes.list}>
          <div className='checklistMV-add-department'>
            <p className='my-0 form-dept-head'>Choose Department</p>

            <div className='checklistMV-department-fileds'>
              <p
                className='my-0 checklistMV-input-label'
                style={{ color: checklistDept === null && '#434343' }}
              >
                Department
              </p>
              <FormSelect
                options={departmentOptions}
                value={checklistDept}
                onChange={(e) => departmentSelect(e)}
                placeholder='Which department the checklist is for?'
              />
            </div>
          </div>
          <hr className='checklistMV-form-devider' />

          <div className='checklistMV-add-items'>
            <p className='my-0 form-items-head'>Add Item On Checklist</p>
            <div className='input-item add-items-task'>
              <p
                className='my-0 checklistMV-input-label'
                style={{
                  color: taskTitle === '' && '#434343'
                }}
              >
                Title Of The Task
              </p>
              <input
                style={{
                  borderBottom:
                    taskTitle === '' && '1px solid RGBA(172, 180, 184, 0.2)'
                }}
                type='text'
                name=''
                id='checklistMV-task'
                placeholder='What is the title of the task?'
                value={taskTitle}
                onChange={handleTaskTitle}
              />
            </div>
            <div className='input-item add-item-desc'>
              <p
                className='my-0 checklistMV-input-label'
                style={{
                  color: taskDetails === '' && '#434343'
                }}
              >
                Description
              </p>
              <textarea
                style={{
                  borderBottom:
                    taskDetails === '' && '1px solid RGBA(172, 180, 184, 0.2)'
                }}
                name=''
                id='task-details'
                cols='50'
                rows='3'
                placeholder='Task details'
                value={taskDetails}
                onChange={handleTaskDetails}
              ></textarea>
            </div>

            <div className='input-item-dropdown'>
              <p
                className='my-0 checklistMV-input-label'
                style={{
                  color: selectedShifts.length <= 0 && '#434343'
                }}
              >
                Select Shift
              </p>
              <FormCheckbox
                placeholder={'Choose Shift'}
                options={shiftOptions}
                isCheckAll={shiftAllCheck}
                setIsCheckAll={setShiftAllCheck}
                allSelectID={1}
                allSelectText={'All Shifts'}
                checkedItems={selectedShifts}
                setCheckedItems={setSelectedShifts}
              />
            </div>

            <div className='input-item-dropdown'>
              <p
                className='my-0 checklistMV-input-label'
                style={{
                  color: selectedWorkday.length <= 0 && '#434343'
                }}
              >
                Work Day
              </p>
              <FormCheckbox
                placeholder={'Choose Work Day'}
                options={workdayOptions}
                isCheckAll={workdayAllCheck}
                setIsCheckAll={setWorkdayAllCheck}
                allSelectID={2}
                allSelectText={'Task For Everyday'}
                checkedItems={selectedWorkday}
                setCheckedItems={setSelectedWorkday}
              />
            </div>

            <div className='input-item-dropdown'>
              <p
                className='my-0 checklistMV-input-label'
                style={{
                  color: selectedPerson.length <= 0 && '#434343'
                }}
              >
                Assigned To
              </p>
              <FormCheckbox
                placeholder={'Who is this ask for?'}
                options={assignedOptions}
                isCheckAll={assignedAllCheck}
                setIsCheckAll={setAssignedAllCheck}
                allSelectID={3}
                allSelectText={'For Everyone'}
                checkedItems={selectedPerson}
                setCheckedItems={setSelectedPerson}
              />
            </div>

            <div className='input-item-dropdown'>
              <p
                className='my-0 checklistMV-input-label'
                style={{ color: checklistReminder === null && '#434343' }}
              >
                Reminder
              </p>
              <FormSelect
                options={reminderOptions}
                value={checklistReminder}
                onChange={(e) => reminderSelect(e)}
                placeholder='Add reminder'
              />
            </div>
          </div>

          <div className='justify-content-end drawer-btns px-3'>
            <button className='drawer-cancle' onClick={handleCancle}>
              Cancel
            </button>
            <button className='drawer-save' onClick={handleSave}>
              Save & Add Another
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateDrawer;
