import { FormGroup, Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';
import Statistics from '../../components/dashboard/overview/Statistics';
import WeeklyCompare from '../../components/dashboard/overview/WeeklyCompare';
import DailyCompare from '../../components/dashboard/overview/DailyCompare';
import '../../styles/dashboard/Overview.css';
import OurRateToday from '../../components/dashboard/overview/OurRateToday';
import RateWeOffer from '../../components/dashboard/overview/RateWeOffer';
import OnLeave from '../../components/dashboard/overview/OnLeave';
import GroupsEvents from '../../components/dashboard/overview/GroupsEvents';
import AntSwitch from '../../components/common/AntSwitch';

const Overview = () => {
  const [toggled, setToggled] = useState({ checked: false });

  const handleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };
  return (
    <div className='main-section overview'>
      <div className='overview-title'>
        <p className='my-0'>Today's Overview</p>
      </div>
      <Statistics />

      {/* Hotel Rate Comparison Section */}
      <div className='comparison-section my-4'>
        <div className='table-top d-flex justify-content-between align-items-center'>
          <p className='dash-table-title m-0'>
            Hotel Rate Comparison <ArrowDropDownIcon fontSize='large' />
          </p>
          <FormGroup>
            <Typography component='div'>
              <Grid component='label' container alignItems='center' spacing={2}>
                <Grid item>
                  <span className='m-0'>Weekly View</span>
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={toggled.checked}
                    onChange={handleChange}
                    name='checked'
                  />
                </Grid>
              </Grid>
            </Typography>
          </FormGroup>
        </div>
        {/* Comparison Table */}
        <div className='dash-table'>
          {toggled.checked === true ? <WeeklyCompare /> : <DailyCompare />}
        </div>
      </div>

      {/* OurRateToday & RateWeOffer */}
      <div className='d-flex justify-content-between my-4'>
        <div className='our-rate-section mr-2'>
          <div className='table-top d-flex justify-content-between align-items-center'>
            <p className='dash-table-title m-0'>Our Rates Today </p>
          </div>
          <div className='dash-table'>
            <OurRateToday></OurRateToday>
          </div>
        </div>
        <div className='we-offer-section ml-2'>
          <div className='table-top d-flex justify-content-between align-items-center'>
            <p className='dash-table-title m-0'>Rate We Can Offer </p>
          </div>
          <div className='dash-table'>
            <RateWeOffer></RateWeOffer>
          </div>
        </div>
      </div>

      {/* On Leave Section */}
      <div className='onLeave-section my-4'>
        <div className='table-top d-flex justify-content-between align-items-center'>
          <p className='dash-table-title m-0'>On Leave Today</p>
        </div>
        <div className='dash-table'>
          <OnLeave />
        </div>
      </div>

      {/* Group & Event Section */}
      <div className='event-section my-4'>
        <div className='table-top d-flex justify-content-between align-items-center'>
          <p className='dash-table-title m-0'>Groups & Events</p>
        </div>
        <div className='dash-table'>
          <GroupsEvents />
        </div>
      </div>
    </div>
  );
};

export default Overview;
