import React, { useState } from 'react';
import FilterMenu from '../../components/common/FilterMenu';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import '../../styles/maintenance/Replacements.css';
import ReplaceTodayTable from '../../components/maintenance/replacements/ReplaceTodayTable';
import ReplaceUpcomingTable from '../../components/maintenance/replacements/ReplaceUpcomingTable';
import AddItemDrawer from '../../components/maintenance/replacements/AddItemDrawer';
import DetailsDrawer from '../../components/maintenance/replacements/DetailsDrawer';
import SuccessPopUp from '../../components/maintenance/replacements/SuccessPopUp';

const openStyle = {
  visibility: 'visible',
  display: 'unset'
};
const hideStyle = {
  visibility: 'hidden',
  display: 'none'
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '45px',
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});
const Replacements = () => {
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];
  const classes = useStyles();
  const [showToday, setShowToday] = useState(true);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [successMSG, setSuccessMSG] = useState('');
  const [detailsID, setDetailsID] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAddNewClick = (event, index) => {
    setAddDrawerOpen(true);
  };
  return (
    <div className='replacements main-section'>
      <div className='replacements-req-head d-flex justify-content-between align-items-center'>
        <p className='main-title my-0'>Replacements Tracking</p>

        <div className='d-flex justify-content-between align-items-center'>
          <div className='filters'>
            <FilterMenu
              value={filters.date}
              onChange={(e) => filterChange('date', e)}
              options={filterByDateOptions}
              placeholder='Filter Date'
            />
          </div>

          <div className={classes.addNewButton}>
            <Button className='main-button' onClick={handleAddNewClick}>
              Add Item
            </Button>
          </div>
        </div>
      </div>

      {/* Replacements Tables */}
      <div className='replacement-tables'>
        <hr className='main-devider' />
        <div className='table-today-replacement'>
          <p className='replacement-table-head m-0'>
            {showToday === false ? (
              <ArrowDropUpIcon
                className='aria-expand-btn'
                fontSize='large'
                onClick={() => setShowToday(true)}
              />
            ) : (
              <ArrowDropDownIcon
                className='aria-expand-btn'
                fontSize='large'
                onClick={() => setShowToday(false)}
              />
            )}
            Today{' '}
          </p>
          {showToday && (
            <ReplaceTodayTable
              setDetailsDrawerOpen={setDetailsDrawerOpen}
              setAddDrawerOpen={setAddDrawerOpen}
              setDetailsID={setDetailsID}
            />
          )}
        </div>

        <div className='table-upcoming-replacement mb-4'>
          <p className='replacement-table-head m-0'>
            {showUpcoming === false ? (
              <ArrowDropUpIcon
                className='aria-expand-btn'
                fontSize='large'
                onClick={() => setShowUpcoming(true)}
              />
            ) : (
              <ArrowDropDownIcon
                className='aria-expand-btn'
                fontSize='large'
                onClick={() => setShowUpcoming(false)}
              />
            )}
            Upcoming
          </p>
          {showUpcoming && <ReplaceUpcomingTable />}
        </div>
      </div>

      <div
        className='drawer-wraper'
        style={addDrawerOpen ? openStyle : hideStyle}
      >
        <AddItemDrawer
          addDrawerOpen={addDrawerOpen}
          setAddDrawerOpen={setAddDrawerOpen}
          setOpenPopUp={setOpenPopUp}
          setSuccessMSG={setSuccessMSG}
        />
      </div>

      <div
        className='drawer-wraper'
        style={detailsDrawerOpen ? openStyle : hideStyle}
      >
        <DetailsDrawer
          detailsDrawerOpen={detailsDrawerOpen}
          setDetailsDrawerOpen={setDetailsDrawerOpen}
          detailsID={detailsID}
          setOpenPopUp={setOpenPopUp}
          setSuccessMSG={setSuccessMSG}
        />
      </div>

      {openPopUp && (
        <SuccessPopUp
          openPopUp={openPopUp}
          successMSG={successMSG}
          setOpenPopUp={setOpenPopUp}
          setDetailsDrawerOpen={setDetailsDrawerOpen}
          setAddDrawerOpen={setAddDrawerOpen}
        />
      )}
    </div>
  );
};

export default Replacements;
