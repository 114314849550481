import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 18,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 3,
    color: '#ffffff',
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#DE6552',
        borderColor: '#DE6552'
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid #D8D8D8`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: '#D8D8D8'
  },
  checked: {}
}))(Switch);

export default AntSwitch;
