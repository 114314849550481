import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import editValue from './preDeparments';
import '../../../styles/managerView/DepartmentsMV.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: 'RGBA(0, 0, 0, 0.5)'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ffffff',
    borderRadius: '3px',
    boxShadow: theme.shadows[5],
    padding: 0,
    height: 'auto'
  }
}));

const EditDepartment = ({
  openEditDept,
  setOpenEditDept,
  editID,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const classes = useStyles();

  const inputValue = editValue.find((data) => data.id === editID);

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenPopUp(true);
    setPopUpMSG('Department edited successfully');
    setPopUpType('success');
    setOpenEditDept(false);
  };
  const handleCancle = (e) => {
    e.preventDefault();
    setOpenEditDept(false);
    setOpenPopUp(false);
    setPopUpMSG('');
    setPopUpType(null);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenEditDept(false);
      setOpenPopUp(false);
      setPopUpMSG('');
      setPopUpType(null);
    }
  };
  return (
    <div className='addNew-modal'>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openEditDept}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openEditDept}>
          <div className={classes.paper}>
            <div className='editDept-form'>
              <p className='my-0 form-title'>Edit Department</p>
              <form action=''>
                <div className='department-input-box'>
                  <p className='my-0 department-input-label'>Department Name</p>
                  <input
                    type='text'
                    name=''
                    id=''
                    placeholder='Edit department name'
                    value={inputValue.title}
                  />
                </div>

                <div className='addDepartment-btns'>
                  <div
                    className='btn-group'
                    role='group'
                    aria-label='Basic example'
                  >
                    <button className='editDept-submit' onClick={handleEdit}>
                      Update Department Name
                    </button>
                    <button className='editDept-cancle' onClick={handleCancle}>
                      Cancel Edit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditDepartment;
