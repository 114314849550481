import React from 'react';
import '../../../styles/maintenance/PreventiveMaintenance.css';
import tableData from './fakeChecklist';

const TableFrontDeskShort = ({ setReadDetails, setDetailsID, setFormOpen }) => {
  const handleDataClick = (id) => {
    setFormOpen(true);
    setReadDetails(true);
    setDetailsID(id);
  };
  return (
    <table className='table-checklistMV short-tables'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Daily Checklist Tasks</th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id} onClick={() => handleDataClick(id)}>
                <td>{data.task}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TableFrontDeskShort;
