import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import editValue from './fakeRoomType';
import '../../../styles/managerView/RoomTypeMV.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: 'RGBA(0, 0, 0, 0.5)'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ffffff',
    borderRadius: '3px',
    boxShadow: theme.shadows[5],
    padding: 0,
    height: 'auto'
  }
}));

const EditRoomType = ({
  openEditRoomType,
  setOpenEditRoomType,
  editID,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const classes = useStyles();

  const inputValue = editValue.find((data) => data.id === editID);

  const handleEdit = (e) => {
    e.preventDefault();
    setOpenPopUp(true);
    setPopUpMSG('Room type edited successfully');
    setPopUpType('success');
    setOpenEditRoomType(false);
  };
  const handleCancle = (e) => {
    e.preventDefault();
    setOpenEditRoomType(false);
    setOpenPopUp(false);
    setPopUpMSG('');
    setPopUpType(null);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenEditRoomType(false);
      setOpenPopUp(false);
      setPopUpMSG('');
      setPopUpType(null);
    }
  };
  return (
    <div className='addNew-modal'>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openEditRoomType}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openEditRoomType}>
          <div className={classes.paper}>
            <div className='editRoomType-form'>
              <p className='my-0 form-title'>Edit Room Type</p>
              <form action=''>
                <div className='roomType-input-box'>
                  <p className='my-0 roomType-input-label'>Room Type</p>
                  <input
                    type='text'
                    name=''
                    id=''
                    placeholder='Edit Room Type'
                    value={inputValue.title}
                  />
                </div>

                <div className='editRoomType-btns'>
                  <div
                    className='btn-group'
                    role='group'
                    aria-label='Basic example'
                  >
                    <button className='editRoom-submit' onClick={handleEdit}>
                      Update Room Type
                    </button>
                    <button className='editRoom-cancle' onClick={handleCancle}>
                      Cancel Edit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditRoomType;
