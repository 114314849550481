import React from 'react';

export function Dashboard() {
  return (
    <svg
      width='22px'
      height='17px'
      viewBox='0 0 22 17'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Dashboard</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Navigation/Icons-Only'
          transform='translate(-34.000000, 0.000000)'
          fill='#D3D3D3'
          fillRule='nonzero'
        >
          <g transform='translate(34.000000, 0.000000)' id='Icons/Home/Select'>
            <path
              d='M11,0 C4.935,0 0,4.935 0,11 C0,12.935 0.509,14.837 1.472,16.502 L1.761,17 L7,17 L7,15 L15,15 L15,17 L20.239,17 L20.528,16.501 C21.491,14.836 22,12.935 22,11 C22,4.935 17.065,0 11,0 Z M11,2 C11.552,2 12,2.448 12,3 C12,3.552 11.552,4 11,4 C10.448,4 10,3.552 10,3 C10,2.448 10.448,2 11,2 Z M15.843,3.414 C16.309,3.711 16.445,4.329 16.148,4.795 C15.851,5.26 15.233,5.397 14.767,5.1 C14.301,4.803 14.165,4.184 14.462,3.719 C14.759,3.253 15.377,3.117 15.843,3.414 Z M6.094,3.455 C6.557,3.154 7.176,3.285 7.477,3.748 C7.778,4.211 7.647,4.83 7.184,5.131 C6.721,5.432 6.102,5.301 5.801,4.838 C5.499,4.375 5.631,3.756 6.094,3.455 Z M3.145,6.608 C3.415,6.126 4.024,5.954 4.506,6.223 C4.988,6.493 5.16,7.102 4.891,7.584 C4.621,8.066 4.012,8.238 3.53,7.969 C3.047,7.699 2.875,7.09 3.145,6.608 Z M3,12 C2.448,12 2,11.552 2,11 C2,10.448 2.448,10 3,10 C3.552,10 4,10.448 4,11 C4,11.552 3.552,12 3,12 Z M12.323,12.493 L12.322,12.491 C11.969,12.804 11.509,13 11,13 C9.895,13 9,12.105 9,11 C9,10.067 9.641,9.29 10.505,9.069 L10.504,9.066 L10.563,9.051 C10.578,9.048 17.53,7.249 17.53,7.249 L12.323,12.493 Z M19,12 C18.448,12 18,11.552 18,11 C18,10.448 18.448,10 19,10 C19.552,10 20,10.448 20,11 C20,11.552 19.552,12 19,12 Z'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Schedule() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Schedule</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.361328 6.61719C1.52742 3.78744 3.78676 1.52844 6.61523 0.361328L7.37695 2.21094C5.04343 3.17382 3.17284 5.04466 2.21094 7.37891L0.361328 6.61719ZM3 11C3 6.589 6.589 3 11 3C15.411 3 19 6.589 19 11C19 15.411 15.411 19 11 19C6.589 19 3 15.411 3 11ZM12 7.02539V10.6367L13.7578 12.7441L12.2207 14.0254L10 11.3613V7.02539H12ZM14.6172 19.7891C16.9507 18.8262 18.8213 16.9534 19.7832 14.6191L21.6328 15.3828C20.4667 18.2126 18.2074 20.4696 15.3789 21.6367L14.6172 19.7891Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}

export function Operations() {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Operations</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 2H11.816C11.403 0.837 10.304 0 9 0C7.696 0 6.597 0.837 6.184 2H2C0.895 2 0 2.895 0 4V18C0 19.105 0.895 20 2 20H16C17.105 20 18 19.105 18 18V4C18 2.895 17.105 2 16 2ZM5.707 9.293L8 11.586L12.293 7.293L13.707 8.707L8 14.414L4.293 10.707L5.707 9.293ZM10 3C10 2.448 9.552 2 9 2C8.448 2 8 2.448 8 3C8 3.552 8.448 4 9 4C9.552 4 10 3.552 10 3Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}

export function Maintenance() {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Maintenance</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 6C7 2.691 9.691 0 13 0C13.734 0 14.4686 0.142828 15.1836 0.423828L15.5898 0.582031L13.5859 2.58594C12.8049 3.36694 12.8049 4.63306 13.5859 5.41406C13.9759 5.80506 14.488 6 15 6C15.512 6 16.0241 5.80506 16.4141 5.41406L18.418 3.41016L18.5762 3.81641C18.8572 4.53141 19 5.266 19 6C19 9.309 16.309 12 13 12C12.482 12 11.9641 11.9291 11.4531 11.7891L6.87695 16.3652C6.48618 17.8706 5.12102 19 3.5 19C1.57885 19 0 17.4212 0 15.5C0 13.879 1.12945 12.5138 2.63477 12.123L7.21094 7.54688C7.07094 7.03587 7 6.518 7 6ZM5 15.5C5 14.6597 4.34027 14 3.5 14C2.65973 14 2 14.6597 2 15.5C2 16.3403 2.65973 17 3.5 17C4.34027 17 5 16.3403 5 15.5Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}

export function HouseKeeping() {
  return (
    <svg
      width='17px'
      height='20px'
      viewBox='0 0 17 20'
      version='1.1'
      fill='#b8b4cc'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Housekeeping</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Navigation/Icons-Only'
          transform='translate(-37.000000, -398.000000)'
          fill='#b8b4cc'
          fillRule='nonzero'
        >
          <g transform='translate(34.000000, 0.000000)' id='Navigation'>
            <g id='Housekeeping' transform='translate(0.000000, 398.000000)'>
              <g
                id='icons8-housekeeper_female'
                transform='translate(3.000000, 0.000000)'
              >
                <path
                  d='M5,0 C3.8954305,0 3,0.8954305 3,2 C3,3.1045695 3.8954305,4 5,4 C6.1045695,4 7,3.1045695 7,2 C7,0.8954305 6.1045695,0 5,0 Z M14,3 C13.448,3 13,3.448 13,4 L13,14 L12.716797,14 C12.227797,14 11.810469,14.352938 11.730469,14.835938 L11.009766,19.150391 C10.935766,19.595391 11.279469,20 11.730469,20 L16.269531,20 C16.720531,20 17.063281,19.595391 16.988281,19.150391 L16.269531,14.835938 C16.190531,14.352938 15.772203,14 15.283203,14 L15,14 L15,4 C15,3.448 14.552,3 14,3 Z M3.6699219,5 C2.7029219,5 1.8741719,5.6905781 1.7011719,6.6425781 L0.2148438,14.822266 C0.1038438,15.435266 0.5732656,16 1.1972656,16 L2,16 L2,19 C2,19.552 2.448,20 3,20 C3.552,20 4,19.552 4,19 L4,16 L6,16 L6,19 C6,19.552 6.448,20 7,20 C7.552,20 8,19.552 8,19 L8,16 L8.802734,16 C9.426734,16 9.896156,15.434312 9.785156,14.820312 L8.298828,6.6425781 C8.125828,5.6905781 7.297078,5 6.3300781,5 L3.6699219,5 Z'
                  id='Shape'
                  fill='#b8b4cc'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function Reports() {
  return (
    <svg
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Reports</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 0H21H22V2H21V13C21 14.105 20.105 15 19 15H12V18H10V15H3C1.895 15 1 14.105 1 13V2H0V0H1ZM14 6.414L16.543 8.957L17.957 7.543L14 3.586L10 7.586L8 5.586L4.043 9.543L5.457 10.957L8 8.414L10 10.414L14 6.414Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Settings() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      x='0'
      y='0'
      viewBox='0 0 512 512'
    >
      <g>
        <g xmlns='http://www.w3.org/2000/svg'>
          <path
            d='m497 201h-22.785c-5.339-21.313-13.722-41.546-25.025-60.407l16.114-16.114c2.813-2.813 4.394-6.628 4.394-10.607 0-3.978-1.58-7.793-4.394-10.606l-56.568-56.569c-2.812-2.813-6.628-4.394-10.606-4.394s-7.794 1.581-10.606 4.394l-16.115 16.115c-18.861-11.305-39.098-19.688-60.409-25.027v-22.785c0-8.284-6.716-15-15-15h-80c-8.284 0-15 6.716-15 15v22.785c-21.311 5.338-41.548 13.722-60.407 25.026l-16.114-16.115c-2.813-2.813-6.628-4.394-10.607-4.394s-7.793 1.581-10.606 4.394l-56.568 56.569c-5.858 5.858-5.858 15.355 0 21.213l16.114 16.115c-11.305 18.86-19.688 39.094-25.027 60.407h-22.785c-8.284 0-15 6.716-15 15v80c0 8.284 6.716 15 15 15h22.785c5.339 21.313 13.722 41.547 25.026 60.407l-16.114 16.115c-5.858 5.858-5.858 15.355 0 21.213l56.568 56.568c5.857 5.858 15.355 5.858 21.213 0l16.115-16.114c18.86 11.303 39.097 19.687 60.407 25.025v22.786c0 8.284 6.716 15 15 15h80c8.284 0 15-6.716 15-15v-22.786c21.311-5.338 41.548-13.722 60.407-25.025l16.115 16.115c5.857 5.857 15.355 5.858 21.213 0l56.568-56.568c2.813-2.813 4.394-6.628 4.394-10.606 0-3.979-1.58-7.794-4.394-10.607l-16.114-16.114c11.304-18.86 19.687-39.094 25.025-60.407h22.786c8.284 0 15-6.716 15-15v-80c0-8.286-6.716-15.002-15-15.002zm-241 40c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-133.454 57.409c-4.247-13.378-6.546-27.625-6.546-42.409 0-67.021 47.098-123.032 110-136.767v84.814c-17.929 10.376-30 29.749-30 51.953zm133.454 97.591c-40.968 0-77.823-17.599-103.425-45.647l73.433-42.396c8.825 5.106 19.063 8.043 29.992 8.043s21.167-2.937 29.992-8.042l73.433 42.396c-25.602 28.047-62.457 45.646-103.425 45.646zm133.454-97.591-73.454-42.409c0-22.205-12.071-41.577-30-51.953v-84.814c62.902 13.735 110 69.746 110 136.767 0 14.784-2.299 29.031-6.546 42.409z'
            fill='#b8b4cc'
            data-original='#000000'
          />
        </g>
      </g>
    </svg>
  );
}

export function Announcements() {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7788 0.873481C20.044 0.33937 20.584 0 21.1752 0C22.0379 0 22.7368 0.70703 22.7368 1.57975V9.28885C23.5179 9.74499 23.9993 10.5877 24 11.5C23.9984 12.4114 23.5171 13.253 22.7368 13.7087V21.4202C22.7368 22.293 22.0379 23 21.1752 23C20.584 23 20.044 22.6606 19.7788 22.1265L18.9474 20.4444L10.8701 16.943C10.4052 16.7424 10.1053 16.2787 10.1053 15.7676V7.22993C10.1053 6.71881 10.4052 6.25886 10.8701 6.05697L18.9474 2.55556L19.7788 0.873481ZM0 8.94444C0 7.53889 1.13684 6.38889 2.52632 6.38889H6.31579C7.01305 6.38889 7.57895 6.96133 7.57895 7.66667V14.0556V21.7222C7.57895 22.4276 7.01305 23 6.31579 23C5.61853 23 5.05263 22.4276 5.05263 21.7222V16.6111H2.52632C1.13684 16.6111 0 15.4611 0 14.0556V8.94444Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Tasks() {
  return (
    <svg
      width='22'
      height='24'
      viewBox='0 0 22 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5556 2.4H14.4418C13.937 1.0044 12.5938 0 11 0C9.40622 0 8.063 1.0044 7.55822 2.4H2.44444C1.09389 2.4 0 3.474 0 4.8V21.6C0 22.926 1.09389 24 2.44444 24H19.5556C20.9061 24 22 22.926 22 21.6V4.8C22 3.474 20.9061 2.4 19.5556 2.4ZM4.88889 9V7.8C4.88889 7.4688 5.16267 7.2 5.5 7.2H16.5C16.8373 7.2 17.1111 7.4688 17.1111 7.8V9C17.1111 9.3312 16.8373 9.6 16.5 9.6H5.5C5.16267 9.6 4.88889 9.3312 4.88889 9ZM14.6667 13.8V12.6C14.6667 12.2688 14.3929 12 14.0556 12H5.5C5.16267 12 4.88889 12.2688 4.88889 12.6V13.8C4.88889 14.1312 5.16267 14.4 5.5 14.4H14.0556C14.3929 14.4 14.6667 14.1312 14.6667 13.8ZM16.5 16.8C16.8373 16.8 17.1111 17.0688 17.1111 17.4V18.6C17.1111 18.9312 16.8373 19.2 16.5 19.2H5.5C5.16267 19.2 4.88889 18.9312 4.88889 18.6V17.4C4.88889 17.0688 5.16267 16.8 5.5 16.8H16.5ZM12.2222 3.6C12.2222 2.9376 11.6747 2.4 11 2.4C10.3253 2.4 9.77778 2.9376 9.77778 3.6C9.77778 4.2624 10.3253 4.8 11 4.8C11.6747 4.8 12.2222 4.2624 12.2222 3.6Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Requests() {
  return (
    <svg
      width='28'
      height='24'
      viewBox='0 0 28 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 0C18.5267 0 17.3333 1.19333 17.3333 2.66667V11.1875C17.3333 11.9088 18.2068 12.2711 18.7161 11.7604L20.9792 9.33333H25.3333C25.8853 9.33333 26.3976 9.1656 26.8229 8.8776C27.1069 8.6856 27.3523 8.44025 27.5443 8.15625C27.8323 7.73092 28 7.21867 28 6.66667V2.66667C28 2.11467 27.8323 1.60242 27.5443 1.17708C27.3523 0.893083 27.1069 0.647729 26.8229 0.455729C26.3976 0.167729 25.8853 0 25.3333 0H20ZM10 2.66667C7.42267 2.66667 5.33333 4.756 5.33333 7.33333C5.33333 9.91066 7.42267 12 10 12C12.5773 12 14.6667 9.91066 14.6667 7.33333C14.6667 4.756 12.5773 2.66667 10 2.66667ZM0 20.6667C0 17.06 6.02 14.6667 10 14.6667C13.98 14.6667 20 17.06 20 20.6667V22.6667C20 23.4027 19.4027 24 18.6667 24H1.33333C0.597333 24 0 23.4027 0 22.6667V20.6667Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Efficiency() {
  return (
    <svg
      width='26'
      height='22'
      viewBox='0 0 26 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.18182 0H24.8182C25.4705 0 26 0.547556 26 1.22222C26 1.89689 25.4705 2.44444 24.8182 2.44444V15.8889C24.8182 17.2394 23.7605 18.3333 22.4545 18.3333H14.1818V20.7778C14.1818 21.4524 13.6524 22 13 22C12.3476 22 11.8182 21.4524 11.8182 20.7778V18.3333H3.54545C2.23955 18.3333 1.18182 17.2394 1.18182 15.8889V2.44444C0.529454 2.44444 0 1.89689 0 1.22222C0 0.547556 0.529454 0 1.18182 0ZM7.15945 14.5347C9.958 15.1959 12.4705 13.2868 12.9267 10.6407C13.0035 10.1921 12.6608 9.77778 12.2188 9.77778H8.27273V5.69678C8.27273 5.23967 7.87209 4.88522 7.43836 4.96467C4.87973 5.43522 3.03491 8.03489 3.67309 10.9291C4.06309 12.6964 5.45055 14.1313 7.15945 14.5347ZM22.4545 12.2222C22.4545 12.8969 21.9251 13.4444 21.2727 13.4444H16.5455C15.8931 13.4444 15.3636 12.8969 15.3636 12.2222C15.3636 11.5476 15.8931 11 16.5455 11H21.2727C21.9251 11 22.4545 11.5476 22.4545 12.2222ZM16.5455 6.11111C15.8931 6.11111 15.3636 6.65867 15.3636 7.33333C15.3636 8.008 15.8931 8.55556 16.5455 8.55556H21.2727C21.9251 8.55556 22.4545 8.008 22.4545 7.33333C22.4545 6.65867 21.9251 6.11111 21.2727 6.11111H16.5455Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Guests() {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2733 0.300194C19.3824 0.111656 19.5836 -0.00302622 19.8 6.07875e-05H22.2C22.5314 9.42088e-05 22.8 0.271059 22.8 0.605322V0.671523C22.8005 0.794732 22.7637 0.915158 22.6945 1.01671L20.9039 3.63163H22.2C22.4164 3.62854 22.6176 3.74323 22.7267 3.93176C22.8358 4.1203 22.8358 4.35348 22.7267 4.54202C22.6176 4.73056 22.4164 4.84524 22.2 4.84215H19.8C19.4686 4.84212 19.2 4.57115 19.2 4.23689V4.17778C19.1995 4.05457 19.2363 3.93415 19.3055 3.8326L21.1008 1.21058H19.8C19.5836 1.21367 19.3824 1.09899 19.2733 0.910451C19.1642 0.721914 19.1642 0.488731 19.2733 0.300194ZM14.4733 2.72124C14.5824 2.5327 14.7836 2.41802 15 2.42111H17.4C17.7314 2.42114 18 2.69211 18 3.02637V3.09257C18.0005 3.21578 17.9637 3.3362 17.8945 3.43776L16.1039 6.05268H17.4C17.6164 6.04959 17.8176 6.16427 17.9267 6.35281C18.0358 6.54135 18.0358 6.77453 17.9267 6.96307C17.8176 7.1516 17.6164 7.26629 17.4 7.2632H15C14.6686 7.26317 14.4 6.9922 14.4 6.65794V6.59883C14.3995 6.47562 14.4363 6.3552 14.5055 6.25364L16.3008 3.63163H15C14.7836 3.63472 14.5824 3.52003 14.4733 3.3315C14.3642 3.14296 14.3642 2.90978 14.4733 2.72124ZM1.2 3.63163C0.5376 3.63163 0 4.17394 0 4.84215V21.7895C0 22.4577 0.5376 23 1.2 23C1.8624 23 2.4 22.4577 2.4 21.7895V20.579H21.6V21.7895C21.6 22.4577 22.1376 23 22.8 23C23.4624 23 24 22.4577 24 21.7895V12.1053C24 10.7677 22.926 9.68425 21.6 9.68425H12C10.674 9.68425 9.6 10.7677 9.6 12.1053V13.3158H6C7.32548 13.3158 8.4 12.2319 8.4 10.8948C8.4 9.55766 7.32548 8.47372 6 8.47372C4.67452 8.47372 3.6 9.55766 3.6 10.8948C3.6 12.2319 4.67452 13.3158 6 13.3158H2.4V4.84215C2.4 4.17394 1.8624 3.63163 1.2 3.63163Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Arrivals() {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.0522 0C7.38921 0 8.47308 1.07452 8.47308 2.4C8.47308 3.72548 7.38921 4.8 6.0522 4.8C4.71518 4.8 3.63132 3.72548 3.63132 2.4C3.63132 1.07452 4.71518 0 6.0522 0ZM8.75914 6L14.303 8.74922L14.9059 10.5398L14.9083 10.5375L16.5939 15.4289L19.1708 14.4469C19.7978 14.2177 20.4941 14.5366 20.7241 15.1594L22.9251 20.9227C23.1563 21.5443 22.8346 22.2345 22.2064 22.4625L21.7737 22.6664C21.7011 20.7404 20.1163 19.2 18.1566 19.2C17.2754 19.2 16.4774 19.5242 15.8492 20.0414L13.4898 13.6102L12.3266 10.4531L10.1776 9.38672L13.2959 24H10.8207L9.14922 16.1672C8.94654 16.4059 8.70648 16.6961 8.46196 16.9917L8.46192 16.9918L8.46186 16.9918C8.00695 17.5418 7.53659 18.1105 7.26264 18.4008V24H4.84176V17.6367L6.26261 15.525L5.25548 11.3297C4.91172 11.9345 4.49843 12.4564 4.01667 12.8836C2.89823 13.876 1.50942 14.4 0 14.4V12C0.930828 12 1.71565 11.7037 2.40197 11.0953C3.01566 10.5517 3.46991 9.70933 3.74953 8.59453C4.26759 6.54133 6.27371 6 7.26264 6H8.75914ZM19.367 22.8C19.367 22.1373 18.8251 21.6 18.1566 21.6C17.4881 21.6 16.9462 22.1373 16.9462 22.8C16.9462 23.4627 17.4881 24 18.1566 24C18.8251 24 19.367 23.4627 19.367 22.8Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Departures() {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.9478 0C15.6108 0 14.5269 1.07452 14.5269 2.4C14.5269 3.72548 15.6108 4.8 16.9478 4.8C18.2848 4.8 19.3687 3.72548 19.3687 2.4C19.3687 1.07452 18.2848 0 16.9478 0ZM14.2409 6L8.69695 8.74922L8.0941 10.5398L8.09173 10.5375L6.4061 15.4289L3.82919 14.4469C3.20218 14.2177 2.50593 14.5366 2.27595 15.1594L0.0749344 20.9227C-0.15626 21.5443 0.165415 22.2345 0.793633 22.4625L1.22627 22.6664C1.2989 20.7404 2.8837 19.2 4.8434 19.2C5.7246 19.2 6.52259 19.5242 7.15081 20.0414L9.51022 13.6102L10.6734 10.4531L12.8224 9.38672L9.70408 24H12.1793L13.8508 16.1672C14.0535 16.4059 14.2935 16.6961 14.538 16.9917L14.5381 16.9918L14.5381 16.9918C14.9931 17.5418 15.4634 18.1105 15.7374 18.4008V24H18.1582V17.6367L16.7374 15.525L17.7445 11.3297C18.0883 11.9345 18.5016 12.4564 18.9833 12.8836C20.1018 13.876 21.4906 14.4 23 14.4V12C22.0692 12 21.2844 11.7037 20.598 11.0953C19.9843 10.5517 19.5301 9.70933 19.2505 8.59453C18.7324 6.54133 16.7263 6 15.7374 6H14.2409ZM3.63297 22.8C3.63297 22.1373 4.1749 21.6 4.8434 21.6C5.51191 21.6 6.05384 22.1373 6.05384 22.8C6.05384 23.4627 5.51191 24 4.8434 24C4.1749 24 3.63297 23.4627 3.63297 22.8Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}

export function Forecast() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2 2.4C19.2 1.07452 20.2745 0 21.6 0C22.9255 0 24 1.07452 24 2.4C24 3.72548 22.9255 4.8 21.6 4.8C21.3895 4.80012 21.1799 4.77254 20.9766 4.71797L18.9234 7.28438C19.1046 7.62836 19.1995 8.01121 19.2 8.4C19.2 9.72548 18.1255 10.8 16.8 10.8C15.4745 10.8 14.4 9.72548 14.4 8.4C14.4013 8.35854 14.4036 8.31712 14.407 8.27578L11.1375 6.64219C10.7061 7.00241 10.162 7.19981 9.6 7.2C9.038 7.19981 8.49388 7.00241 8.0625 6.64219L4.79531 8.27578C4.79795 8.31714 4.79951 8.35856 4.8 8.4C4.8 9.72548 3.72548 10.8 2.4 10.8C1.07452 10.8 0 9.72548 0 8.4C0 7.07452 1.07452 6 2.4 6C2.962 6.00019 3.50612 6.19759 3.9375 6.55781L7.20469 4.92422C7.20205 4.88286 7.20049 4.84144 7.2 4.8C7.2 3.47452 8.27452 2.4 9.6 2.4C10.9255 2.4 12 3.47452 12 4.8C11.9987 4.84146 11.9964 4.88288 11.993 4.92422L15.2625 6.56016C15.6936 6.19909 16.2377 6.00084 16.8 6C17.0106 6.00065 17.2202 6.02902 17.4234 6.08437L19.4766 3.51797C19.295 3.17328 19.2001 2.78958 19.2 2.4ZM19.2 15.6C19.2 14.2745 20.2745 13.2 21.6 13.2C22.9255 13.2 24 14.2745 24 15.6C24 16.9255 22.9255 18 21.6 18C21.3649 17.9998 21.1312 17.965 20.9062 17.8969L18.9094 20.4562C19.0999 20.8074 19.1998 21.2005 19.2 21.6C19.2 22.9255 18.1255 24 16.8 24C15.4745 24 14.4 22.9255 14.4 21.6C14.3999 21.3895 14.4275 21.1799 14.482 20.9766L11.9156 18.9234C11.5716 19.1046 11.1888 19.1995 10.8 19.2C10.0844 19.1996 9.40639 18.8799 8.95078 18.3281L4.77422 19.5211C4.6137 20.7101 3.59979 21.5979 2.4 21.6C1.07452 21.6 0 20.5255 0 19.2C0 17.8745 1.07452 16.8 2.4 16.8C3.11556 16.8004 3.79361 17.1201 4.24922 17.6719L8.42578 16.4789C8.5863 15.2899 9.60021 14.4021 10.8 14.4C12.1255 14.4 13.2 15.4745 13.2 16.8C13.2001 17.0105 13.1725 17.2201 13.118 17.4234L15.682 19.4766C16.0267 19.295 16.4104 19.2001 16.8 19.2C17.0009 19.2008 17.2009 19.2267 17.3953 19.2773L19.4438 16.65C19.2839 16.323 19.2006 15.964 19.2 15.6Z'
        fill='#B8B4CC'
      />
    </svg>
  );
}
export function Repeat({ color }) {
  return (
    <svg
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.80852 0.0418383C7.85539 0.0208385 7.90433 0.00744933 7.95304 0.00242093C8.09917 -0.0126642 8.24617 0.0430911 8.35342 0.16009L9.85338 1.79644C10.0489 2.00971 10.0489 2.35447 9.85338 2.56774L8.35342 4.2041C8.25792 4.30828 8.12991 4.3639 7.99992 4.3639C7.93542 4.3639 7.87051 4.35017 7.80852 4.32235C7.62152 4.2378 7.49993 4.03881 7.49993 3.81844V2.72754H1.00011V5.4548C1.00266 5.65151 0.907927 5.83448 0.752183 5.93364C0.596439 6.03281 0.403814 6.03281 0.24807 5.93364C0.0923257 5.83448 -0.00240944 5.65151 0.000140634 5.4548V2.72754C0.000140634 2.13133 0.453596 1.63664 1.00011 1.63664H7.49993V0.545741C7.49993 0.325379 7.62152 0.126383 7.80852 0.0418383ZM8.99989 6.5457C8.99611 6.24486 9.21631 5.99749 9.49206 5.99279C9.6272 5.99049 9.75744 6.04797 9.85311 6.15213C9.94877 6.25629 10.0017 6.39826 9.99986 6.5457V9.27295C9.99986 9.86916 9.5464 10.3639 8.99989 10.3639H2.50007V11.4548C2.50007 11.6757 2.37848 11.8741 2.19149 11.9587C2.00399 12.0427 1.78957 11.9964 1.64658 11.8404L0.146621 10.2041C-0.0488736 9.99133 -0.0488736 9.64602 0.146621 9.43275L1.64658 7.7964C1.74208 7.69222 1.87009 7.6366 2.00008 7.6366C2.06458 7.6366 2.12949 7.65033 2.19149 7.67815C2.37848 7.76269 2.50007 7.96169 2.50007 8.18205V9.27295H8.99989V6.5457Z'
        fill={color ? color : 'black'}
      />
    </svg>
  );
}
export function Comment({ color }) {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1.2C0 0.544162 0.544162 0 1.2 0H10.793C11.4481 0 11.993 0.54299 11.993 1.19883L12 12L9.6 9.6H1.2C0.544162 9.6 0 9.05584 0 8.4V1.2ZM10.793 1.2H1.2V8.4H10.0969L10.7977 9.10078L10.793 1.2ZM2.99883 3V4.2H8.99883V3H2.99883ZM2.99883 6.6V5.4H7.19883V6.6H2.99883Z'
        fill={color ? color : 'black'}
      />
    </svg>
  );
}

export function Lock({ color }) {
  return (
    <svg
      width='9'
      height='12'
      viewBox='0 0 9 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.125 3.42857C1.125 1.52925 2.63036 0 4.5 0C6.36964 0 7.875 1.52925 7.875 3.42857V4C8.48985 4 9 4.51825 9 5.14286V10.8571C9 11.4817 8.48985 12 7.875 12H1.125C0.510152 12 0 11.4817 0 10.8571V5.14286C0 4.51825 0.510152 4 1.125 4V3.42857ZM6.75 3.42857C6.75 2.12789 5.78036 1.14286 4.5 1.14286C3.21964 1.14286 2.25 2.12789 2.25 3.42857V4H6.75V3.42857ZM1.125 5.14286H7.875V10.8571H1.125V5.14286ZM3.375 8C3.375 7.37143 3.88125 6.85714 4.5 6.85714C5.11875 6.85714 5.625 7.37143 5.625 8C5.625 8.62857 5.11875 9.14286 4.5 9.14286C3.88125 9.14286 3.375 8.62857 3.375 8Z'
        fill={color ? color : 'black'}
      />
    </svg>
  );
}

export function Checkmark({ color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='10'
      viewBox='0 0 13 10'
      fill='none'
    >
      <path
        d='M11.5286 0.195312L4 7.72396L1.13802 4.86198L0.195312 5.80469L4 9.60938L12.4714 1.13802L11.5286 0.195312Z'
        fill={color ? color : 'black'}
      />
    </svg>
  );
}

export function Triangle({ color }) {
  return (
    <svg
      width='10'
      height='7'
      viewBox='0 0 10 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 7L10 0H0L5 7Z'
        fill={color ? color : '#000000'}
      />
    </svg>
  );
}

export function Calendar({ color }) {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.3'>
        <rect
          x='0.849609'
          y='2.5498'
          width='13.6'
          height='3.4'
          fill='#BDBDBD'
        />
      </g>
      <rect x='0.900391' y='5.6001' width='14' height='10' fill='#BDBDBD' />
      <rect x='2.5498' width='1.7' height='2.55' fill='#434343' />
      <rect x='11.0498' width='1.7' height='2.55' fill='#434343' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.7 17.0002H13.6C14.535 17.0002 15.3 16.2352 15.3 15.3002V3.4002C15.3 2.4652 14.535 1.7002 13.6 1.7002H1.7C0.765 1.7002 0 2.4652 0 3.4002V15.3002C0 16.2352 0.765 17.0002 1.7 17.0002ZM1.7 15.3002V3.4002H13.6V15.3002H1.7Z'
        fill='#434343'
      />
      <rect x='0.849609' y='5.1001' width='13.6' height='1.7' fill='#434343' />
    </svg>
  );
}

export function Department({ color }) {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.888672'
        y='5.3335'
        width='2.66667'
        height='7.11111'
        fill='#BDBDBD'
      />
      <rect
        x='14.2227'
        y='5.3335'
        width='2.66667'
        height='7.11111'
        fill='#BDBDBD'
      />
      <rect
        opacity='0.3'
        x='7.11133'
        y='5.3335'
        width='3.55556'
        height='3.55556'
        fill='black'
      />
      <path
        d='M8.88911 3.55556C9.87095 3.55556 10.6669 2.75962 10.6669 1.77778C10.6669 0.795938 9.87095 0 8.88911 0C7.90727 0 7.11133 0.795938 7.11133 1.77778C7.11133 2.75962 7.90727 3.55556 8.88911 3.55556Z'
        fill='#434343'
      />
      <path
        d='M15.1108 3.55556C16.0926 3.55556 16.8886 2.75962 16.8886 1.77778C16.8886 0.795938 16.0926 0 15.1108 0C14.1289 0 13.333 0.795938 13.333 1.77778C13.333 2.75962 14.1289 3.55556 15.1108 3.55556Z'
        fill='#434343'
      />
      <rect
        x='11.5557'
        y='12.4443'
        width='1.77778'
        height='3.55556'
        fill='#434343'
      />
      <path
        d='M2.66645 3.55556C3.64829 3.55556 4.44423 2.75962 4.44423 1.77778C4.44423 0.795938 3.64829 0 2.66645 0C1.68461 0 0.888672 0.795938 0.888672 1.77778C0.888672 2.75962 1.68461 3.55556 2.66645 3.55556Z'
        fill='#434343'
      />
      <rect
        x='4.44434'
        y='12.4443'
        width='1.77778'
        height='3.55556'
        fill='#434343'
      />
      <rect x='3.55566' y='8' width='10.6667' height='1.77778' fill='#434343' />
      <path
        d='M3.55556 11.5554H1.77778V9.77767V6.22211H3.55556V9.77767H5.33333V6.22211C5.33333 5.23989 4.53778 4.44434 3.55556 4.44434H1.77778C0.795556 4.44434 0 5.23989 0 6.22211V11.5554C0 12.5377 0.795556 13.3332 1.77778 13.3332H6.22222V11.5554H3.55556Z'
        fill='#434343'
      />
      <path
        d='M16.0001 4.44434H14.2223C13.2401 4.44434 12.4446 5.23989 12.4446 6.22211V9.77767H14.2223V6.22211H16.0001V9.77767V11.5554H13.3334H11.5557V13.3332H16.0001C16.9823 13.3332 17.7779 12.5377 17.7779 11.5554V6.22211C17.7779 5.23989 16.9823 4.44434 16.0001 4.44434Z'
        fill='#434343'
      />
      <path
        d='M8.00043 8.88878V6.22211H9.77821V8.88878H11.556V6.22211C11.556 5.23989 10.7604 4.44434 9.77821 4.44434H8.00043C7.01821 4.44434 6.22266 5.23989 6.22266 6.22211V8.88878H8.00043Z'
        fill='#434343'
      />
    </svg>
  );
}

export function Priority({ color }) {
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.133 14.222L10.3108 1.15534C10.0442 0.799783 9.51082 0.799783 9.24416 1.15534L1.42193 14.222C1.15527 14.6664 1.51082 15.1109 1.95527 15.1109H17.5997C18.0442 15.1109 18.3997 14.5776 18.133 14.222Z'
        fill='#BDBDBD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.621875 15.2C0.888542 15.7333 1.42187 16 1.95521 16H17.5997C18.133 16 18.5774 15.6444 18.8441 15.2C19.1108 14.7556 19.1108 14.1333 18.8441 13.6889L11.0219 0.711111C10.7552 0.266667 10.3108 0 9.77743 0C9.2441 0 8.79965 0.266667 8.4441 0.711111L0.621875 13.6889C0.355208 14.1333 0.355208 14.6667 0.621875 15.2ZM17.1552 14.2222H2.48854L9.77743 2.04444L17.1552 14.2222Z'
        fill='#434343'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.88867 9.77794H10.6664V5.3335H8.88867V9.77794ZM8.88867 13.3335H10.6664V11.5557H8.88867V13.3335Z'
        fill='#434343'
      />
    </svg>
  );
}

export function Assigned({ color }) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.99967 6.22201C9.47243 6.22201 10.6663 5.0281 10.6663 3.55534C10.6663 2.08258 9.47243 0.888672 7.99967 0.888672C6.52692 0.888672 5.33301 2.08258 5.33301 3.55534C5.33301 5.0281 6.52692 6.22201 7.99967 6.22201Z'
        fill='#BDBDBD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.44434 3.55556C4.44434 1.592 6.03634 0 7.99989 0C9.96345 0 11.5554 1.592 11.5554 3.55556C11.5554 5.51911 9.96345 7.11111 7.99989 7.11111C6.03634 7.11111 4.44434 5.51911 4.44434 3.55556ZM6.22211 3.55556C6.22211 4.536 7.01945 5.33333 7.99989 5.33333C8.98034 5.33333 9.77767 4.536 9.77767 3.55556C9.77767 2.57511 8.98034 1.77778 7.99989 1.77778C7.01945 1.77778 6.22211 2.57511 6.22211 3.55556Z'
        fill='#434343'
      />
      <path
        d='M15.1109 15.1112H0.888672V15.1112C0.888672 13.1477 2.48067 11.5557 4.44423 11.5557H11.5553C13.5189 11.5557 15.1109 13.1477 15.1109 15.1112V15.1112Z'
        fill='#BDBDBD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 11.5558C9.16267 11.5558 10.1858 10.9896 10.8347 10.1265C13.3387 10.6811 16 11.898 16 13.778V16.0002H0V13.778C0 11.898 2.66133 10.6811 5.16533 10.1265C5.81422 10.9896 6.83733 11.5558 8 11.5558ZM1.77778 13.778V14.2225H14.2222V13.778C14.2222 13.4998 13.3938 12.6998 11.3929 12.1051C10.4471 12.8962 9.26044 13.3336 8 13.3336C6.73956 13.3336 5.55289 12.8962 4.60711 12.1051C2.60622 12.6998 1.77778 13.4998 1.77778 13.778Z'
        fill='#434343'
      />
    </svg>
  );
}

export function Clock({ color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='17'
      height='17'
      x='0'
      y='0'
      viewBox='0 0 280.124 280.124'
    >
      <g>
        <g xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M159.391,19.322C159.391,8.646,150.737,0,140.069,0c-10.676,0-19.322,8.646-19.322,19.322   c0,7.193,3.982,13.389,9.81,16.723V57.94h19.007V36.045C155.41,32.711,159.391,26.515,159.391,19.322z M113.003,176.61   l-86.108,86.117c-3.973,3.973-3.973,10.396,0,14.36c3.964,3.947,10.387,3.947,14.351,0l86.126-86.126   c3.955-3.964,3.955-10.396,0-14.351C123.408,172.637,116.976,172.637,113.003,176.61z M253.244,262.727l-86.108-86.117   c-3.982-3.973-10.405-3.973-14.378,0c-3.955,3.955-3.955,10.387,0,14.351l86.135,86.126c3.955,3.947,10.387,3.947,14.351,0   C257.235,273.114,257.235,266.7,253.244,262.727z'
            fill='#434343'
            data-original='#70838d'
            class=''
          />
          <path
            d='M140.069,48.165c63.855,0,115.914,53.319,115.914,116.763c0,63.452-52.041,115.196-115.914,115.196   c-63.855,0-115.914-52.374-115.914-115.826C24.156,100.854,76.214,48.165,140.069,48.165z'
            fill='#434343'
            data-original='#3e4e5c'
            class=''
          />
          <path
            d='M248.729,45.399l-28.362-22.192c-7.832-6.126-19.147-4.743-25.281,3.089l-15.078,19.261   c23.365,8.033,43.632,22.822,58.604,41.978l13.196-16.854C257.952,62.84,256.561,51.525,248.729,45.399z M59.413,22.927   L30.85,45.277c-7.885,6.169-9.285,17.563-3.115,25.456l13.31,17.012c15.008-19.322,35.441-34.216,59.042-42.249L84.869,26.043   C78.7,18.149,67.297,16.758,59.413,22.927z'
            fill='#434343'
            data-original='#70838d'
            class=''
          />
          <path
            d='M140.069,75.555c48.777,0,88.541,40.726,88.541,89.189c0,48.471-39.764,87.99-88.541,87.99   s-88.541-39.991-88.541-88.462S91.292,75.555,140.069,75.555z'
            fill='#bebebe'
            data-original='#ebebeb'
            class=''
          />
          <path
            d='M188.383,154.575h-38.661v-48.313c0-5.347-4.314-9.661-9.661-9.661   c-5.329,0-9.661,4.314-9.661,9.661v57.948c0,5.312,4.323,9.661,9.661,9.661h48.313c5.312,0,9.643-4.323,9.643-9.661   S193.703,154.575,188.383,154.575z'
            fill='#434343'
            data-original='#e2574c'
            class=''
          />
        </g>
        <g xmlns='http://www.w3.org/2000/svg'></g>
        <g xmlns='http://www.w3.org/2000/svg'></g>
      </g>
    </svg>
  );
}

export function Note({ color }) {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.849609'
        y='3.3999'
        width='13.6'
        height='12.75'
        fill='#BDBDBD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.03 1.7H13.6C14.535 1.7 15.3 2.465 15.3 3.4V15.3C15.3 16.235 14.535 17 13.6 17H1.7C0.765 17 0 16.235 0 15.3V3.4C0 2.465 0.765 1.7 1.7 1.7H5.27C5.61 0.68 6.545 0 7.65 0C8.755 0 9.69 0.68 10.03 1.7ZM1.7 3.4V15.3H13.6V3.4H7.65C8.16 3.4 8.5 3.06 8.5 2.55C8.5 2.04 8.16 1.7 7.65 1.7C7.14 1.7 6.8 2.04 6.8 2.55C6.8 3.06 7.14 3.4 7.65 3.4H1.7Z'
        fill='#434343'
      />
      <path
        d='M6.80027 12.2401L3.65527 9.09508L4.84527 7.90508L6.80027 9.86008L10.4553 6.20508L11.6453 7.39508L6.80027 12.2401Z'
        fill='#434343'
      />
    </svg>
  );
}

export function XCircle({ color }) {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 0C2.6934 0 0 2.6934 0 6C0 9.3066 2.6934 12 6 12C9.3066 12 12 9.3066 12 6C12 2.6934 9.3066 0 6 0ZM6 1.2C8.65807 1.2 10.8 3.34193 10.8 6C10.8 8.65807 8.65807 10.8 6 10.8C3.34193 10.8 1.2 8.65807 1.2 6C1.2 3.34193 3.34193 1.2 6 1.2ZM3.17578 4.02422L4.02422 3.17578L6 5.15156L7.97578 3.17578L8.82422 4.02422L6.84844 6L8.82422 7.97578L7.97578 8.82422L6 6.84844L4.02422 8.82422L3.17578 7.97578L5.15156 6L3.17578 4.02422Z'
        fill={color ? color : '#DE6552'}
      />
    </svg>
  );
}

export function PlusCircle({ color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='17'
      height='17'
      x='0'
      y='0'
      viewBox='0 0 512 512'
    >
      <g transform='matrix(0.9000000000000001,0,0,0.9,25.599999999999966,25.599999999999994)'>
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0'
          fill='#c1c1c1'
          data-original='#2196f3'
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0'
          fill='#000000'
          data-original='#000000'
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0'
          fill='#000000'
          data-original='#000000'
        />
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0'
          fill='#000000'
          data-original='#000000'
        />
      </g>
    </svg>
  );
}

export function More({ color }) {
  return (
    <svg
      width='4'
      height='13'
      viewBox='0 0 4 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.5 2C3.5 1.17157 2.82843 0.5 2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5C2.82843 3.5 3.5 2.82843 3.5 2ZM3.5 6.5C3.5 5.67157 2.82843 5 2 5C1.17157 5 0.5 5.67157 0.5 6.5C0.5 7.32843 1.17157 8 2 8C2.82843 8 3.5 7.32843 3.5 6.5ZM2 9.5C2.82843 9.5 3.5 10.1716 3.5 11C3.5 11.8284 2.82843 12.5 2 12.5C1.17157 12.5 0.5 11.8284 0.5 11C0.5 10.1716 1.17157 9.5 2 9.5Z'
        fill={color ? color : '#797B88'}
      />
    </svg>
  );
}

export function Like({ color }) {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.54482 0.502276C5.60829 0.221382 5.84566 0.0270822 6.11225 0.00268744C6.20112 -0.00544414 6.29348 0.0045427 6.38364 0.0372269C6.92364 0.231753 7.57895 0.677619 7.57895 1.69636C7.57895 2.81172 6.94737 4.30902 6.94737 4.30902H11.3684C12.0632 4.30902 12.6316 4.87744 12.6316 5.57218V6.16675C12.6316 6.4257 12.5767 6.68177 12.4712 6.91798L10.5752 11.1848C10.3851 11.6124 9.96142 11.888 9.49342 11.888H4.42105C3.72316 11.888 3.15789 11.3227 3.15789 10.6248V4.79381C3.15789 4.48181 3.27327 4.18061 3.48232 3.94882L5.19449 2.04668L5.54482 0.502276ZM0 5.25639C0 4.73344 0.424421 4.30902 0.947368 4.30902C1.47032 4.30902 1.89474 4.73344 1.89474 5.25639V10.9406C1.89474 11.4635 1.47032 11.888 0.947368 11.888C0.424421 11.888 0 11.4635 0 10.9406V5.25639Z'
        fill={color ? color : '#797B88'}
      />
    </svg>
  );
}

export function Tick({ color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      x='0'
      y='0'
      viewBox='0 0 24 24'
    >
      <g>
        <g xmlns='http://www.w3.org/2000/svg'>
          <path
            d='m14.371 16.871c-.566.567-1.32.879-2.121.879s-1.555-.312-2.121-.879l-4.25-4.25c-.567-.566-.879-1.32-.879-2.121s.312-1.555.879-2.121c.566-.567 1.32-.879 2.121-.879s1.555.312 2.121.879l2.129 2.128 5.877-5.877c-1.92-1.637-4.406-2.63-7.127-2.63-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11c0-1.137-.173-2.233-.493-3.265z'
            fill={color}
            data-original='#000000'
          />
          <path
            d='m12.25 15.75c-.256 0-.512-.098-.707-.293l-4.25-4.25c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l3.543 3.543 10.043-10.043c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-10.75 10.75c-.195.195-.451.293-.707.293z'
            fill={color}
            data-original='#000000'
          />
        </g>
      </g>
    </svg>
  );
}
