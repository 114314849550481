import React, { useState } from 'react';
import '../../../styles/managerView/Employee.css';
import AddNewDepartment from './AddNewDepartment';
import DepartmentDropdown from './DepartmentDropdown';
import LevelDropdown from './LevelDropdown';

const AddEmploye = ({
  setAddNewForm,
  setFormOpen,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const [deptOptions, setDeptOptions] = useState([
    'Front Desk',
    'Housekeeping',
    'Maintenance',
    'Sales'
  ]);
  const [openAddNewDept, setOpenAddNewDept] = useState(false);
  const [firstForm, setFirstForm] = useState({
    firstName: '',
    lastName: '',
    phone: ''
  });
  const [secondForm, setSecondForm] = useState({
    firstName: '',
    lastName: '',
    phone: ''
  });
  const [thirdForm, setThirdForm] = useState({
    firstName: '',
    lastName: '',
    phone: ''
  });
  const [deptFirst, setDeptFirst] = useState(null);
  const [deptSecond, setDeptSecond] = useState(null);
  const [deptThird, setDeptThird] = useState(null);
  const [levelFirst, setLevelFirst] = useState(null);
  const [levelSecond, setLevelSecond] = useState(null);
  const [levelThird, setLevelThird] = useState(null);

  //Handle Forms
  const handleFormSubmit_1 = (e) => {
    e.preventDefault();
  };
  const handleFormSubmit_2 = (e) => {
    e.preventDefault();
  };
  const handleFormSubmit_3 = (e) => {
    e.preventDefault();
  };

  //Handle Dropdown Select
  const departmentSelectFirst = (e) => {
    setDeptFirst(e);
  };
  const departmentSelectSecond = (e) => {
    setDeptSecond(e);
  };
  const departmentSelectThird = (e) => {
    setDeptThird(e);
  };
  const levelSelectFirst = (e) => {
    setLevelFirst(e);
  };
  const levelSelectSecond = (e) => {
    setLevelSecond(e);
  };
  const levelSelectThird = (e) => {
    setLevelThird(e);
  };

  //Handle Input fields
  const handleFirstChange = (e) => {
    const { name, value } = e.target;
    setFirstForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSecondChange = (e) => {
    const { name, value } = e.target;
    setSecondForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleThirdChange = (e) => {
    const { name, value } = e.target;
    setThirdForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  //Handle Clear Button
  const handleAllClear = (e) => {
    setFirstForm((oldState) => ({
      ...oldState,
      firstName: '',
      lastName: '',
      phone: ''
    }));
    setSecondForm((oldState) => ({
      ...oldState,
      firstName: '',
      lastName: '',
      phone: ''
    }));
    setThirdForm((oldState) => ({
      ...oldState,
      firstName: '',
      lastName: '',
      phone: ''
    }));

    setDeptFirst(null);
    setDeptSecond(null);
    setDeptThird(null);
    setLevelFirst(null);
    setLevelSecond(null);
    setLevelThird(null);
  };

  //Handle Save&ADD Button
  const handleSaveAdd = (e) => {
    setAddNewForm(true);
    setFormOpen(true);
    setOpenPopUp(true);
    setPopUpMSG(
      'New employee added successfully. Login information sent to user’s phone number'
    );
    setPopUpType('success');
  };

  //Handle Save&Close Button
  const handleSaveClose = (e) => {
    setAddNewForm(false);
    setFormOpen(false);
    setOpenPopUp(true);
    setPopUpMSG(
      'New employee added successfully. Login information sent to user’s phone number'
    );
    setPopUpType('success');
  };

  return (
    <div>
      <div className='employee-half-header d-flex justify-content-between align-items-center'>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='btn-save-add' onClick={handleSaveAdd}>
            Save & Add More
          </button>

          <button className='btn-save-close' onClick={handleSaveClose}>
            Save & Close
          </button>
        </div>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='clear-btn' onClick={handleAllClear}>
            Clear
          </button>
          <button
            type='submit'
            className='close-normal'
            onClick={() => setFormOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
      {/*Starting Form-1 */}
      <div className='form-wrap'>
        <p
          className='my-0 form-index'
          style={{
            color:
              (firstForm.firstName !== '' ||
                firstForm.lastName !== '' ||
                firstForm.phone !== '' ||
                deptFirst !== null ||
                levelFirst !== null) &&
              '#434343'
          }}
        >
          O1
        </p>

        <form action='' onSubmit={handleFormSubmit_1}>
          <div className='d-flex justify-content-between align-items-center inputs-row top-input-row'>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: firstForm.firstName !== '' && '#BDBDBD' }}
              >
                First Name
              </label>
              <input
                type='text'
                name='firstName'
                id=''
                placeholder='Enter first name'
                value={firstForm.firstName}
                onChange={handleFirstChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='last-name'
                style={{ color: firstForm.lastName !== '' && '#BDBDBD' }}
              >
                Last Name
              </label>
              <input
                type='text'
                name='lastName'
                id=''
                placeholder='Enter last name'
                value={firstForm.lastName}
                onChange={handleFirstChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='phone'
                style={{ color: firstForm.phone !== '' && '#BDBDBD' }}
              >
                Phone
              </label>
              <input
                type='tel'
                name='phone'
                id=''
                placeholder='Enter phone number'
                value={firstForm.phone}
                onChange={handleFirstChange}
              />
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center inputs-row custom-inputs'>
            <div className='input-fileds dropDown-fields'>
              <label
                htmlFor='department'
                style={{ color: deptFirst !== null && '#BDBDBD' }}
              >
                Department
              </label>
              <DepartmentDropdown
                setOpenAddNewDept={setOpenAddNewDept}
                options={deptOptions}
                value={deptFirst}
                onChange={(e) => departmentSelectFirst(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: levelFirst !== null && '#BDBDBD' }}
              >
                User Level
              </label>
              <LevelDropdown
                value={levelFirst}
                onChange={(e) => levelSelectFirst(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds user-name-field'>
              <label htmlFor='user-name'>User Name (Auto Generated)</label>
              <input
                readonly='readonly'
                type='text'
                name=''
                id=''
                placeholder='User name will show here'
              />
            </div>
          </div>
        </form>
        <hr className='form-devider' />
      </div>
      {/*Ending Form-1 */}
      {/* ...................................................................................................................................................................................................................................... */}
      {/*Starting Form-2 */}
      <div className='form-wrap'>
        <p
          className='my-0 form-index'
          style={{
            color:
              (secondForm.firstName !== '' ||
                secondForm.lastName !== '' ||
                secondForm.phone !== '' ||
                deptSecond !== null ||
                levelSecond !== null) &&
              '#434343'
          }}
        >
          O2
        </p>

        <form action='' onSubmit={handleFormSubmit_2}>
          <div className='d-flex justify-content-between align-items-center inputs-row top-input-row'>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: secondForm.firstName !== '' && '#BDBDBD' }}
              >
                First Name
              </label>
              <input
                type='text'
                name='firstName'
                id=''
                placeholder='Enter first name'
                value={secondForm.firstName}
                onChange={handleSecondChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='last-name'
                style={{ color: secondForm.lastName !== '' && '#BDBDBD' }}
              >
                Last Name
              </label>
              <input
                type='text'
                name='lastName'
                id=''
                placeholder='Enter last name'
                value={secondForm.lastName}
                onChange={handleSecondChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='phone'
                style={{ color: secondForm.phone !== '' && '#BDBDBD' }}
              >
                Phone
              </label>
              <input
                type='tel'
                name='phone'
                id=''
                placeholder='Enter phone number'
                value={secondForm.phone}
                onChange={handleSecondChange}
              />
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center inputs-row custom-inputs'>
            <div className='input-fileds dropDown-fields'>
              <label
                htmlFor='department'
                style={{ color: deptSecond !== null && '#BDBDBD' }}
              >
                Department
              </label>
              <DepartmentDropdown
                setOpenAddNewDept={setOpenAddNewDept}
                options={deptOptions}
                value={deptSecond}
                onChange={(e) => departmentSelectSecond(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: levelSecond !== null && '#BDBDBD' }}
              >
                User Level
              </label>
              <LevelDropdown
                value={levelSecond}
                onChange={(e) => levelSelectSecond(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds user-name-field'>
              <label htmlFor='user-name'>User Name (Auto Generated)</label>
              <input
                readonly='readonly'
                type='text'
                name=''
                id=''
                placeholder='User name will show here'
              />
            </div>
          </div>
        </form>
        <hr className='form-devider' />
      </div>
      {/*Ending Form-2 */}
      {/* ...................................................................................................................................................................................................................................... */}
      {/*Starting Form-3 */}
      <div className='form-wrap'>
        <p
          className='my-0 form-index'
          style={{
            color:
              (thirdForm.firstName !== '' ||
                thirdForm.lastName !== '' ||
                thirdForm.phone !== '' ||
                deptThird !== null ||
                levelThird !== null) &&
              '#434343'
          }}
        >
          O3
        </p>

        <form action='' onSubmit={handleFormSubmit_3}>
          <div className='d-flex justify-content-between align-items-center inputs-row top-input-row'>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: thirdForm.firstName !== '' && '#BDBDBD' }}
              >
                First Name
              </label>
              <input
                type='text'
                name='firstName'
                id=''
                placeholder='Enter first name'
                value={thirdForm.firstName}
                onChange={handleThirdChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='last-name'
                style={{ color: thirdForm.lastName !== '' && '#BDBDBD' }}
              >
                Last Name
              </label>
              <input
                type='text'
                name='lastName'
                id=''
                placeholder='Enter last name'
                value={thirdForm.lastName}
                onChange={handleThirdChange}
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='phone'
                style={{ color: thirdForm.phone !== '' && '#BDBDBD' }}
              >
                Phone
              </label>
              <input
                type='tel'
                name='phone'
                id=''
                placeholder='Enter phone number'
                value={thirdForm.phone}
                onChange={handleThirdChange}
              />
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center inputs-row custom-inputs'>
            <div className='input-fileds dropDown-fields'>
              <label
                htmlFor='department'
                style={{ color: deptThird !== null && '#BDBDBD' }}
              >
                Department
              </label>
              <DepartmentDropdown
                setOpenAddNewDept={setOpenAddNewDept}
                options={deptOptions}
                value={deptThird}
                onChange={(e) => departmentSelectThird(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds'>
              <label
                htmlFor='first-name'
                style={{ color: levelThird !== null && '#BDBDBD' }}
              >
                User Level
              </label>
              <LevelDropdown
                value={levelThird}
                onChange={(e) => levelSelectThird(e)}
                placeholder='Choose an option'
              />
            </div>
            <div className='input-fileds user-name-field'>
              <label htmlFor='user-name'>User Name (Auto Generated)</label>
              <input
                readonly='readonly'
                type='text'
                name=''
                id=''
                placeholder='User name will show here'
              />
            </div>
          </div>
        </form>
        <hr className='form-devider' />
      </div>
      {/*Ending Form-3 */}
      {openAddNewDept && (
        <AddNewDepartment
          openAddNewDept={openAddNewDept}
          setOpenAddNewDept={setOpenAddNewDept}
          setDeptOptions={setDeptOptions}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </div>
  );
};

export default AddEmploye;
