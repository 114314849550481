import React, { useState } from 'react';
import CheckListTable from '../../components/dashboard/checklist/CheckListTable';
import DetailsPage from '../../components/dashboard/checklist/DetailsPage';
import '../../styles/dashboard/Checklist.css';

const Checklist = () => {
  const [checkListID, setCheckListID] = useState(0);
  return (
    <div className='main-section checklist'>
      <div className='checklist-head d-flex justify-content-between align-items-center'>
        <p className='main-title my-0'>Daily Checklist</p>
        <div className='head-progress d-flex align-items-center justify-content-end'>
          <p className='completed-checklist my-0'>1</p>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              aria-valuenow='75'
              aria-valuemin='0'
              style={{ width: '25%' }}
              aria-valuemax='100'
            ></div>
          </div>
          <p className='remaining-checklist my-0'>10</p>
        </div>
      </div>

      <div className='content-section d-flex'>
        <div className='show-check-list'>
          <p className='table-title my-0'>Things To Do</p>
          <CheckListTable setCheckListID={setCheckListID} />
        </div>
        <div className='show-check-details'>
          <DetailsPage checkListID={checkListID} />
        </div>
      </div>
    </div>
  );
};

export default Checklist;
