import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import '../../../styles/dashboard/Announcements.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '& .MuiAlert-filledSuccess ': {
      color: '#fff',
      fontWeight: 500,
      backgroundColor: 'RGB(71, 179, 156)',
      '&.MuiAlert-root': {
        width: '100%'
      },
      '&.MuiAlert-icon ': {
        display: 'none !important'
      },
      '&.MuiAlert-message ': {
        paddingTop: '22px',
        paddingBottom: '22px',
        fontSize: '16px',
        fontWeight: '400',
        marginLeft: 'auto'
      },
      '&.MuiAlert-action': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '32px',
        paddingLeft: '0'
      }
    }
  },
  snackBar: {
    '&.MuiSnackbar-root': {
      width: '100%',
      paddingRight: '16px',
      paddingLeft: '16px'
    }
  },
  alert: {
    '&.MuiPaper-root': {
      '&.MuiAlert-root': {
        width: '100%',
        padding: '0px',
        justifyContent: 'center'
      },
      '&.MuiAlert-icon ': {
        display: 'none !important'
      },
      '&.MuiAlert-message ': {
        paddingTop: '22px',
        paddingBottm: '22px'
      }
    }
  }
}));

const ConfirmPopUp = ({
  popUp,
  setPopUp,
  setFormOpen,
  setSignClose,
  setReadDetails,
  setAddNewForm
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setFormOpen(false);
    setSignClose(false);
    setReadDetails(false);
    setAddNewForm(false);
    setPopUp(false);
  };
  return (
    <div className={classes.root}>
      {' '}
      <Snackbar
        open={popUp}
        onClose={handleClose}
        autoHideDuration={6000}
        className={classes.snackBar}
      >
        <Alert
          onClose={handleClose}
          severity='success'
          closeText='Close'
          className={classes.alert}
          action={
            <Button color='inherit' size='small' onClick={handleClose}>
              Close
            </Button>
          }
        >
          Confirmation submitted successfully.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ConfirmPopUp;
