import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import '../../styles/managerView/DepartmentsMV.css';
import TableDepartments from '../../components/managerView/departments/TableDepartments';
import TableSuggestions from '../../components/managerView/departments/TableSuggestions';
import AddNewForm from '../../components/managerView/departments/AddNewForm';
import PopUp from '../../components/managerView/departments/PopUp';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '40px'
    }
  }
});

const Departments = () => {
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [addNewForm, setAddNewForm] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpMSG, setPopUpMSG] = useState('');
  const [popUpType, setPopUpType] = useState(null);

  const handleAddNewClick = (event, index) => {
    setFormOpen(true);
    setAddNewForm(true);
  };
  return (
    <div className='main-section departmentsMV'>
      {!formOpen && (
        <>
          <div className='departmentsMV-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Departments</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Add New Department
                </Button>
              </div>
            </div>
          </div>

          {/*Department Table */}
          <div className='departmentsMV-tables'>
            <hr className='main-devider-departmentsMV' />
            <div className='dual-table-MV d-flex'>
              <div className='table-departmentsMV'>
                <p className='departmentsMV-table-head departments-title my-0'>
                  All Departments{' '}
                </p>
                <TableDepartments
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              </div>
              <div className='table-suggestionsMV mb-4'>
                <p className='departmentsMV-table-head suggestions-title my-0'>
                  Suggestion
                </p>
                <p className='my-0 suggestions-subtitle'>
                  Quickly add department from the table below
                </p>
                <TableSuggestions
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Departments</p>
              <hr className='short-devider' />
              <div className='table-departmentsMV-half'>
                <p className='departmentsMV-table-head departments-title my-0'>
                  All Departments{' '}
                </p>
                <TableDepartments
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              </div>
            </div>

            <div className='forms-section'>
              {addNewForm && (
                <AddNewForm
                  setAddNewForm={setAddNewForm}
                  setFormOpen={setFormOpen}
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              )}
            </div>
          </div>
        </>
      )}

      {openPopUp && (
        <PopUp
          openPopUp={openPopUp}
          popUpMSG={popUpMSG}
          popUpType={popUpType}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </div>
  );
};

export default withRouter(Departments);
