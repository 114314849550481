var otherFakeData = [
  {
    id: 0,
    title: 'Work Order One',
    location: 9999,
    createdBY: 'Micheals',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Work Order Two',
    location: 9999,
    createdBY: 'Micheals',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Work Order Three',
    location: 9999,
    createdBY: 'Micheals',
    createdON: 'Dec 12 @ 09:00am',
    dueON: 'Dec 12 @ 10:00am',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default otherFakeData;
