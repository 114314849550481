import React from 'react';
import '../../../styles/maintenance/MaintenanceRequests.css';
import tableData from './otherFakeData';

const OverdueRequestsShort = () => {
  return (
    <table className='table-maintenance-requests short-tables'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.title}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default OverdueRequestsShort;
