import React from 'react';
import '../../../styles/managerView/ChecklistsMV.css';
import tableData from './fakeChecklist';
import { makeStyles, MenuItem } from '@material-ui/core';
import DotMenu from '../../common/DotMenu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  }
});

const TableFrontDesk = ({
  setReadDetails,
  setDetailsID,
  setFormOpen,
  setOpenEditDrawer,
  setEditID,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const classes = useStyles();
  const handleRead = (id) => {
    setFormOpen(true);
    setReadDetails(true);
    setDetailsID(id);
  };
  const handleEdit = (id) => {
    setEditID(id);
    setOpenEditDrawer(true);
  };
  const handleDelete = () => {
    setFormOpen(false);
    setReadDetails(false);
    setOpenPopUp(true);
    setPopUpMSG('Checklist item deleted successfully');
    setPopUpType('danger');
  };
  return (
    <table className='table-checklistMV'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Daily Checklist Tasks</th>
          <th scope='col'>Shift</th>
          <th scope='col'>Work Day</th>
          <th scope='col'>Assigned To</th>
          <th scope='col' style={{ width: '160px' }}>
            Reminder
          </th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td onClick={() => handleRead(data.id)}>{data.task}</td>
                <td>{data.shift}</td>
                <td>{data.workDay}</td>
                <td>{data.assignedTo}</td>
                <td style={{ width: '160px' }}>{data.reminder}</td>
                <td style={{ width: '10px' }}>
                  {' '}
                  <DotMenu inline openLeft>
                    <MenuItem className={classes.menuItem}>Duplicate</MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => handleEdit(data.id)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      style={{ color: '#DE6552' }}
                      onClick={handleDelete}
                    >
                      Delete
                    </MenuItem>
                  </DotMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TableFrontDesk;
