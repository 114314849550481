var maintenanceData = [
  {
    id: 0,
    title: 'Need two extra towels',
    dept: 'Maintenance',
    location: 9999,
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Want 2nd floor room',
    dept: 'Front Desk',
    location: 9999,
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Want extra blanket',
    dept: 'Housekeeping',
    location: 9999,
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default maintenanceData;
