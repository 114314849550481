import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '& .MuiAlert-filledSuccess ': {
      color: '#fff',
      fontWeight: 500,
      backgroundColor: '#DE6552',
      '&.MuiAlert-root': {
        width: '100%'
      },
      '&.MuiAlert-icon ': {
        display: 'none !important'
      },
      '&.MuiAlert-message ': {
        paddingTop: '22px',
        paddingBottom: '22px',
        fontSize: '16px',
        fontWeight: '400',
        marginLeft: 'auto'
      },
      '&.MuiAlert-action': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '32px',
        paddingLeft: '0'
      }
    }
  },
  snackBar: {
    '&.MuiSnackbar-root': {
      width: '100%',
      paddingRight: '16px',
      paddingLeft: '16px'
    }
  },
  alert: {
    '&.MuiPaper-root': {
      '&.MuiAlert-root': {
        width: '100%',
        padding: '0px',
        justifyContent: 'center'
      },
      '&.MuiAlert-icon ': {
        display: 'none !important'
      },
      '&.MuiAlert-message ': {
        paddingTop: '22px',
        paddingBottm: '22px'
      }
    }
  }
}));

const DeletePopUp = ({ deletePopUp, setDeletePopUp }) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setDeletePopUp(false);
  };
  return (
    <div className={classes.root}>
      {' '}
      <Snackbar
        open={deletePopUp}
        onClose={handleClose}
        autoHideDuration={6000}
        className={classes.snackBar}
      >
        <Alert
          onClose={handleClose}
          severity='success'
          closeText='Close'
          className={classes.alert}
          action={
            <Button color='inherit' size='small' onClick={handleClose}>
              Close
            </Button>
          }
        >
          Item Deleted Successfully |{' '}
          <Button color='inherit' size='medium' onClick={handleClose}>
            UNDO
          </Button>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DeletePopUp;
