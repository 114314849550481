import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import '../../styles/housekeeping/HKoverview.css';
import Statistics from '../../components/housekeeping/overview/Statistics';
import TableOverview from '../../components/housekeeping/overview/TableOverview';
import AntSwitch from '../../components/common/AntSwitch';
import FilterCheckBox from '../../components/common/FilterCheckBox';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 48px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      marginTop: '15px',
      marginBottom: '15px',
      height: '50px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});

const Overview = () => {
  const RoomStatusOptions = [
    { name: 'Cleaning', value: 'cleaning' },
    { name: 'Making', value: 'making' },
    { name: 'Dirty', value: 'dirty' },
    { name: 'Ready To Rent', value: 'ready-to-rent' }
  ];
  const HousekeeperOptions = [
    { name: 'Emilie F.', value: 'Emilie F.' },
    { name: 'Laura M.', value: 'Laura M.' },
    { name: 'Susan C.', value: 'Susan C.' },
    { name: 'Eunice P.', value: 'Eunice P.' },
    { name: 'Nina I.', value: 'Nina I.' },
    { name: 'Mena A.', value: 'Mena A.' }
  ];
  const [roomStatusAllCheck, setRoomStatusAllCheck] = useState(false);
  const [roomStatusFilters, setRoomStatusFilters] = useState([]);
  console.log('roomStatusFilters', roomStatusFilters);

  const [housekeeperAllCheck, seHousekeeperAllCheck] = useState(false);
  const [housekeeperFilters, setHousekeeperFilters] = useState([]);
  console.log('housekeeperFilters', housekeeperFilters);

  const [toggled, setToggled] = useState({ checked: false });
  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const classes = useStyles();
  return (
    <div className='main-section hk-overview'>
      <Statistics />

      <div className='hk-overview-head d-flex justify-content-between align-items-center'>
        <p className='main-title my-0'>All Rooms</p>

        <div className='d-flex justify-content-between align-items-center'>
          <div className='filters'>
            <FormControl className={classes.formControl}>
              <Typography component='div' style={{ marginRight: '26px' }}>
                <Grid
                  component='label'
                  container
                  alignItems='center'
                  spacing={1}
                >
                  <Grid item>
                    <span className='toggle-label'>
                      Only Ready To Rent Rooms
                    </span>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={toggled.checked}
                      onChange={handleToggleChange}
                      name='checked'
                    />
                  </Grid>
                </Grid>
              </Typography>
            </FormControl>

            <FilterCheckBox
              placeholder={'Filter Room Status'}
              options={RoomStatusOptions}
              isCheckAll={roomStatusAllCheck}
              setIsCheckAll={setRoomStatusAllCheck}
              allSelectID={1}
              checkedItems={roomStatusFilters}
              setCheckedItems={setRoomStatusFilters}
            />

            <FilterCheckBox
              placeholder={'Filter By Housekeeper'}
              options={HousekeeperOptions}
              isCheckAll={housekeeperAllCheck}
              setIsCheckAll={seHousekeeperAllCheck}
              allSelectID={2}
              checkedItems={housekeeperFilters}
              setCheckedItems={setHousekeeperFilters}
            />
          </div>
        </div>
      </div>

      {/* HouseKeeping overview Table */}
      <div className='hk-overview-table'>
        <hr className='main-devider' />
        <div className='hk-overview-table-wrap'>
          <TableOverview />
        </div>
      </div>
    </div>
  );
};

export default Overview;
