import React from 'react';
import tableData from './upcomingData';
import '../../../styles/maintenance/Replacements.css';

const ReplaceUpcomingTable = () => {
  return (
    <table className='table-replacement'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col' style={{ width: '172px' }}>
            Location
          </th>
          <th scope='col'>Last Replaced</th>
          <th scope='col'>Next Replacement</th>
          <th scope='col' className='pl-0'>
            Replacement In
          </th>
          <th scope='col'>Created By</th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.title}</td>
                <td style={{ width: '172px' }}>{data.location}</td>
                <td>{data.lastDate}</td>
                <td>{data.nextDate}</td>
                <td className='pl-0'>
                  <span
                    className='m-0'
                    style={{ color: data.replaceIN <= 7 && '#DE6552' }}
                  >
                    {data.replaceIN} Days
                  </span>
                </td>
                <td>{data.createdBY}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default ReplaceUpcomingTable;
