var upcomingData = [
  {
    id: 0,
    title: 'Item one',
    location: 9999,
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 7,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Item Two',
    location: 9999,
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 280,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Item three',
    location: 9999,
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 340,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default upcomingData;
