import React, { useState } from 'react';
import loginImage from '../../assets/images/login-illustration.png';
import '../../styles/SignUp/SignUp.css';

const LogIn = () => {
  const [isValid, setIsValid] = useState({
    userName: true,
    password: true
  });

  const [passwordCheck, setPasswordCheck] = useState({
    length: true,
    upperCase: true,
    symbol: true,
    number: true
  });

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);

    // test length
    /(?=.{8,})/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          length: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          length: false
        }));

    // test uppercase
    /(?=.*[A-Z])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          upperCase: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          upperCase: false
        }));

    // test symbol
    /(?=.*[!@#$%^&*])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          symbol: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          symbol: false
        }));

    // test number
    /(?=.*[0-9])/.test(e.target.value) === true
      ? setPasswordCheck((prevState) => ({
          ...prevState,
          number: true
        }))
      : setPasswordCheck((prevState) => ({
          ...prevState,
          number: false
        }));
  };

  const handleSignUpForm = (e) => {
    e.preventDefault();
    // check first name
    userName === ''
      ? setIsValid((prevState) => ({
          ...prevState,
          userName: false
        }))
      : setIsValid((prevState) => ({
          ...prevState,
          userName: true
        }));

    // check Password
    if (password === '') {
      setIsValid((prevState) => ({
        ...prevState,
        password: false
      }));
    } else if (
      passwordCheck.length &&
      passwordCheck.number &&
      passwordCheck.symbol &&
      passwordCheck.upperCase
    ) {
      setIsValid((prevState) => ({
        ...prevState,
        password: true
      }));
    } else {
      setIsValid((prevState) => ({
        ...prevState,
        password: false
      }));
    }
  };

  return (
    <div className='sign-up d-flex'>
      {' '}
      <div className='signUp-img-section'>
        <div className='app-text'>
          <p className='my-0 brand-title'>strēmlīnd</p>
          <p className='my-0 brand-subTitle'>
            Connect, automate, organize, and simplify your daily hotel
            operations.
          </p>
        </div>
        <img
          src={loginImage}
          alt=''
          style={{ width: '260px', height: '291px' }}
        />

        <div className='hotel-info'>
          <p className='my-0 hotel-text'>Your hotel is</p>
          <p className='my-0 hotel-name'>Best Western Woodland Hills Inn</p>
        </div>
      </div>
      <div className='signUp-form-section'>
        <p className='mb-0 signUp-form-title' style={{ marginTop: '100px' }}>
          Sign in to Stremlind
        </p>

        <div className='signUp-form'>
          <form action='submit' onSubmit={handleSignUpForm}>
            {/* Input First Name */}
            <div className='signUp-input-box'>
              <label
                htmlFor='userName'
                style={{ color: !isValid.userName && '#DE6552' }}
              >
                Email or username
              </label>
              <input
                style={{
                  borderBottom: !isValid.userName
                    ? '1px solid #DE6552'
                    : userName !== ''
                    ? '1px solid #434343'
                    : '1px solid RGBA(172, 180, 184, 0.2)'
                }}
                type='text'
                name='userName'
                id=''
                placeholder='Enter email or username'
                value={userName}
                onChange={handleUserName}
              />
              {!isValid.userName && (
                <span className='error-msg'>Username is not correct!</span>
              )}
            </div>

            {/* Input password */}
            <div className='signUp-input-box'>
              <label
                htmlFor='Password'
                style={{ color: !isValid.password && '#DE6552' }}
              >
                Password
              </label>
              <input
                style={{
                  color: !isValid.password && '#DE6552',
                  borderBottom: !isValid.password
                    ? '1px solid #DE6552'
                    : password !== ''
                    ? '1px solid #434343'
                    : '1px solid RGBA(172, 180, 184, 0.2)'
                }}
                type='password'
                name='password'
                id=''
                placeholder='Enter password'
                value={password}
                onChange={handlePassword}
              />
              {!isValid.password && (
                <span className='error-msg'>Password is not correct!</span>
              )}
            </div>

            <button type='submit' className='signUp-btn'>
              Log In
            </button>
          </form>
          <p className='my-0 forgot-text'>Forgot password?</p>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
