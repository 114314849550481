import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import '../../styles/managerView/Employee.css';
import FrontDeskTable from '../../components/managerView/employees/FrontDeskTable';
import HousekeepingTable from '../../components/managerView/employees/HousekeepingTable';
import FrontDeskShort from '../../components/managerView/employees/FrontDeskShort';
import HousekeepingShort from '../../components/managerView/employees/HousekeepingShort';
import AddEmploye from '../../components/managerView/employees/AddEmploye';
import PopUp from '../../components/managerView/employees/PopUp';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },
  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '45px',
      marginTop: '20px',
      marginBottom: '20px'
    }
  }
});

const Employee = () => {
  const classes = useStyles();
  const [showFrontDesk, setShowFrontDesk] = useState(true);
  const [showHousekeeping, setShowHousekeeping] = useState(true);
  const [showFrontDeskShort, setShowFrontDeskShort] = useState(true);
  const [showHousekeepingShort, setShowHousekeepingShort] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [addNewForm, setAddNewForm] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpMSG, setPopUpMSG] = useState('');
  const [popUpType, setPopUpType] = useState(null);
  const handleAddNewClick = (event, index) => {
    setFormOpen(true);
    setAddNewForm(true);
  };
  return (
    <div className='main-section employee'>
      {!formOpen && (
        <>
          <div className='employee-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Employees</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {/*Employee Table */}
          <div className='employee-tables'>
            <hr className='main-devider' />
            <div className='frontDesk-employees'>
              <p className='employees-table-head m-0'>
                {showFrontDesk === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(false)}
                  />
                )}
                Front Desk{' '}
              </p>
              {showFrontDesk && (
                <FrontDeskTable
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              )}
            </div>
            <div className='housekeeping-employees mb-4'>
              <p className='employees-table-head m-0'>
                {showHousekeeping === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowHousekeeping(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowHousekeeping(false)}
                  />
                )}
                Housekeeping
              </p>
              {showHousekeeping && <HousekeepingTable />}
            </div>
          </div>
        </>
      )}
      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Employees</p>
              <hr className='short-devider' />
              <div className='frontDesk-employees'>
                <p className='employees-table-head m-0'>
                  {showFrontDeskShort === true ? (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(true)}
                    />
                  )}
                  Front Desk
                </p>
                {showFrontDeskShort && (
                  <FrontDeskShort
                    setFormOpen={setFormOpen}
                    setAddNewForm={setAddNewForm}
                  />
                )}
              </div>
              <div className='housekeeping-employees'>
                <p className='employees-table-head m-0'>
                  {showHousekeepingShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowHousekeepingShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowHousekeepingShort(false)}
                    />
                  )}
                  Housekeeping
                </p>
                {showHousekeepingShort && <HousekeepingShort />}
              </div>
            </div>

            <div className='forms-section'>
              {addNewForm && (
                <AddEmploye
                  setFormOpen={setFormOpen}
                  setAddNewForm={setAddNewForm}
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              )}
            </div>
          </div>
        </>
      )}

      {openPopUp && (
        <PopUp
          openPopUp={openPopUp}
          popUpMSG={popUpMSG}
          popUpType={popUpType}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </div>
  );
};

export default Employee;
