var dataToday = [
  {
    id: 0,
    title: 'Room inspection',
    location: 9999,
    startDate: 'Dec 12, 2020',
    createdBY: 'Guest',
    dueON: 'Dec 12, 2020',
    startIN: 0,
    nextDate: 'Dec 12, 2021',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Room inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 0,
    nextDate: 'Dec 12, 2021',
    status: 'Not started',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Room inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 0,
    nextDate: 'Dec 12, 2021',
    status: 'In progress',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 3,
    title: 'Room inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 10,
    nextDate: 'Dec 12, 2021',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 4,
    title: 'Room inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 10,
    nextDate: 'Dec 12, 2021',
    status: 'In progress',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default dataToday;
