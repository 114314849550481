var fakeSuggestions = [
  {
    id: 0,
    title: 'Front Desk'
  },
  {
    id: 1,
    title: 'Maintenance'
  },
  {
    id: 2,
    title: 'Housekeeping'
  },
  {
    id: 3,
    title: 'Restaurant'
  },
  {
    id: 4,
    title: 'Sales'
  },
  {
    id: 5,
    title: 'Marketing'
  },
  {
    id: 6,
    title: 'Security'
  },
  {
    id: 7,
    title: 'Shuttle Service'
  }
];

export default fakeSuggestions;
