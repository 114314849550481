import React from 'react';
import '../../../styles/managerView/RoomTypeMV.css';
import tableData from './fakeSuggestions';
import preRooms from './fakeRoomType';

const TableSuggestions = ({ setOpenPopUp, setPopUpMSG, setPopUpType }) => {
  const handleAdd = (addID) => {
    const findOldDept = preRooms.find((data) => data.id === addID);
    if (findOldDept === undefined || findOldDept.id !== addID) {
      setOpenPopUp(true);
      setPopUpMSG('Room types added successfully');
      setPopUpType('success');
    } else {
      setOpenPopUp(true);
      setPopUpMSG('Room type already exists. Add different one');
      setPopUpType('danger');
    }
  };
  return (
    <table className='table-RoomTypes'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.title}</td>
                <td>
                  <button
                    className='addRoomType-btn'
                    onClick={() => handleAdd(id)}
                  >
                    Add
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TableSuggestions;
