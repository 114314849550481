import React, { useState } from 'react';
import '../../../styles/managerView/DepartmentsMV.css';
import EditDepartment from './EditDepartment';
import primaryDept from './preDeparments';

const TableDepartments = ({ setOpenPopUp, setPopUpMSG, setPopUpType }) => {
  const [openEditDept, setOpenEditDept] = useState(false);
  const [editID, setEditID] = useState(null);

  const handleEdit = (id) => {
    setOpenEditDept(true);
    setEditID(id);
  };

  const handleDelete = () => {
    setOpenPopUp(true);
    setPopUpMSG(
      'You can not delete this department. You already have employees under it. Please move employees to a different department to delete'
    );
    setPopUpType('danger');
  };
  return (
    <>
      <table className='table-deptMV'>
        <thead className='thead'>
          <tr>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'></th>
          </tr>
        </thead>
        {primaryDept.length > 0 ? (
          <tbody>
            {primaryDept.map((data, id) => {
              return (
                <tr key={id}>
                  <td>{data.title}</td>
                  <td>
                    <button className='edit-btn' onClick={() => handleEdit(id)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className='delete-btn' onClick={handleDelete}>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <p className='loading'>Loading Data...</p>
          </tbody>
        )}
      </table>

      {openEditDept && (
        <EditDepartment
          openEditDept={openEditDept}
          setOpenEditDept={setOpenEditDept}
          editID={editID}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </>
  );
};

export default TableDepartments;
