import React from 'react';
import '../../../styles/dashboard/Overview.css';

const GroupsEvents = () => {
  const fakeData = [
    {
      name: 'Ollie Jenkins',
      checkInDate: 'Oct 15, 2019',
      checkOutDate: 'Oct 17, 2019',
      total: 2,
      roomType: '2 kings, 5 Queens',
      event: 'Wedding',
      contact: '(818) 123 456'
    },
    {
      name: 'Ollie Jenkins',
      checkInDate: 'Oct 15, 2019',
      checkOutDate: 'Oct 17, 2019',
      total: 2,
      roomType: '2 kings, 5 Queens',
      event: 'Wedding',
      contact: '(818) 123 456'
    },
    {
      name: 'Ollie Jenkins',
      checkInDate: 'Oct 15, 2019',
      checkOutDate: 'Oct 17, 2019',
      total: 2,
      roomType: '2 kings, 5 Queens',
      event: 'Wedding',
      contact: '(818) 123 456'
    },
    {
      name: 'Ollie Jenkins',
      checkInDate: 'Oct 15, 2019',
      checkOutDate: 'Oct 17, 2019',
      total: 2,
      roomType: '2 kings, 5 Queens',
      event: 'Wedding',
      contact: '(818) 123 456'
    }
  ];
  return (
    <table className='table'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Name</th>
          <th scope='col'>Check In Date</th>
          <th scope='col'>Check Out Date</th>
          <th scope='col'>Total Nights</th>
          <th scope='col'>Type of Rooms</th>
          <th scope='col'>Event Type</th>
          <th scope='col'>Contact Number</th>
        </tr>
      </thead>
      {fakeData.length > 0 ? (
        <tbody>
          {fakeData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.checkInDate}</td>
                <td>{data.checkOutDate}</td>
                <td>{data.total}</td>
                <td>{data.roomType}</td>
                <td>{data.event}</td>
                <td>{data.contact}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default GroupsEvents;
