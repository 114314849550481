import React from 'react';
import tableData from './fakeCheckList';
import '../../../styles/dashboard/Checklist.css';

const CheckListTable = ({ setCheckListID }) => {
  const handleDataClick = (id) => {
    setCheckListID(id);
  };
  return (
    <table className='checklist-table'>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id} onClick={() => handleDataClick(id)}>
                <td>
                  {/* <p className='m-0'></p> */}
                  {id < 9 ? `0${id + 1}` : id + 1}
                </td>
                <td>{data.title}</td>
                <td>
                  <button>Done</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default CheckListTable;
