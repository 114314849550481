import React from 'react';
import '../../../styles/dashboard/Overview.css';

const RateWeOffer = () => {
  const fakeData = [
    {
      name: 'Single King',
      lowest: 100,
      standard: 120
    },
    {
      name: 'Two Queen',
      lowest: 120,
      standard: 140
    },
    {
      name: 'Suite',
      lowest: 200,
      standard: 220
    },
    {
      name: 'Single Queen',
      lowest: 100,
      standard: 120
    }
  ];
  return (
    <table className='table'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Room Type</th>
          <th scope='col'>Lowest</th>
          <th scope='col'>Standard</th>
        </tr>
      </thead>
      {fakeData.length > 0 ? (
        <tbody>
          {fakeData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>${data.lowest}</td>
                <td>${data.standard}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default RateWeOffer;
