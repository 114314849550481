var fakeSuggestions = [
  {
    id: 0,
    title: 'King Bed'
  },
  {
    id: 1,
    title: 'Two Queen Beds'
  },
  {
    id: 2,
    title: 'Handicap Room'
  },
  {
    id: 3,
    title: 'Twin Beds'
  },
  {
    id: 4,
    title: 'Suite'
  },
  {
    id: 5,
    title: 'Presidential Suite'
  },
  {
    id: 6,
    title: 'Honeymoon Suite'
  },
  {
    id: 7,
    title: 'Family Suite'
  }
];

export default fakeSuggestions;
