import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from '../../common/icons';
import useOutsideClick from '../../common/useClickOutside';
import '../../../styles/managerView/ChecklistsMV.css';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginRight: 24,
    padding: '10px 0',
    width: 'auto',
    userSelect: 'none',
    borderBottom: '1px solid #434343',
    '& label': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '2px',
      marginBottom: '0',
      cursor: 'pointer',
      fontSize: 14,
      fontFamily: 'Open sans',
      fontWeight: '500',
      color: '#434343',
      '& p': {
        maxWidth: '134px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
      '& .triangle': {
        marginLeft: 8
      }
    }
  },
  clear: {
    width: 14,
    height: 14,
    borderRadius: '100%',
    textAlign: 'center',
    fontSize: 14,
    verticalAlign: 'middle',
    lineHeight: '14px',
    border: '1px solid red',
    color: 'red',
    marginRight: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textIndent: '-1px'
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: 0,
    zIndex: 1,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all'
    },
    '& .allCheck': {
      padding: '10px 16px'
    },
    '& .singleCheck': {
      padding: '10px 16px'
    },
    '& .option-devider': {
      borderTop: '1px solid RGBA(172, 180, 184, 0.2)',
      marginTop: '0px',
      marginBottom: '0px'
    },

    '& .check-option': {
      width: 'auto',
      '& .checklistMV-checkbox': {
        webkitAppearance: 'initial',
        appearance: 'initial',
        width: '15px',
        height: '14px',
        background: 'rgb(255, 255, 255)',
        position: 'relative',
        border: '2px solid #3B3B3B'
      },
      '& .checklistMV-checkbox:checked': {
        background: 'rgb(255, 255, 255)'
      },
      '& label': {
        fontSize: '14px',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: '500',
        color: '#282828',
        paddingLeft: '8px',
        width: '100%'
      }
    },
    '& .check-option:hover': {
      backgroundColor: 'RGBA(172, 180, 184, 0.2)'
    }
  }
});

const Checkbox = ({ type, name, handleClick, isChecked, value, id }) => {
  return (
    <input
      className='checklistMV-checkbox'
      value={value}
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

const FormCheckbox = ({
  options,
  placeholder,
  setCheckedItems,
  checkedItems,
  setIsCheckAll,
  isCheckAll,
  allSelectID,
  allSelectText
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(options);
  }, [list, options]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setCheckedItems(list.map((li) => li.value));
    if (isCheckAll) {
      setCheckedItems([]);
    }
  };

  const handleClick = (e) => {
    const { checked, value } = e.target;
    setCheckedItems([...checkedItems, value]);
    if (!checked) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  if (list.length === checkedItems.length) {
    setIsCheckAll(true);
  } else {
    setIsCheckAll(false);
  }

  const SingleCheck = list.map(({ name, value }) => {
    return (
      <div className='check-option singleCheck d-flex align-items-center'>
        <Checkbox
          key={value}
          type='checkbox'
          id={value}
          name={name}
          value={value}
          handleClick={handleClick}
          isChecked={checkedItems.includes(value)}
        />
        <label htmlFor={value} className='my-0'>
          {name}
        </label>
      </div>
    );
  });

  const classes = useStyles();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <div
      className={classes.root}
      ref={ref}
      style={{
        borderBottom:
          checkedItems.length <= 0 && '1px solid RGBA(172, 180, 184, 0.2)'
      }}
    >
      <label onClick={() => toggleOpen()}>
        <p
          className='my-0'
          style={{
            color: checkedItems.length <= 0 && '#bdbdbd'
          }}
        >
          {checkedItems.length > 0 ? checkedItems.join(', ') : placeholder}
        </p>
        <span className='triangle'>
          <Svg.Triangle color={'#434343'} />
        </span>
      </label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <div className='check-option allCheck d-flex align-items-center'>
          <Checkbox
            type='checkbox'
            name='selectAll'
            id={allSelectID}
            value={'selectAll'}
            handleClick={handleSelectAll}
            isChecked={isCheckAll}
          />
          <label htmlFor={allSelectID} className='my-0'>
            {allSelectText}
          </label>
        </div>
        <hr className='option-devider' />
        {SingleCheck}
      </div>
    </div>
  );
};

export default FormCheckbox;
