import React from 'react';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import * as Svg from '../../../components/common/icons';
import '../../../styles/dashboard/Announcements.css';
import fakeData from './fakeTasks';
import AntSwitch from '../../common/AntSwitch';
import Comment from './Comment';

const DetailsPage = ({
  setFormOpen,
  detailsID,
  setReadDetails,
  setAddNewForm
}) => {
  // const [toggled, setToggled] = useState({ checked: false });
  const singleData = fakeData.find((data) => data.id === detailsID);
  const handleMarkComplete = () => {
    setFormOpen(false);
    setReadDetails(false);
    setAddNewForm(false);
  };
  return (
    <div>
      <div className='task-half-header d-flex justify-content-between align-items-center'>
        <Button
          variant='contained'
          color='primary'
          className='primary-button'
          onClick={handleMarkComplete}
        >
          Mark As Complete
        </Button>

        <button className='close-normal' onClick={() => setFormOpen(false)}>
          Close
        </button>
      </div>
      <div className='details-info'>
        <p className='details-Title'>{singleData.title}</p>

        <hr className='form-devider' />
        <div className='details-filters'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='due-date-info'>
              <Svg.Calendar />
              <p className='detailsDate m-0 pl-2'>{singleData.dueDate}</p>
            </div>
            <div className='department-info'>
              <Svg.Department />
              <p className='detailsDepartment m-0 pl-2'>{singleData.dept}</p>
            </div>
            <div className='priority-info'>
              <Svg.Priority />
              <p className='detailsPriority m-0 pl-2'>Priority</p>
            </div>
            <div className='assigned-info'>
              <Svg.Assigned />
              <p className='detailsAssigned m-0 pl-2'>Assigned To</p>
            </div>
          </div>
          <div className='private-mark'>
            <Typography component='div'>
              <Grid
                component='label'
                container
                alignItems='center'
                spacing={(1, 2)}
              >
                <Grid item>
                  <span className='form-toggle-label'>Mark as private</span>
                </Grid>
                <Grid item>
                  <AntSwitch checked={true} name='checked' />
                </Grid>
              </Grid>
            </Typography>
          </div>
        </div>

        <hr className='form-devider' />
        <p className='detailsText my-0'>{singleData.desc}</p>
        <hr className='form-devider' />
        <Comment />
      </div>
    </div>
  );
};

export default DetailsPage;
