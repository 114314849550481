var todayData = [
  {
    id: 0,
    title: 'Change battery',
    location: 'Room 200 - 300',
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 0,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'AC Unit',
    location: '9999',
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 0,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Patio light bulbs',
    location: 'Patio',
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 0,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 3,
    title: 'Parking lot lights',
    location: 'Parking lot',
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 0,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 4,
    title: 'Pool light',
    location: 'Pool',
    lastDate: 'Dec 12',
    nextDate: 'Dec 12',
    replaceIN: 0,
    createdBY: 'Darryl',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default todayData;
