import React from 'react';
import '../../../styles/dashboard/Overview.css';

const OnLeave = () => {
  const fakeData = [
    {
      name: 'Ollie Jenkins',
      startDate: 'Oct 15, 2019',
      endDate: 'Oct 17, 2019',
      total: 2,
      dept: 'Breakfast',
      replacement: 'None'
    },
    {
      name: 'Danny Simon',
      startDate: 'Oct 15, 2019',
      endDate: 'Oct 21, 2019',
      total: 5,
      dept: 'Front Desk',
      replacement: 'Frank Lambert'
    },
    {
      name: 'Harry Austin',
      startDate: 'Oct 15, 2019',
      endDate: 'Oct 23, 2019',
      total: 7,
      dept: 'Front Desk',
      replacement: 'Lawrence Nelson'
    },
    {
      name: 'Chris McDonald',
      startDate: 'Oct 15, 2019',
      endDate: 'Oct 20, 2019',
      total: 4,
      dept: 'Front Desk',
      replacement: 'Kevin Luna'
    }
  ];
  return (
    <table className='table'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Name</th>
          <th scope='col'>Leave Start</th>
          <th scope='col'>End date</th>
          <th scope='col'>Total days</th>
          <th scope='col'>Department</th>
          <th scope='col'>Replacement</th>
        </tr>
      </thead>
      {fakeData.length > 0 ? (
        <tbody>
          {fakeData.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.startDate}</td>
                <td>{data.endDate}</td>
                <td>{data.total}</td>
                <td>{data.dept}</td>
                <td>{data.replacement}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default OnLeave;
