import './App.css';
import SideBar from './components/navs/SideBar';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import DashBoard from './pages/Dashboard/DashBoard';
// import Schedule from './pages/Schedule/Schedule';
import Maintenance from './pages/Maintenance/Maintenance';
import HouseKeeping from './pages/Housekeeping/HouseKeeping';
// import Reports from './pages/Reports/Reports';
import Operations from './pages/Operations/Operations';
import ManagerView from './pages/ManagerView/ManagerView';
import LogIn from './pages/SignUp/LogIn';
import CreateAccount from './pages/SignUp/CreateAccount';

function App() {
  return (
    <div className='App'>
      <Router>
        <div className='d-block'>
          <Switch>
            <Route path='/login'>
              <LogIn />
            </Route>
            <Route path='/signUp'>
              <CreateAccount />
            </Route>
          </Switch>
        </div>

        <div className='d-flex'>
          {(window.location.pathname !== '/login' ||
            window.location.pathname !== '/signUp') && <SideBar />}
          <div className='pages'>
            <Switch>
              <Redirect exact from='/' to='/dashboard/overview' />
              <Route
                exact
                path='/dashboard/:page?'
                render={(props) => <DashBoard {...props} />}
              />
              {/* <Route path='/schedule'>
              <Schedule />
            </Route> */}

              <Redirect exact from='/operations' to='/operations/cashCount' />
              <Route
                exact
                path='/operations/:page?'
                render={(props) => <Operations {...props} />}
              />

              <Redirect
                exact
                from='/maintenance'
                to='/maintenance/maintenanceRequests'
              />
              <Route
                exact
                path='/maintenance/:page?'
                render={(props) => <Maintenance {...props} />}
              />

              <Redirect
                exact
                from='/housekeeping'
                to='/housekeeping/overview'
              />
              <Route
                exact
                path='/housekeeping/:page?'
                render={(props) => <HouseKeeping {...props} />}
              />

              {/* <Route path='/reports'>
              <Reports />
            </Route> */}

              <Redirect exact from='/manager' to='/manager/employee' />
              <Route
                exact
                path='/manager/:page?'
                render={(props) => <ManagerView {...props} />}
              />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
