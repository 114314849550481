import React from 'react';
import '../../../styles/dashboard/Overview.css';

const OurRateToday = () => {
  const fakeData = [
    {
      name: 'Single King',
      currentPrice: 100,
      lastPrice: 120
    },
    {
      name: 'Two Queen',
      currentPrice: 120,
      lastPrice: 140
    },
    {
      name: 'Suite',
      currentPrice: 200,
      lastPrice: 220
    },
    {
      name: 'Single Queen',
      currentPrice: 100,
      lastPrice: 100
    }
  ];

  return (
    <table className='table'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Room Type</th>
          <th scope='col'>Current Year</th>
          <th scope='col'>Last Year</th>
          <th scope='col'>difference</th>
        </tr>
      </thead>
      {fakeData.length > 0 ? (
        <tbody>
          {fakeData.map((data, index) => {
            const priceDiff = data.currentPrice - data.lastPrice;
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>${data.currentPrice}</td>
                <td>${data.lastPrice}</td>
                <td>${priceDiff}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default OurRateToday;
