import React from 'react';
import * as Svg from '../../../components/common/icons';
import '../../../styles/dashboard/Announcements.css';
import fakeData from './frontDeskData';

const DetailsPage = ({
  setFormOpen,
  detailsID,
  setReadDetails,
  setAddNewForm
}) => {
  const singleData = fakeData.find((data) => data.id === detailsID);
  const handleMarkButton = () => {
    setReadDetails(false);
    setAddNewForm(false);
    setFormOpen(false);
  };
  return (
    <div>
      <div className='request-half-header d-flex justify-content-between align-items-center'>
        <div className='btn-group' role='group' aria-label='Basic example'>
          {singleData.status === 'Open' && (
            <button
              type='button'
              className='btn-mark'
              onClick={handleMarkButton}
              style={{ background: singleData.status === 'Open' && '#47B39C' }}
              disabled={singleData.status !== 'Open' && true}
            >
              Accept
            </button>
          )}

          {singleData.status === 'Not started' && (
            <button
              type='button'
              className='btn-mark'
              onClick={handleMarkButton}
              style={{
                background: singleData.status === 'Not started' && '#DE6552'
              }}
              disabled={singleData.status !== 'Not started' && true}
            >
              Start Task
            </button>
          )}

          {singleData.status === 'In progress' && (
            <button
              type='button'
              className='btn-mark'
              onClick={handleMarkButton}
              style={{
                background: singleData.status === 'In progress' && '#FBBE53'
              }}
              disabled={singleData.status !== 'In progress' && true}
            >
              Mark As Done
            </button>
          )}
        </div>

        <button className='close-normal' onClick={() => setFormOpen(false)}>
          Close
        </button>
      </div>
      <div className='details-info'>
        <p className='details-Title'>{singleData.title}</p>

        <hr className='form-devider' />
        <div className='details-filters'>
          <div className='d-flex justify-content-between align-items-center'>
            {/* <div className='start-date-info'>
              <Svg.Calendar />
              <p className='detailsStartDate m-0 pl-2'>
                {singleData.dueDate} - {singleData.dueDate}
              </p>
            </div> */}
            <div className='due-date-info'>
              <Svg.Calendar />
              <p className='detailsDueDate m-0 pl-2'>
                {singleData.dueDate} - {singleData.dueDate}
              </p>
            </div>
            <div className='priority-info'>
              <Svg.Priority />
              <p className='detailsPriority m-0 pl-2'>Priority</p>
            </div>
            <div className='assigned-info'>
              <Svg.Assigned />
              <p className='detailsAssigned m-0 pl-2'>{singleData.dept}</p>
            </div>
          </div>
          <div className='details-status'>
            <p
              className='my-0'
              style={{
                background:
                  singleData.status === 'Open'
                    ? '#47B39C'
                    : singleData.status === 'Not started'
                    ? '#DE6552'
                    : '#FBBE53'
              }}
            >
              {singleData.status}
            </p>
          </div>
        </div>

        <hr className='form-devider' />
        <p className='detailsRoom my-0'>{singleData.location}</p>

        <hr className='form-devider' />

        <p className='detailsText my-0'>{singleData.description}</p>
        <hr className='form-devider' />
      </div>
    </div>
  );
};

export default DetailsPage;
