import React from 'react';
import '../../../styles/housekeeping/HKoverview.css';

const Statistics = () => {
  return (
    <div className='hk-overview-statistics'>
      <div className='card-deck mb-4'>
        <div
          className='card hk-overview-card'
          style={{ width: '18rem', height: '150px' }}
        >
          <div className='card-body hk-overview-cardBody'>
            <img src='/icons/guests.svg' alt='' />
            <p className='value mt-3 mb-1'>10</p>
            <p className='state-name my-0'>Ready To Rent</p>
          </div>
        </div>

        <div
          className='card hk-overview-card'
          style={{ width: '18rem', height: '150px' }}
        >
          <div className='card-body hk-overview-cardBody'>
            <img src='/icons/housekeeping.svg' alt='' />
            <p className='value mt-3 mb-1'>30</p>
            <p className='state-name my-0'>Cleaning In Progress</p>
          </div>
        </div>

        <div
          className='card hk-overview-card'
          style={{ width: '18rem', height: '150px' }}
        >
          <div className='card-body hk-overview-cardBody'>
            <img src='/icons/mop.svg' alt='' />
            <p className='value mt-3 mb-1'>10</p>
            <p className='state-name my-0'>Dirty Rooms</p>
          </div>
        </div>

        <div
          className='card hk-overview-card'
          style={{ width: '18rem', height: '150px' }}
        >
          <div className='card-body hk-overview-cardBody'>
            <img src='/icons/broom-stick.svg' alt='' />
            <p className='value mt-3 mb-1'>20</p>
            <p className='state-name my-0'>Blocked Rooms</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
