import React from 'react';
import tableData from './fakeHKData';
import '../../../styles/housekeeping/HKoverview.css';

const TableOverview = () => {
  return (
    <table className='table-hk-overview'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Room</th>
          <th scope='col'>Type</th>
          <th scope='col' style={{ width: '270px' }}>
            Status
          </th>
          <th scope='col' className='px-0' style={{ width: '120px' }}>
            Start Time
          </th>
          <th scope='col'>Clean In</th>
          <th scope='col' style={{ width: '120px' }}>
            HK
          </th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.room}</td>
                <td>{data.type}</td>
                <td className='td-status' style={{ width: '270px' }}>
                  <p
                    className='m-0'
                    style={{
                      background:
                        data.status === 'Ready To Rent'
                          ? '#47B39C'
                          : data.status === 'Cleaning'
                          ? '#FBBE53'
                          : data.status === 'Dirty'
                          ? '#DE6552'
                          : data.status === 'Making'
                          ? '#A2D5AC'
                          : '#686868'
                    }}
                  >
                    {data.status}
                  </p>
                </td>
                <td className='px-0' style={{ width: '120px' }}>
                  {data.startTime === '' ? '-' : data.startTime}
                </td>
                <td>{data.cleanIN === '' ? '-' : data.cleanIN}</td>
                <td style={{ width: '120px' }}>{data.hk}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TableOverview;
