import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterMenu from '../../components/common/FilterMenu';
import '../../styles/managerView/ChecklistsMV.css';
import TableFrontDesk from '../../components/managerView/ChecklistsMV/TableFrontDesk';
import DetailsPage from '../../components/managerView/ChecklistsMV/DetailsPage';
import CreateDrawer from '../../components/managerView/ChecklistsMV/CreateDrawer';
import TableFrontDeskShort from '../../components/managerView/ChecklistsMV/TableFrontDeskShort';
import EditDrawer from '../../components/managerView/ChecklistsMV/EditDrawer';
import PopUp from '../../components/managerView/ChecklistsMV/PopUp';

const openStyle = {
  visibility: 'visible'
};
const hideStyle = {
  visibility: 'hidden'
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '45px',
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});

const ChecklistsMV = () => {
  const classes = useStyles();
  const departmentOptions = [
    { label: 'All Departments', value: 'all-departments' },
    { label: 'Front Desk', value: 'front-desk' },
    { label: 'Housekeeping', value: 'housekeeping' },
    { label: 'Maintenance', value: 'maintenance' },
    { label: 'Sales', value: 'sales' }
  ];
  const shiftOptions = [
    { label: 'All Shifts', value: 'all-shifts' },
    { label: 'Morning Shift', value: 'morning-shift' },
    { label: 'Evening Shift', value: 'evening-shift' },
    { label: 'Night Shift', value: 'night-shift' }
  ];
  const workdayOptions = [
    { label: 'All Workdays', value: 'all-workdays' },
    { label: 'Sunday', value: 'sunday' },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' }
  ];
  const assignedOptions = [
    { label: 'Everyone', value: 'everyone' },
    { label: 'Dwight Schrute', value: 'dwight-schrute' },
    { label: 'Jim Halpert', value: 'jim-halpert' }
  ];
  const [showFrontDesk, setShowFrontDesk] = useState(true);
  const [showMaintenance, setShowMaintenance] = useState(true);
  const [showHousekeeping, setShowHousekeeping] = useState(true);

  const [showFrontDeskShort, setShowFrontDeskShort] = useState(true);
  const [showMaintenanceShort, setShowMaintenanceShort] = useState(true);
  const [showHousekeepingShort, setShowHousekeepingShort] = useState(true);

  const [openAddNewDrawer, setOpenAddNewDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editID, setEditID] = useState(null);

  const [formOpen, setFormOpen] = useState(false);
  const [readDetails, setReadDetails] = useState(false);
  const [detailsID, setDetailsID] = useState(null);

  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpMSG, setPopUpMSG] = useState('');
  const [popUpType, setPopUpType] = useState(null);

  const [filters, setFilters] = useState({
    dept: null,
    shift: null,
    workDay: null,
    assignedTo: null
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAddNewClick = (event, index) => {
    setFormOpen(false);
    setOpenAddNewDrawer(true);
    setReadDetails(false);
  };

  return (
    <div className='main-section mv-checklists'>
      {!formOpen && (
        <>
          {' '}
          <div className='checklistMV-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>All Checklist</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='filters'>
                <FilterMenu
                  value={filters.dept}
                  onChange={(e) => filterChange('dept', e)}
                  options={departmentOptions}
                  placeholder='Filter Departments'
                />
                <FilterMenu
                  value={filters.shift}
                  onChange={(e) => filterChange('shift', e)}
                  options={shiftOptions}
                  placeholder='Filter Shifts'
                />
                <FilterMenu
                  value={filters.workDay}
                  onChange={(e) => filterChange('workDay', e)}
                  options={workdayOptions}
                  placeholder='Filter Workdays'
                />
                <FilterMenu
                  value={filters.assignedTo}
                  onChange={(e) => filterChange('assignedTo', e)}
                  options={assignedOptions}
                  placeholder='Assigned To'
                />
              </div>

              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Add New Checklist
                </Button>
              </div>
            </div>
          </div>
          {/*Checklists Tables */}
          <div className='checklistMV-tables'>
            <hr className='main-devider' />
            <div className='frontDesk-MV'>
              <p className='checklistMV-table-head m-0'>
                {showFrontDesk === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(false)}
                  />
                )}
                Front Desk
              </p>
              {showFrontDesk && (
                <TableFrontDesk
                  setReadDetails={setReadDetails}
                  setDetailsID={setDetailsID}
                  setFormOpen={setFormOpen}
                  setOpenEditDrawer={setOpenEditDrawer}
                  setEditID={setEditID}
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              )}
            </div>
            <div className='maintenance-MV'>
              <p className='checklistMV-table-head m-0'>
                {showMaintenance === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowMaintenance(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowMaintenance(false)}
                  />
                )}
                Maintenance
              </p>
            </div>

            <div className='housekeeping-MV mb-4'>
              <p className='checklistMV-table-head m-0'>
                {showHousekeeping === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowHousekeeping(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowHousekeeping(false)}
                  />
                )}
                Housekeeping
              </p>
            </div>
          </div>
        </>
      )}

      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>All Checklist</p>
              <hr className='short-devider' />
              <div className='frontDesk-MV'>
                <p className='checklistMV-table-head m-0'>
                  {showFrontDeskShort === true ? (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(true)}
                    />
                  )}
                  Front Desk
                </p>
                {showFrontDeskShort && (
                  <TableFrontDeskShort
                    setReadDetails={setReadDetails}
                    setDetailsID={setDetailsID}
                    setFormOpen={setFormOpen}
                  />
                )}
              </div>
              <div className='maintenance-MV'>
                <p className='checklistMV-table-head m-0'>
                  {showMaintenanceShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowMaintenanceShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowMaintenanceShort(false)}
                    />
                  )}
                  Maintenance
                </p>
              </div>
              <div className='housekeeping-MV'>
                <p className='checklistMV-table-head m-0'>
                  {showHousekeepingShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowHousekeepingShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowHousekeepingShort(false)}
                    />
                  )}
                  Housekeeping
                </p>
              </div>
            </div>

            <div className='checklistMV-details-section'>
              {readDetails && (
                <DetailsPage
                  detailsID={detailsID}
                  setFormOpen={setFormOpen}
                  setReadDetails={setReadDetails}
                  setOpenEditDrawer={setOpenEditDrawer}
                  setEditID={setEditID}
                  setOpenPopUp={setOpenPopUp}
                  setPopUpMSG={setPopUpMSG}
                  setPopUpType={setPopUpType}
                />
              )}
            </div>
          </div>
        </>
      )}

      {/* Edit Drawer */}
      {openEditDrawer && (
        <div
          className='drawer-wraper'
          style={openEditDrawer ? openStyle : hideStyle}
        >
          <EditDrawer
            openEditDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            editID={editID}
            setOpenPopUp={setOpenPopUp}
            setPopUpMSG={setPopUpMSG}
            setPopUpType={setPopUpType}
          />
        </div>
      )}

      {/* Create Drawer */}
      <div
        className='drawer-wraper'
        style={openAddNewDrawer ? openStyle : hideStyle}
      >
        <CreateDrawer
          openAddNewDrawer={openAddNewDrawer}
          setOpenAddNewDrawer={setOpenAddNewDrawer}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      </div>

      {openPopUp && (
        <PopUp
          openPopUp={openPopUp}
          popUpMSG={popUpMSG}
          popUpType={popUpType}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </div>
  );
};

export default ChecklistsMV;
