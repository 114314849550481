import React from 'react';
import { withRouter } from 'react-router';
import Overview from './Overview';
import Announcements from './Announcements';
import Tasks from './TasksNotes';
import Checklist from './Checklist';
import Requests from './Requests';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/navs/Header.css';
import Profile from '../../components/navs/Profile';

const useStyles = makeStyles({
  menu: {
    position: 'relative',
    zIndex: 0,
    overflowY: 'visible !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-30px',
      left: '-40px',
      width: 'calc(100% + 80px)',
      height: '254px',
      zIndex: 0
    },
    '& .MuiTabs-fixed, .MuiTabs-root': {
      overflow: 'visible !important'
    },

    '& .MuiTabs-flexContainer': {
      display: 'inline-block',
      background: '#fff',
      boxShadow: '0 15px 30px -4px rgba(172,180,184,0.2)',

      '& .MuiTab-root': {
        zIndex: 2,
        textTransform: 'none',
        color: '#797B88',
        '&.Mui-selected': {
          color: '#434343'
        },

        '& .MuiTab-wrapper': {
          width: 'initial',
          fontFamily: 'Open sans !important',
          fontWeight: '600',
          padding: '0 16px',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            bottom: '-2px',
            borderBottom: '2px solid #252427'
          }
        }
      }
    },
    '& .MuiTabs-indicator': {
      bottom: 0,
      height: 3,
      background: '#252427',
      borderRadius: 0,
      zIndex: 1,
      transform: 'scaleX(0.75)'
    }
  }
});

const DashBoard = (props) => {
  const classes = useStyles();

  const { match, history } = props;
  const { params } = match;
  const { page } = params;

  const tabNameToIndex = {
    0: 'overview',
    1: 'announcements',
    2: 'tasks',
    3: 'checklist',
    4: 'requests'
  };

  const indexToTabName = {
    overview: 0,
    announcements: 1,
    tasks: 2,
    checklist: 3,
    requests: 4
  };

  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);

  const handleChange = (event, newValue) => {
    history.push(`/dashboard/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center top-navs'>
        <div className='head-navs'>
          <div className={classes.menu}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              aria-label='full width tabs example'
            >
              <Tab label='Overview' default />
              <Tab label='Announcements' />
              <Tab label='Tasks & Notes' />
              <Tab label='Daily Checklist' />
              <Tab label='Requests' />
            </Tabs>
          </div>
        </div>
        <Profile />
      </div>

      {selectedTab === 0 && <Overview />}
      {selectedTab === 1 && <Announcements />}
      {selectedTab === 2 && <Tasks />}
      {selectedTab === 3 && <Checklist />}
      {selectedTab === 4 && <Requests />}
    </div>
  );
};

export default withRouter(DashBoard);
