import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import '../../../styles/managerView/Employee.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: 'RGBA(0, 0, 0, 0.5)'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ffffff',
    borderRadius: '3px',
    boxShadow: theme.shadows[5],
    padding: 0,
    height: 'auto'
  }
}));

const AddNewDepartment = ({
  openAddNewDept,
  setOpenAddNewDept,
  setDeptOptions,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const classes = useStyles();

  const [newDept, setNewDept] = useState('');

  const handleDeptChange = (e) => {
    setNewDept(e.target.value);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    setOpenPopUp(true);
    setPopUpMSG(
      'New department added successfully. You can now choose from dropdown menu'
    );
    setPopUpType('success');
    setDeptOptions((state) => [...state, newDept]);
    setOpenAddNewDept(false);
  };
  const handleCancle = (e) => {
    e.preventDefault();
    setOpenAddNewDept(false);
    setOpenPopUp(false);
    setPopUpMSG('');
    setPopUpType(null);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenAddNewDept(false);
      setOpenPopUp(false);
      setPopUpMSG('');
      setPopUpType(null);
    }
  };
  return (
    <div className='addNew-modal'>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openAddNewDept}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openAddNewDept}>
          <div className={classes.paper}>
            <div className='addDept-form'>
              <p className='my-0 form-title'>Add New Department</p>
              <form action=''>
                <div className='department-input-box'>
                  <p className='my-0 department-input-label'>Department Name</p>
                  <input
                    type='text'
                    name=''
                    id=''
                    placeholder='Write department name here'
                    onChange={handleDeptChange}
                  />
                </div>

                <div className='addDepartment-btns'>
                  <div
                    className='btn-group'
                    role='group'
                    aria-label='Basic example'
                  >
                    <button className='deptAdd-submit' onClick={handleAdd}>
                      Add New Department
                    </button>
                    <button className='deptAdd-cancle' onClick={handleCancle}>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddNewDepartment;
