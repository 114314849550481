import React from 'react';
import '../../../styles/dashboard/Requests.css';
import tableData from './frontDeskData';

const TableFrontDeskShort = ({
  setReadDetails,
  setDetailsID,
  setFormOpen,
  setAddNewForm
}) => {
  const handleDataClick = (id) => {
    setFormOpen(true);
    setReadDetails(true);
    setDetailsID(id);
    setAddNewForm(false);
  };
  return (
    <table className='table-requests short-tables'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id} onClick={() => handleDataClick(id)}>
                <td>{data.title}</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TableFrontDeskShort;
