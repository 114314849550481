import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import * as Svg from '../../../components/common/icons';
import '../../../styles/dashboard/Announcements.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AntSwitch from '../../common/AntSwitch';
import SelectDropdown from '../../common/SelectDropdown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RepeatSelect from './RepeatSelect';
import WeekdaySelect from './WeekdaySelect';

const AddNewForm = ({ pane, setFormOpen }) => {
  const deptOptions = ['Front Desk', 'Housekeeping', 'Maintenance', 'Sales'];
  const priorityOptions = [
    'Urgent',
    'Important',
    'Normal',
    'Complaints',
    'Closed'
  ];
  const assignedOptions = ['Dwight Schrute', 'Jim Halpert', 'Auvik', 'Matthew'];
  const repeatOptions = ['Daily', 'Weekly', 'Monthly', 'Custom'];
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [showReapeat, setShowReapeat] = useState(false);
  const [repeat, setRepeat] = useState(null);

  const [dropSelect, setDropSelect] = useState({
    dept: null,
    priority: null,
    assignedTo: null
  });
  const [weekDay, setWeekDay] = useState([]);

  console.log(weekDay);

  const repeatSelect = (e) => {
    setRepeat(e);
  };

  const selectChange = (name, value) => {
    setDropSelect({ ...dropSelect, [name]: value });
  };

  const [toggled, setToggled] = useState({ checked: false });
  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const [recursion, setRecursion] = useState({ checked: false });
  const handleToggleRecursion = (event) => {
    setRecursion({ ...recursion, checked: event.target.checked });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form action='submit' onSubmit={handleFormSubmit}>
      <div className='task-half-header d-flex justify-content-between align-items-center'>
        <Button variant='contained' color='primary' className='primary-button'>
          {pane === 0 ? 'Create Task' : 'Create Note'}
        </Button>

        <button
          type='submit'
          className='close-normal'
          onClick={() => setFormOpen(false)}
        >
          Close
        </button>
      </div>
      <div className='inputs'>
        <input
          type='text'
          name=''
          id='tasks-title-field'
          placeholder={`Write a short title for your ${
            pane === 0 ? 'task' : 'note'
          }`}
        />

        <hr className='form-devider' />
        <div className='select-menus'>
          <div className='d-flex'>
            <div className='due-date d-flex align-items-center'>
              <Svg.Calendar />
              <DatePicker
                dateFormat='MMM d'
                placeholderText='Due date'
                className='custom-datePicker'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              >
                <hr className='footer-plugin-devider' />
                <div className='datepicker-footer-plugin'>
                  {pane === 0 ? (
                    <div className='task-footer'>
                      <div className='task-footer-head d-flex align-items-center'>
                        {showReapeat === false ? (
                          <ArrowRightIcon
                            className='aria-expand-btn'
                            fontSize='large'
                            onClick={() => setShowReapeat(true)}
                          />
                        ) : (
                          <ArrowDropDownIcon
                            className='aria-expand-btn'
                            fontSize='large'
                            onClick={() => setShowReapeat(false)}
                          />
                        )}
                        <p className='my-0'>Repeat this task</p>
                      </div>
                      {showReapeat && (
                        <>
                          <div className='task-repeat-box'>
                            <RepeatSelect
                              options={repeatOptions}
                              value={repeat}
                              onChange={(e) => repeatSelect(e)}
                              placeholder='Repeat Option'
                            />
                          </div>

                          {repeat === 'Daily' && (
                            <div className='days-input d-flex align-items-center'>
                              <span>For next</span>
                              <input type='number' name='' id='' />
                              <span>Days</span>
                            </div>
                          )}

                          {repeat === 'Custom' && (
                            <div className='weekDay-select'>
                              <p className='my-0 weekDay-list'>Repeat Every</p>
                              <WeekdaySelect
                                weekDay={weekDay}
                                setWeekDay={setWeekDay}
                              />
                            </div>
                          )}

                          <div className='recursion-toggle'>
                            <Typography component='div'>
                              <Grid
                                component='label'
                                container
                                alignItems='center'
                                spacing={(1, 2)}
                              >
                                <Grid item>
                                  <span className='form-toggle-label'>
                                    Keep recurring after complete
                                  </span>
                                </Grid>
                                <Grid item>
                                  <AntSwitch
                                    checked={recursion.checked}
                                    onChange={handleToggleRecursion}
                                    name='checked'
                                  />
                                </Grid>
                              </Grid>
                            </Typography>
                          </div>
                          <div className='task-footer-btns'>
                            <button className='repeat-save'>Save</button>
                            <button className='repeat-cancle'>Cancle</button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className='note-footer d-flex'>
                      <div className='start-date mr-3 align-items-center'>
                        <p className='start-date-title text-left mb-2'>
                          Start Date
                        </p>
                        <div className='start-date-box'>
                          <span className='text-left'>
                            {startDate !== null &&
                              startDate.toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div className='end-date'>
                        <p className='end-date-title text-left mb-2'>
                          End Date
                        </p>
                        <div className='end-date-box'>
                          <span className='text-left'>
                            {' '}
                            {endDate !== null && endDate.toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </DatePicker>
            </div>
            <div className='department d-flex align-items-center mr-2'>
              <Svg.Department />
              <SelectDropdown
                options={deptOptions}
                value={dropSelect.dept}
                onChange={(e) => selectChange('dept', e)}
                placeholder='Department'
              />
            </div>
            <div className='priority d-flex align-items-center'>
              <Svg.Priority />
              <SelectDropdown
                options={priorityOptions}
                value={dropSelect.priority}
                onChange={(e) => selectChange('priority', e)}
                placeholder='Priority'
              />
            </div>

            <div className='assigned d-flex align-items-center'>
              <Svg.Assigned />
              <SelectDropdown
                options={assignedOptions}
                value={dropSelect.assignedTo}
                onChange={(e) => selectChange('assignedTo', e)}
                placeholder='Assigned To'
              />
            </div>
          </div>

          <div className='private-mark'>
            <Typography component='div'>
              <Grid
                component='label'
                container
                alignItems='center'
                spacing={(1, 2)}
              >
                <Grid item>
                  <span className='form-toggle-label'>Mark as private</span>
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={toggled.checked}
                    onChange={handleToggleChange}
                    name='checked'
                  />
                </Grid>
              </Grid>
            </Typography>
          </div>
        </div>

        <hr className='form-devider' />
        <textarea
          name=''
          id='tasks-details-field'
          cols='30'
          rows='5'
          placeholder={`Your ${pane === 0 ? 'task' : 'note'} details`}
        ></textarea>
        <hr className='form-devider' />
      </div>
    </form>
  );
};

export default AddNewForm;
