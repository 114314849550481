var dataOther = [
  {
    id: 0,
    title: 'Other inspection',
    location: 9999,
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 1,
    nextDate: 'Dec 12, 2021',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Other inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 7,
    nextDate: 'Dec 12, 2021',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'Other inspection',
    location: 9999,
    createdBY: 'Guest',
    startDate: 'Dec 12, 2020',
    dueON: 'Dec 12, 2020',
    startIN: 30,
    nextDate: 'Dec 12, 2021',
    status: 'Open',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default dataOther;
