var preDepartments = [
  {
    id: 0,
    title: 'Front Desk'
  },
  {
    id: 1,
    title: 'Maintenance'
  },
  {
    id: 2,
    title: 'Housekeeping'
  },
  {
    id: 3,
    title: 'Restaurant'
  }
];

export default preDepartments;
