var fakeEmployees = [
  {
    id: 0,
    name: 'Howard Sparks',
    phone: '317-213-3565',
    level: 'Employee',
    userName: 'howards'
  },
  {
    id: 1,
    name: 'Gilbert Matthews',
    phone: '812-346-3255',
    level: 'Employee',
    userName: 'gilbertm'
  },
  {
    id: 2,
    name: 'Lloyd Austin',
    phone: '306-911-7045',
    level: 'Employee',
    userName: 'lloyda'
  },
  {
    id: 3,
    name: 'Steve Rodriguez',
    phone: '415-764-5115',
    level: 'Employee',
    userName: 'stever'
  },
  {
    id: 4,
    name: 'Joseph Flores',
    phone: '674-624-5825',
    level: 'Employee',
    userName: 'josephf'
  },
  {
    id: 5,
    name: 'Warren Townsend',
    phone: '025-329-6009',
    level: 'Employee',
    userName: 'warrent'
  },
  {
    id: 6,
    name: 'Warren Townsend',
    phone: '025-329-6009',
    level: 'Employee',
    userName: 'warrent'
  },
  {
    id: 7,
    name: 'Nina Hernandez',
    phone: '785-981-7080',
    level: 'Manager',
    userName: 'ninah'
  },
  {
    id: 8,
    name: 'Jeffrey Edwards',
    phone: '775-425-8719',
    level: 'Employee',
    userName: 'jeffreye'
  },
  {
    id: 9,
    name: 'Miguel Cole',
    phone: '594-060-1495',
    level: 'Employee',
    userName: 'miguelc'
  }
];

export default fakeEmployees;
