import React from 'react';
import '../../../styles/managerView/Employee.css';
import tableData from './fakeEmployees';

const FrontDeskTable = ({ setOpenPopUp, setPopUpMSG, setPopUpType }) => {
  const handleReset = (params) => {
    setOpenPopUp(true);
    setPopUpMSG(
      'Password reset successfully. User will receive a new text message'
    );
    setPopUpType('success');
  };
  return (
    <table className='table-employees'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Full Name</th>
          <th scope='col' className='px-0' style={{ width: '170px' }}>
            Phone
          </th>
          <th scope='col'>User Level</th>
          <th scope='col'>User Name</th>
          <th scope='col' className='px-0' style={{ width: '150px' }}>
            Reset Password
          </th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id}>
                <td>{data.name}</td>
                <td className='px-0' style={{ width: '170px' }}>
                  {data.phone}
                </td>
                <td>{data.level}</td>
                <td>{data.userName}</td>
                <td className='px-0' style={{ width: '150px' }}>
                  <button onClick={() => handleReset(data.id)}>Reset</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default FrontDeskTable;
