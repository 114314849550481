import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterMenu from '../../components/common/FilterMenu';
import '../../styles/maintenance/MaintenanceRequests.css';
import TodayRequests from '../../components/maintenance/maintenance-requests/TodayRequests';
import OverdueRequests from '../../components/maintenance/maintenance-requests/OverdueRequests';
import UpcommingRequests from '../../components/maintenance/maintenance-requests/UpcommingRequests';
import TodayRequestsShort from '../../components/maintenance/maintenance-requests/TodayRequestsShort';
import DetailsPage from '../../components/maintenance/maintenance-requests/DetailsPage';
import OverdueRequestsShort from '../../components/maintenance/maintenance-requests/OverdueRequestsShort';
import AddWorkOrder from '../../components/maintenance/maintenance-requests/AddWorkOrder';
import UpcommingRequestsShort from '../../components/maintenance/maintenance-requests/UpcommingRequestsShort';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '45px',
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});
const MaintenanceRequests = () => {
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];
  const filterByRequestsOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Not Started', value: 'not-started' },
    { label: 'In Progress', value: 'in-progress' }
  ];
  const classes = useStyles();
  const [showTodayRequest, setShowTodayRequest] = useState(true);
  const [showOverdueRequest, setShowOverdueRequest] = useState(true);
  const [showUpcomingRequest, setShowUpcomingRequest] = useState(true);
  const [showTodayRequestShort, setShowTodayRequestShort] = useState(true);
  const [showOverdueRequestShort, setShowOverdueRequestShort] = useState(true);
  const [showUpcomingRequestShort, setShowUpcomingRequestShort] = useState(
    true
  );
  const [workOrderOpen, setWorkOrderOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [readDetails, setReadDetails] = useState(false);
  const [detailsID, setDetailsID] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    request: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAddNewClick = (event, index) => {
    setFormOpen(true);
    setWorkOrderOpen(true);
    setReadDetails(false);
  };
  return (
    <div className='maintenance-req main-section'>
      {!formOpen && (
        <>
          <div className='maintenance-req-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Maintenance Requests</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                <FilterMenu
                  value={filters.request}
                  onChange={(e) => filterChange('request', e)}
                  options={filterByRequestsOptions}
                  placeholder='Filter Request'
                />
              </div>

              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Request Work Order
                </Button>
              </div>
            </div>
          </div>
          {/* MaintenanceRequests Tables */}
          <div className='maintenance-requests-tables'>
            <hr className='main-devider' />
            <div className='today-requests'>
              <p className='requests-table-head m-0'>
                {showTodayRequest === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodayRequest(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodayRequest(false)}
                  />
                )}
                Today{' '}
              </p>
              {showTodayRequest && (
                <TodayRequests
                  setReadDetails={setReadDetails}
                  setDetailsID={setDetailsID}
                  setFormOpen={setFormOpen}
                  setWorkOrderOpen={setWorkOrderOpen}
                />
              )}
            </div>
            <div className='overdue-requests'>
              <p className='requests-table-head m-0'>
                {showOverdueRequest === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdueRequest(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdueRequest(false)}
                  />
                )}
                Overdue
              </p>
              {showOverdueRequest && <OverdueRequests />}
            </div>

            <div className='upcomming-requests mb-4'>
              <p className='requests-table-head m-0'>
                {showUpcomingRequest === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcomingRequest(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcomingRequest(false)}
                  />
                )}
                Upcoming
              </p>
              {showUpcomingRequest && <UpcommingRequests />}
            </div>
          </div>
        </>
      )}
      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Maintenance Requests</p>
              <hr className='short-devider' />
              <div className='today-requests'>
                <p className='requests-table-head m-0'>
                  {showTodayRequestShort === true ? (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodayRequestShort(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodayRequestShort(true)}
                    />
                  )}
                  Today
                </p>
                {showTodayRequestShort && (
                  <TodayRequestsShort
                    setReadDetails={setReadDetails}
                    setDetailsID={setDetailsID}
                    setFormOpen={setFormOpen}
                    setWorkOrderOpen={setWorkOrderOpen}
                  />
                )}
              </div>
              <div className='overdue-requests'>
                <p className='requests-table-head m-0'>
                  {showOverdueRequestShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueRequestShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueRequestShort(false)}
                    />
                  )}
                  Overdue
                </p>
                {showOverdueRequestShort && <OverdueRequestsShort />}
              </div>
              <div className='upcomming-requests'>
                <p className='requests-table-head m-0'>
                  {showUpcomingRequestShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingRequestShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingRequestShort(false)}
                    />
                  )}
                  Upcoming
                </p>
                {showUpcomingRequestShort && <UpcommingRequestsShort />}
              </div>
            </div>

            <div className='forms-section'>
              {workOrderOpen && (
                <AddWorkOrder
                  setFormOpen={setFormOpen}
                  setWorkOrderOpen={setWorkOrderOpen}
                />
              )}
              {readDetails && (
                <DetailsPage
                  detailsID={detailsID}
                  setFormOpen={setFormOpen}
                  setReadDetails={setReadDetails}
                  detailsDrawerOpen={detailsDrawerOpen}
                  setDetailsDrawerOpen={setDetailsDrawerOpen}
                  setWorkOrderOpen={setWorkOrderOpen}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MaintenanceRequests;
