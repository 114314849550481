import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import * as Svg from '../../../components/common/icons';
import '../../../styles/dashboard/Announcements.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AntSwitch from '../../common/AntSwitch';
import SelectDropdown from '../../common/SelectDropdown';

const AddNewForm = ({ pane, setFormOpen }) => {
  const deptOptions = ['Front Desk', 'Housekeeping', 'Maintenance', 'Sales'];
  const priorityOptions = [
    'Urgent',
    'Important',
    'Normal',
    'Complaints',
    'Closed'
  ];
  const assignedOptions = ['Dwight Schrute', 'Jim Halpert', 'Auvik', 'Matthew'];

  const [dueDate, setDueDate] = useState(null);
  const [toggled, setToggled] = useState({ checked: false });
  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const [dropSelect, setDropSelect] = useState({
    dept: null,
    priority: null,
    assignedTo: null
  });

  const selectChange = (name, value) => {
    setDropSelect({ ...dropSelect, [name]: value });
  };
  return (
    <form>
      <div className='announcement-half-header d-flex justify-content-between align-items-center'>
        <Button variant='contained' color='primary' className='primary-button'>
          {pane === 0 ? 'Create Announcement' : 'Create Memo'}
        </Button>

        <button
          type='submit'
          className='close-normal'
          onClick={() => setFormOpen(false)}
        >
          Close
        </button>
      </div>
      <div className='inputs'>
        <input
          type='text'
          name=''
          id='announcement-title-field'
          placeholder={`Write a short title for your ${
            pane === 0 ? 'announcement' : 'memo'
          }`}
        />

        <hr className='form-devider' />
        <div className='select-menus'>
          <div className='d-flex'>
            <div className='due-date'>
              <Svg.Calendar />
              <DatePicker
                dateFormat='MMMM d'
                placeholderText='Due Date'
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
              />
            </div>
            <div className='department d-flex align-items-center mr-2'>
              <Svg.Department />
              <SelectDropdown
                options={deptOptions}
                value={dropSelect.dept}
                onChange={(e) => selectChange('dept', e)}
                placeholder='Department'
              />
            </div>
            <div className='priority d-flex align-items-center'>
              <Svg.Priority />
              <SelectDropdown
                options={priorityOptions}
                value={dropSelect.priority}
                onChange={(e) => selectChange('priority', e)}
                placeholder='Priority'
              />
            </div>
            {pane !== 0 && (
              <div className='assigned d-flex align-items-center'>
                <Svg.Assigned />
                <SelectDropdown
                  options={assignedOptions}
                  value={dropSelect.assignedTo}
                  onChange={(e) => selectChange('assignedTo', e)}
                  placeholder='Assigned To'
                />
              </div>
            )}
          </div>

          <div className='private-mark'>
            <Typography component='div'>
              <Grid
                component='label'
                container
                alignItems='center'
                spacing={(1, 2)}
              >
                <Grid item>
                  <span className='form-toggle-label'>Mark as private</span>
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={toggled.checked}
                    onChange={handleToggleChange}
                    name='checked'
                  />
                </Grid>
              </Grid>
            </Typography>
          </div>
        </div>

        <hr className='form-devider' />
        <textarea
          name=''
          id='announcement-details-field'
          cols='30'
          rows='5'
          placeholder={`Your ${pane === 0 ? 'announcement' : 'memo'} details`}
        ></textarea>
        <hr className='form-devider' />
      </div>
    </form>
  );
};

export default AddNewForm;
