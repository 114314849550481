import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import '../../styles/navs/Header.css';
import img from '../../assets/images/auvik.jpg';

const Profile = () => {
  return (
    <div className='profile p-3 d-flex'>
      <SearchIcon fontSize='medium' style={{ color: '#434343' }} />
      <p className='user-name m-0'> Hello,User </p>
      <img src={img} alt='' />
    </div>
  );
};

export default Profile;
