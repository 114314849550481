import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import '../../../styles/operations/Operations.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 15%)'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ffffff',
    borderRadius: '3px',
    boxShadow: theme.shadows[5],
    padding: 0,
    height: '85vh',
    overflowY: 'scroll',
    marginTop: '120px',
    marginBottom: '120px'
  }
}));

const AddNewCashCount = ({ addNewForm, setAddNewForm, setPopUpOpen }) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddNewForm(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPopUpOpen(true);
    setAddNewForm(false);
  };
  const handleCancle = (e) => {
    e.preventDefault();
    setAddNewForm(false);
  };
  return (
    <div className='addNew-modal'>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={addNewForm}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={addNewForm}>
          <div className={classes.paper}>
            <div className='addNew-form'>
              <p className='my-0 form-title'>Add Cash Count</p>
              <form action=''>
                <div className='d-flex cash-input-group mb-4'>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$100</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$50</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$20</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$10</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center'>
                    <p className='cash-input-label'>$5</p>
                    <input type='number' className='cash-input' />
                  </div>
                </div>
                <div className='d-flex cash-input-group mb-4'>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$1</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$0.25</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$0.10</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center mr-3'>
                    <p className='cash-input-label'>$0.05</p>
                    <input type='number' className='cash-input' />
                  </div>
                  <div className='cash-input-box text-center'>
                    <p className='cash-input-label'>$0.01</p>
                    <input type='number' className='cash-input' />
                  </div>
                </div>

                <div className='note-input-box mb-4'>
                  <p className='my-0 note-input-title'>Note</p>
                  <textarea name='' id='' cols='30' rows='4'></textarea>
                </div>

                <div className='dropAmount-input-box'>
                  <p className='my-0 dropAmount-input-title'>Drop Amount</p>
                  <input type='number' />
                </div>

                <div className='addCashCount-btns text-center'>
                  <div>
                    <button className='cashCount-submit' onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                  <div>
                    {' '}
                    <button className='cashCount-cancle' onClick={handleCancle}>
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddNewCashCount;
