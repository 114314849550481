import React, { useState } from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { makeStyles, MenuItem } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import AntSwitch from '../../common/AntSwitch';
import userIMG from '../../../assets/images/auvik.jpg';
import DotMenu from '../../common/DotMenu';
import { Editor } from '@tinymce/tinymce-react';
import activites from './fakeActivity';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  }
});

const Comment = () => {
  const classes = useStyles();
  const [toggled, setToggled] = useState({ checked: false });
  const [editorOpen, setEditorOpen] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [text, setText] = useState('');

  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };
  const customButton = {
    text: 'Post',
    onAction: function () {
      setText('');
      setEditorOpen(false);
    }
  };

  return (
    <div className='comment-section pt-0'>
      {!showActivity && (
        <div>
          <div className='show-comment' style={{ paddingBottom: '200px' }}>
            <p className='checklist-comment-head my-0'>Comments</p>
            <div className='commenter-info d-flex align-items-center'>
              <img src={userIMG} alt='Commenter' />
              <p className='commenter-name my-0'>User Name</p>
              <p className='comment-date my-0'>Dec 32, 2019 at 12:10pm</p>
            </div>

            <div className='comment d-flex align-items-baseline'>
              <div className='comment-text-box'>
                <p className='comment-text my-0'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit,
                  aut. Ea sapiente odit aliquam nam magnam obcaecati
                  voluptatibus quasi vero.
                </p>
              </div>
              <ThumbUpIcon
                fontSize='small'
                style={{
                  color: '#797B88',
                  height: '12px',
                  marginRight: '16px',
                  marginLeft: '16px'
                }}
              />

              <DotMenu inline openLeft>
                <MenuItem className={classes.menuItem}>Edit Comment</MenuItem>
                <MenuItem className={classes.menuItem}>Delete Comment</MenuItem>
              </DotMenu>
            </div>
          </div>
          <div className='post-comment'>
            <div className='comment-post-box d-flex align-items-end'>
              <img src={userIMG} alt='User' />

              {editorOpen && (
                <div className='editor-area'>
                  <div className='d-flex align-items-center justify-content-between'>
                    {' '}
                    <div
                      className='private-mark'
                      style={{ paddingBottom: '8px' }}
                    >
                      <Typography component='div'>
                        <Grid
                          component='label'
                          container
                          alignItems='center'
                          spacing={1}
                        >
                          <Grid item>
                            <span className='form-toggle-label'>
                              Mark task incomplete
                            </span>
                          </Grid>
                          <Grid item>
                            <AntSwitch
                              checked={toggled.checked}
                              onChange={handleToggleChange}
                              name='checked'
                            />
                          </Grid>
                        </Grid>
                      </Typography>
                    </div>
                    <button
                      onClick={() => setShowActivity(true)}
                      className='activity-btn mb-2'
                    >
                      Activity
                    </button>
                  </div>
                  <div className='task-comment-input'>
                    <Editor
                      apiKey='nytdwdejhgozrvq9yqk274bsczhm8weh0g7m9p79p9ht9hue'
                      value={text}
                      onEditorChange={(newText) => setText(newText)}
                      init={{
                        selector: 'textarea#custom-toolbar-button',
                        plugins: 'link lists image emoticons',
                        toolbar:
                          ' bold italic numlist bullist image emoticons custom-toolbar-button',
                        emoticons_images_url:
                          'http://www.iemoji.com/meanings-gallery/smileys-people',
                        toolbar_location: 'bottom',
                        menubar: false,
                        statusbar: false,
                        max_height: 130,
                        images_upload_url: 'postAcceptor.php',
                        images_upload_handler: function (
                          blobInfo,
                          success,
                          failure
                        ) {
                          setTimeout(function () {
                            success(
                              'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png'
                            );
                          }, 2000);
                        },
                        setup: function (editor) {
                          editor.ui.registry.addButton(
                            'custom-toolbar-button',
                            customButton
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {!editorOpen && (
                <div
                  className='editor-clickBox'
                  onClick={() => setEditorOpen(true)}
                >
                  <div className='d-flex justify-content-end'>
                    {' '}
                    <button
                      onClick={() => setShowActivity(true)}
                      className='activity-btn mb-2'
                    >
                      Activity
                    </button>
                  </div>
                  <div className='click-box'></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showActivity && (
        <div className='activity-section'>
          <div className='show-activity'>
            <p className='my-0 activity-head'>Activities</p>
            {activites.map((activity) => (
              <div
                className='activity-list d-flex align-items-center justify-content-between pb-2'
                key={activity.name}
              >
                <p className='my-0 activity-name'>{activity.name}</p>
                <p className='my-0 activity-time'>{activity.time}</p>
              </div>
            ))}
            <button className='show-more-btn'>
              Show 10 previous activities
            </button>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              className='comment-btn'
              onClick={() => setShowActivity(false)}
            >
              Comments
            </button>
          </div>

          <hr className='activity-devider' />
        </div>
      )}
    </div>
  );
};

export default Comment;
