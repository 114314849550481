import React from 'react';
import * as Svg from '../../../components/common/icons';
import { makeStyles, MenuItem } from '@material-ui/core';
import '../../../styles/managerView/ChecklistsMV.css';
import fakeData from '../../managerView/ChecklistsMV/fakeChecklist';
import DotMenu from '../../common/DotMenu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative'
  },

  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343 !important',
      color: '#FFFFFF !important'
    }
  }
});

const DetailsPage = ({
  setFormOpen,
  detailsID,
  setReadDetails,
  setOpenEditDrawer,
  setEditID,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const classes = useStyles();
  const singleData = fakeData.find((data) => data.id === detailsID);
  const handleClose = () => {
    setReadDetails(false);
    setFormOpen(false);
  };

  const handleEdit = (id) => {
    setEditID(id);
    setOpenEditDrawer(true);
  };
  const handleDelete = () => {
    setFormOpen(false);
    setReadDetails(false);
    setOpenPopUp(true);
    setPopUpMSG('Checklist item deleted successfully');
    setPopUpType('danger');
  };

  return (
    <div className='checklistMV-details'>
      <div className='d-flex justify-content-end'>
        <button className='close-normal mb-4 mr-2' onClick={handleClose}>
          Close
        </button>
      </div>
      <div className='details-info'>
        <hr className='form-devider' />
        <div className='details-filters'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='shift-info'>
              <Svg.Department />
              <p className='detailsShift m-0 pl-2'>{singleData.shift}</p>
            </div>
            <div className='work-day-info'>
              <Svg.Calendar />
              <p className='detailsWorkday m-0 pl-2'>{singleData.workDay}</p>
            </div>
            <div className='assigned-info'>
              <Svg.Assigned />
              <p className='detailsAssigned m-0 pl-2'>
                {singleData.assignedTo}
              </p>
            </div>
            <div className='reminder-info'>
              <Svg.Clock />
              <p className='detailsReminder m-0 pl-2'>{singleData.reminder}</p>
            </div>
          </div>

          <div className='addExpense d-flex align-items-center'>
            <DotMenu inline openLeft>
              <MenuItem className={classes.menuItem}>Duplicate</MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => handleEdit(singleData.id)}
              >
                Edit
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                style={{ color: '#DE6552' }}
                onClick={handleDelete}
              >
                Delete
              </MenuItem>
            </DotMenu>
          </div>
        </div>

        <hr className='form-devider' />

        <p className='details-Title'>{singleData.task}</p>

        <hr className='form-devider' />

        <p className='detailsText my-0'>{singleData.details}</p>
        <hr className='form-devider' />
      </div>
    </div>
  );
};

export default DetailsPage;
