import React, { useState } from 'react';
import signImg from '../../../assets/images/login-illustration.png';
import ConfirmPopUp from './ConfirmPopUp';

const SignClose = ({
  setFormOpen,
  setSignClose,
  setReadDetails,
  setAddNewForm
}) => {
  const [popUp, setPopUp] = useState(false);
  const handleReadAgain = () => {
    setSignClose(false);
    setReadDetails(true);
    setAddNewForm(false);
  };

  const handleSubmitConfirm = () => {
    setPopUp(true);
    // setAddNewForm(false);
    // setFormOpen(false);
  };
  return (
    <div className='sign-close text-center'>
      <h4 className='sign-title'>I have read the Memo</h4>
      <p className='sign-here m-0'> Please Sign here</p>
      <input
        type='text'
        name=''
        id='sign-box'
        placeholder='Please type your full name'
      />
      <div className='btns-twin'>
        <button className='btn-confirm mx-2' onClick={handleSubmitConfirm}>
          Submit Confirmation
        </button>
        <button className='btn-readAgain mx-2' onClick={handleReadAgain}>
          Read agin
        </button>
      </div>

      <img src={signImg} alt='' className='ml-5 sign-img' />

      {popUp && (
        <ConfirmPopUp
          popUp={popUp}
          setPopUp={setPopUp}
          setFormOpen={setFormOpen}
          setAddNewForm={setAddNewForm}
          setSignClose={setSignClose}
          setReadDetails={setReadDetails}
        />
      )}
    </div>
  );
};

export default SignClose;
