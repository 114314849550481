import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from '../../components/common/icons';
import useOutsideClick from '../../components/common/useClickOutside';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    paddingLeft: '8px',
    marginRight: 16,
    width: '94px',
    userSelect: 'none',
    '& label': {
      display: 'flex !important',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '2px',
      marginBottom: '0',
      cursor: 'pointer',
      fontFamily: 'Open sans',
      fontWeight: '500',
      color: '#434343',
      '& p': {
        fontFamily: 'Open sans',
        fontSize: 14,
        fontWeight: '500',
        color: '#434343'
      }
    }
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: -16,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all'
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& li': {
      padding: '10px 16px',
      fontSize: 14,
      lineHeight: '16px',
      transition: 'all 0.2s linear',
      fontFamily: 'Open sans',
      fontWeight: '500',
      color: '#434343',
      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear'
        }
      },
      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear'
        }
      }
    },
    '& hr': {
      margin: 0,
      borderTop: '1px solid RGBA(172, 180, 184, 0.2)',
      height: 0
    }
  },
  addOptionBtn: {
    border: 'none',
    outline: 'none',
    background: 'none',
    margin: '10px auto',
    display: 'flex',
    fontFamily: 'Open sans',
    fontSize: 14,
    fontWeight: '500',
    color: '#DE6552'
  }
});

const SelectDropdown = ({ placeholder, value, onChange, options }) => {
  const classes = useStyles();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const [label, setLabel] = useState(placeholder);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!value) {
      setLabel(placeholder ? placeholder : 'nothing selected');
    }
  }, [value, placeholder]);

  const selectOption = (option) => {
    let newValue;

    if (value === option) {
      newValue = null;
    } else {
      newValue = option;
    }
    setLabel(option);
    onChange(newValue);
    setOpen(false);
  };

  const optionIsSelected = (option) => {
    if (value === option) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={classes.root} ref={ref}>
      <label onClick={() => toggleOpen()}>
        <p className='my-0'>{label}</p>
      </label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <ul>
          {options.map((option) => (
            <li
              key={option}
              value={option}
              onClick={() => selectOption(option)}
            >
              {option} {optionIsSelected(option) ? <Svg.Checkmark /> : ''}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectDropdown;
