import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import * as Svg from '../../../components/common/icons';
import '../../../styles/dashboard/Announcements.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectDropdown from '../../common/SelectDropdown';

const AddNewForm = ({ setFormOpen }) => {
  const priorityOptions = [
    'Urgent',
    'Important',
    'Normal',
    'Complaints',
    'Closed'
  ];
  const assignedOptions = ['Dwight Schrute', 'Jim Halpert', 'Auvik', 'Matthew'];
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [dropSelect, setDropSelect] = useState({
    dept: null,
    priority: null,
    assignedTo: null
  });

  const selectChange = (name, value) => {
    setDropSelect({ ...dropSelect, [name]: value });
  };
  return (
    <form>
      <div className='request-half-header d-flex justify-content-between align-items-center'>
        <Button variant='contained' color='primary' className='primary-button'>
          Create Request
        </Button>

        <button
          type='submit'
          className='close-normal'
          onClick={() => setFormOpen(false)}
        >
          Close
        </button>
      </div>
      <div className='inputs'>
        <input
          type='text'
          name=''
          id='requests-title-field'
          placeholder='Write a short title of the Request'
        />

        <hr className='form-devider' />
        <div className='select-menus'>
          <div className='d-flex'>
            {/* <div className='start-date'>
              <Svg.Calendar />
              <DatePicker
                dateFormat='MMMM d'
                placeholderText='Start date'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div> */}
            <div className='due-date'>
              <Svg.Calendar />
              <DatePicker
                dateFormat='MMM d'
                placeholderText='Due date'
                className='custom-datePicker'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              >
                <hr className='footer-plugin-devider' />
                <div className='datepicker-footer-plugin'>
                  <div className='note-footer d-flex'>
                    <div className='start-date mr-3 align-items-center'>
                      <p className='start-date-title text-left mb-2'>
                        Start Date
                      </p>
                      <div className='start-date-box'>
                        <span className='text-left'>
                          {startDate !== null && startDate.toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div className='end-date'>
                      <p className='end-date-title text-left mb-2'>End Date</p>
                      <div className='end-date-box'>
                        <span className='text-left'>
                          {' '}
                          {endDate !== null && endDate.toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </DatePicker>
            </div>
            <div className='priority d-flex align-items-center'>
              <Svg.Priority />
              <SelectDropdown
                options={priorityOptions}
                value={dropSelect.priority}
                onChange={(e) => selectChange('priority', e)}
                placeholder='Priority'
              />
            </div>
            <div className='assigned d-flex align-items-center'>
              <Svg.Assigned />
              <SelectDropdown
                options={assignedOptions}
                value={dropSelect.assignedTo}
                onChange={(e) => selectChange('assignedTo', e)}
                placeholder='Assigned To'
              />
            </div>
          </div>
        </div>

        <hr className='form-devider' />
        <input
          type='text'
          name=''
          id='requests-room-field'
          placeholder='Room number or location'
        />
        <hr className='form-devider' />
        <textarea
          name=''
          id='requests-details-field'
          cols='30'
          rows='5'
          placeholder='Guest Request Details'
        ></textarea>
        <hr className='form-devider' />
      </div>
    </form>
  );
};

export default AddNewForm;
