import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterMenu from '../../components/common/FilterMenu';
import AntSwitch from '../../components/common/AntSwitch';
import FrontDeskTable from '../../components/dashboard/requests/FrontDeskTable';
import MaintenanceTable from '../../components/dashboard/requests/MaintenanceTable';
import TableFrontDeskShort from '../../components/dashboard/requests/TableFrontDeskShort';
import TableMaintenanceShort from '../../components/dashboard/requests/TableMaintenanceShort';
import DetailsPage from '../../components/dashboard/requests/DetailsPage';
import AddNewForm from '../../components/dashboard/requests/AddNewForm';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '40px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});

const Requests = () => {
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];
  const filterByRequestsOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Not Started', value: 'not-started' },
    { label: 'In Progress', value: 'in-progress' }
  ];
  const filterByDepartmentsOption = [
    { label: 'Front Desk', value: 'front-Desk' },
    { label: 'Housekeeping', value: 'housekeeping' }
  ];

  const classes = useStyles();
  const [showFrontDesk, setShowFrontDesk] = useState(true);
  const [showMaintenance, setShowMaintenance] = useState(true);
  const [showFrontDeskShort, setShowFrontDeskShort] = useState(true);
  const [showMaintenanceShort, setShowMaintenanceShort] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [addNewForm, setAddNewForm] = useState(false);
  const [readDetails, setReadDetails] = useState(false);
  const [detailsID, setDetailsID] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    request: null,
    department: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const [toggled, setToggled] = useState({ checked: false });

  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const handleAddNewClick = (event, index) => {
    setFormOpen(true);
    setAddNewForm(true);
    setReadDetails(false);
  };

  return (
    <div className='main-section'>
      {!formOpen && (
        <>
          <div className='requests-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Guest Requests</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                <FilterMenu
                  value={filters.request}
                  onChange={(e) => filterChange('request', e)}
                  options={filterByRequestsOptions}
                  placeholder='Filter Request'
                />
                <FilterMenu
                  value={filters.department}
                  onChange={(e) => filterChange('department', e)}
                  options={filterByDepartmentsOption}
                  placeholder='All Departments'
                />

                <FormControl className={classes.formControl}>
                  <Typography component='div' style={{ marginRight: '40px' }}>
                    <Grid
                      component='label'
                      container
                      alignItems='center'
                      spacing={1}
                    >
                      <Grid item>
                        <span className='toggle-label'>My Department</span>
                      </Grid>
                      <Grid item>
                        <AntSwitch
                          checked={toggled.checked}
                          onChange={handleToggleChange}
                          name='checked'
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </FormControl>
              </div>

              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {/*Requests Table */}
          <div className='requests-tables'>
            <hr className='main-devider' />
            <div className='frontDesk-requests'>
              <p className='requests-table-head m-0'>
                {showFrontDesk === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowFrontDesk(false)}
                  />
                )}
                Front Desk{' '}
              </p>
              {showFrontDesk && (
                <FrontDeskTable
                  setReadDetails={setReadDetails}
                  setDetailsID={setDetailsID}
                  setFormOpen={setFormOpen}
                  setAddNewForm={setAddNewForm}
                />
              )}
            </div>
            <div className='maintenance-requests mb-4'>
              <p className='requests-table-head m-0'>
                {showMaintenance === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowMaintenance(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowMaintenance(false)}
                  />
                )}
                Maintenance
              </p>
              {showMaintenance && <MaintenanceTable />}
            </div>
          </div>
        </>
      )}

      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Guest Requests</p>
              <hr className='short-devider' />
              <div className='frontDesk-requests'>
                <p className='requests-table-head m-0'>
                  {showFrontDeskShort === true ? (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowFrontDeskShort(true)}
                    />
                  )}
                  Front Desk
                </p>
                {showFrontDeskShort && (
                  <TableFrontDeskShort
                    setReadDetails={setReadDetails}
                    setDetailsID={setDetailsID}
                    setFormOpen={setFormOpen}
                    setAddNewForm={setAddNewForm}
                  />
                )}
              </div>
              <div className='maintenance-requests'>
                <p className='requests-table-head m-0'>
                  {showMaintenanceShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowMaintenanceShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowMaintenanceShort(false)}
                    />
                  )}
                  Maintenance
                </p>
                {showMaintenanceShort && <TableMaintenanceShort />}
              </div>
            </div>

            <div className='forms-section'>
              {addNewForm && <AddNewForm setFormOpen={setFormOpen} />}
              {readDetails && (
                <DetailsPage
                  detailsID={detailsID}
                  setFormOpen={setFormOpen}
                  setReadDetails={setReadDetails}
                  setAddNewForm={setAddNewForm}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Requests);
