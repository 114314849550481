var fakeHKData = [
  {
    id: 0,
    room: 10001,
    type: 'KNS',
    status: 'Ready To Rent',
    startTime: '',
    cleanIN: '',
    hk: 'Lura M.'
  },
  {
    id: 1,
    room: 100002,
    type: 'KNSPTKMP',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:12',
    hk: 'Lura M.'
  },
  {
    id: 2,
    room: 10001,
    type: 'KNS',
    status: 'Ready To Rent',
    startTime: '10:00 AM',
    cleanIN: '-00:37',
    hk: 'Lura M.'
  },
  {
    id: 3,
    room: 100003,
    type: 'KNSPTKMP',
    status: 'Dirty',
    startTime: '',
    cleanIN: '',
    hk: 'Lura M.'
  },
  {
    id: 4,
    room: 100002,
    type: 'KNS',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  },
  {
    id: 5,
    room: 100004,
    type: 'KNSPTKMP',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  },
  {
    id: 6,
    room: 100002,
    type: 'KNS',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  },
  {
    id: 7,
    room: 100005,
    type: 'KNSPTKMP',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  },
  {
    id: 8,
    room: 100003,
    type: 'KNS',
    status: 'Dirty',
    startTime: '',
    cleanIN: '',
    hk: 'Lura M.'
  },
  {
    id: 9,
    room: 100003,
    type: 'KNSPTKMP',
    status: 'Dirty',
    startTime: '',
    cleanIN: '',
    hk: 'Lura M.'
  },
  {
    id: 10,
    room: 100004,
    type: 'KNS',
    status: 'Making',
    startTime: '10:00 AM',
    cleanIN: '-00:13',
    hk: 'Lura M.'
  },
  {
    id: 11,
    room: 100004,
    type: 'KNSPTKMP',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  },
  {
    id: 12,
    room: 100005,
    type: 'KNSPTKMP',
    status: 'Blocked',
    startTime: '',
    cleanIN: '',
    hk: 'Lura M.'
  },
  {
    id: 13,
    room: 100005,
    type: 'KNSPTKMP',
    status: 'Cleaning',
    startTime: '10:00 AM',
    cleanIN: '-00:26',
    hk: 'Lura M.'
  }
];
export default fakeHKData;
