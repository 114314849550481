import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Grid, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AntSwitch from '../../common/AntSwitch';
import '../../../styles/maintenance/MaintenanceRequests.css';

const useStyles = makeStyles({
  list: {
    width: 460,
    margin: '32px'
  },
  fullList: {
    width: 'auto'
  }
});
const DetailsDrawer = ({ detailsDrawerOpen, setDetailsDrawerOpen }) => {
  const classes = useStyles();
  const [toggled, setToggled] = useState({ checked: false });
  const [fileName, setFileName] = useState('');

  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const closeDrawer = (event, reason) => {
    if (reason !== 'backdropClick') {
      setDetailsDrawerOpen(false);
    }
  };
  const handleFileChange = (event) => {
    setFileName(event.target.files[0].name);
  };
  const handleCancle = (params) => {
    closeDrawer();
  };
  const handleSave = (params) => {
    closeDrawer();
  };
  return (
    <div>
      <Drawer
        anchor='right'
        open={detailsDrawerOpen}
        onClose={closeDrawer}
        transitionDuration={{ appear: 200, enter: 200, exit: 200 }}
      >
        <div className={classes.list}>
          <div className='drawer-head d-flex align-items-center justify-content-between'>
            <p className='my-0'>Add Items & Expense</p>
            <div className='private-mark'>
              <Typography component='div'>
                <Grid
                  component='label'
                  container
                  alignItems='center'
                  spacing={(1, 2)}
                >
                  <Grid item>
                    <span className='drawer-toggle-label'>
                      This is an Item Request
                    </span>
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={toggled.checked}
                      onChange={handleToggleChange}
                      name='checked'
                    />
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </div>

          <div className='drawer-form'>
            <div className='item'>
              <p className='my-0'>Item Name</p>
              <input type='text' placeholder='What is the name of the item?' />
            </div>
            <div className='item'>
              <p className='my-0'>Quantity</p>
              <input
                type='number'
                placeholder='What is the quantity you need?'
              />
            </div>
            <div className='item'>
              <p className='my-0'>Price</p>
              <input type='number' placeholder='What is price of each unit?' />
            </div>
            <hr className='form-devider' />

            <div className='item-file'>
              <label for='file-upload' class='custom-file-upload'>
                <AttachFileIcon fontSize='large' style={{ color: '#BDBDBD' }} />
                <span>{fileName !== '' ? fileName : 'Attach a receipt'}</span>
              </label>
              <input id='file-upload' type='file' onChange={handleFileChange} />
            </div>
          </div>
          <div
            className='btn-group justify-content-end drawer-btns '
            role='group'
            aria-label='Basic example'
          >
            <button className='drawer-cancle' onClick={handleCancle}>
              Cancel
            </button>
            <button className='drawer-save' onClick={handleSave}>
              Save & Add
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DetailsDrawer;
