import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../styles/maintenance/MaintenanceRequests.css';

const useStyles = makeStyles({
  list: {
    width: 460,
    margin: '32px'
  },
  fullList: {
    width: 'auto'
  },
  datepickerItem: {
    '& .react-datepicker-wrapper': {
      width: '100%',
      '& .react-datepicker__input-container input': {
        margin: 'auto 0'
      }
    }
  }
});
const AddItemDrawer = ({
  addDrawerOpen,
  setAddDrawerOpen,
  setOpenPopUp,
  setSuccessMSG
}) => {
  const classes = useStyles();
  const [nextDate, setNextDate] = useState(null);
  const closeDrawer = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddDrawerOpen(false);
    }
  };

  const handleCancle = (params) => {
    closeDrawer();
  };
  const handleSave = (params) => {
    setOpenPopUp(true);
    setSuccessMSG('Item added successfully');
    closeDrawer();
  };
  return (
    <div>
      <Drawer
        anchor='right'
        open={addDrawerOpen}
        onClose={closeDrawer}
        transitionDuration={{ appear: 200, enter: 200, exit: 200 }}
      >
        <div className={classes.list}>
          <div className='drawer-head d-flex align-items-center justify-content-between'>
            <p className='my-0'>Add New Items To Track</p>
          </div>

          <div className='drawer-form'>
            <div className='item'>
              <p className='my-0'>Item Name</p>
              <input type='text' placeholder='What is the name of the item?' />
            </div>
            <div className='item'>
              <p className='my-0'>Location</p>
              <input
                type='text'
                placeholder='What is the location of the item?'
              />
            </div>
            <div className={` item ${classes.datepickerItem}`}>
              <p className='my-0'>Next Replacement Date</p>
              <DatePicker
                dateFormat='MMMM d, yyyy'
                placeholderText='When do you want to replace this item?'
                selected={nextDate}
                onChange={(date) => setNextDate(date)}
                className='darwer-date-picker'
              />
            </div>
          </div>
          <div
            className='btn-group justify-content-end drawer-btns '
            role='group'
            aria-label='Basic example'
          >
            <button className='drawer-cancle' onClick={handleCancle}>
              Cancel
            </button>
            <button className='drawer-save' onClick={handleSave}>
              Save & Add
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddItemDrawer;
