import React from 'react';
import '../../../styles/dashboard/Overview.css';

const Statistics = () => {
  return (
    <div className='statistic'>
      <div className='card-deck mt-2 mb-4'>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/announcements.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>Announcements</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/tasks.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>Tasks & Notes</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/requests.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>Total Requests</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/efficiency.svg' alt='' />
            <p className='value my-2'>00 %</p>
            <p className='state-name my-0'>Weekly Efficiency</p>
          </div>
        </div>
      </div>

      <div className='card-deck mt-2 mb-4'>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/guests.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>In-House Guest</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/arrivals.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>Arrivals</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/departures.svg' alt='' />
            <p className='value my-2'>00</p>
            <p className='state-name my-0'>Departures</p>
          </div>
        </div>
        <div className='card statistic-card' style={{ width: '18rem' }}>
          <div className='card-body'>
            <img src='/icons/forecast.svg' alt='' />
            <p className='value my-2'>00 %</p>
            <p className='state-name my-0'>Service Level Assurance</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
