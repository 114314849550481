var fakeData = [
  {
    id: 0,
    title:
      'This is first Memo This is first Memo This is first Memo Memo This is first Memo Memo',
    dept: 'Front Desk',
    createdBY: 'Micheals James',
    dueDate: 'Dec 12',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'This is 2nd Memo',
    dept: 'Front Desk',
    createdBY: 'Micheals James',
    dueDate: 'Dec 12',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 2,
    title: 'This is 3rd Memo',
    dept: 'Front Desk',
    createdBY: 'Micheals James',
    dueDate: 'Dec 12',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 3,
    title: 'This is 4th Memo',
    dept: 'Front Desk',
    createdBY: 'Micheals James',
    dueDate: 'Dec 12',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  }
];
export default fakeData;
