import React from 'react';
import '../../../styles/operations/Operations.css';

const Statistics = () => {
  return (
    <div className='cashCount-statistics'>
      <div className='card-deck mb-4'>
        <div
          className='card cashCount-card'
          style={{ width: '18.5rem', height: '150px' }}
        >
          <div className='card-body cashCount-cardBody'>
            <img src='/icons/money-bag.svg' alt='' />
            <p className='value mt-3 mb-1'>$ 00</p>
            <p className='state-name my-0'>Cash In Drawer</p>
          </div>
        </div>
        <div
          className='card cashCount-card'
          style={{ width: '18.5rem', height: '150px' }}
        >
          <div className='card-body cashCount-cardBody'>
            <img src='/icons/dollar-calculator.svg' alt='' />
            <p className='value mt-3 mb-1'>$ 00</p>
            <p className='state-name my-0'>Discrepancy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
