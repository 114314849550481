import React, { useState } from 'react';
import oldData from './fakeSuggestions';

const AddNewForm = ({
  setFormOpen,
  setAddNewForm,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const [newDept, setNewDept] = useState({
    dept_1: '',
    dept_2: '',
    dept_3: '',
    dept_4: '',
    dept_5: '',
    dept_6: '',
    dept_7: ''
  });

  //Handle Input Change
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setNewDept((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const matchDept_1 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_1.toLowerCase()
  );
  const matchDept_2 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_2.toLowerCase()
  );
  const matchDept_3 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_3.toLowerCase()
  );
  const matchDept_4 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_4.toLowerCase()
  );
  const matchDept_5 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_5.toLowerCase()
  );
  const matchDept_6 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_6.toLowerCase()
  );
  const matchDept_7 = oldData.find(
    (data) => data.title.toLowerCase() === newDept.dept_7.toLowerCase()
  );

  // console.log(matchDept_1.title);

  //Handle Clear Button
  const handleAllClear = (e) => {
    setNewDept((oldState) => ({
      ...oldState,
      dept_1: '',
      dept_2: '',
      dept_3: '',
      dept_4: '',
      dept_5: '',
      dept_6: '',
      dept_7: ''
    }));
  };

  //Handle Save&ADD Button
  const handleSaveAdd = (e) => {
    if (
      matchDept_1 !== undefined ||
      matchDept_2 !== undefined ||
      matchDept_3 !== undefined ||
      matchDept_4 !== undefined ||
      matchDept_5 !== undefined ||
      matchDept_6 !== undefined ||
      matchDept_7 !== undefined
    ) {
      setOpenPopUp(true);
      setPopUpMSG(
        'This department already exists. You can not have two departments with same name. Please try adding a different one'
      );
      setPopUpType('danger');
    } else {
      setNewDept((oldState) => ({
        ...oldState,
        dept_1: '',
        dept_2: '',
        dept_3: '',
        dept_4: '',
        dept_5: '',
        dept_6: '',
        dept_7: ''
      }));
      setAddNewForm(true);
      setFormOpen(true);
      setOpenPopUp(true);
      setPopUpMSG('New department added successfully');
      setPopUpType('success');
    }
  };

  //Handle Save&Close Button
  const handleSaveClose = (e) => {
    setNewDept((oldState) => ({
      ...oldState,
      dept_1: '',
      dept_2: '',
      dept_3: '',
      dept_4: '',
      dept_5: '',
      dept_6: '',
      dept_7: ''
    }));
    setAddNewForm(false);
    setFormOpen(false);
    setOpenPopUp(true);
    setPopUpMSG('New department added successfully');
    setPopUpType('success');
  };
  return (
    <div>
      <div className='departmentsMV-half-header d-flex justify-content-between align-items-center'>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='btn-save-add' onClick={handleSaveAdd}>
            Save & Add More
          </button>

          <button className='btn-save-close' onClick={handleSaveClose}>
            Save & Close
          </button>
        </div>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='clear-btn' onClick={handleAllClear}>
            Clear
          </button>
          <button
            type='submit'
            className='close-normal'
            onClick={() => setFormOpen(false)}
          >
            Close
          </button>
        </div>
      </div>

      {/* Field-1 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_1'
          style={{ color: newDept.dept_1 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_1'
          id='dept_1'
          placeholder='Type here'
          value={newDept.dept_1}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-2 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_2'
          style={{ color: newDept.dept_2 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_2'
          id='dept_2'
          placeholder='Type here'
          value={newDept.dept_2}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-3 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_3'
          style={{ color: newDept.dept_3 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_3'
          id='dept_3'
          placeholder='Type here'
          value={newDept.dept_3}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-4 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_4'
          style={{ color: newDept.dept_4 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_4'
          id='dept_4'
          placeholder='Type here'
          value={newDept.dept_4}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-5 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_5'
          style={{ color: newDept.dept_5 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_5'
          id='dept_5'
          placeholder='Type here'
          value={newDept.dept_5}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-6 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_6'
          style={{ color: newDept.dept_6 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_6'
          id='dept_6'
          placeholder='Type here'
          value={newDept.dept_6}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-7 */}
      <div className='deptForm-wrap'>
        <label
          htmlFor='dept_7'
          style={{ color: newDept.dept_7 !== '' && '#BDBDBD' }}
        >
          Department Name
        </label>
        <input
          type='text'
          name='dept_7'
          id='dept_7'
          placeholder='Type here'
          value={newDept.dept_7}
          onChange={handleNameChange}
        />
      </div>
    </div>
  );
};

export default AddNewForm;
