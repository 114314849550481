import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Svg from './icons';
import useOutsideClick from './useClickOutside';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginRight: 24,
    userSelect: 'none',
    '& label': {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '2px',
      marginBottom: '0',
      cursor: 'pointer',
      fontSize: 12,
      fontFamily: 'Open sans',
      fontWeight: '600',
      color: '#434343',
      '& .triangle': {
        marginLeft: 8
      }
    }
  },
  clear: {
    // display: 'inline-block',
    width: 14,
    height: 14,
    borderRadius: '100%',
    textAlign: 'center',
    fontSize: 14,
    verticalAlign: 'middle',
    lineHeight: '14px',
    border: '1px solid red',
    color: 'red',
    marginRight: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textIndent: '-1px'
  },
  dropdown: {
    position: 'absolute',
    minWidth: 175,
    left: -16,
    top: '100%',
    transition: 'opacity 0.1s linear, transform 0.3s ease-out',
    opacity: 0,
    transform: 'translateY(-10px)',
    pointerEvents: 'none',
    backgroundColor: ' #FFFFFF',
    boxShadow: '0 15px 30px -10px rgba(158,158,158,0.3)',
    '&.open': {
      opacity: 1,
      transform: 'translateY(0px)',
      pointerEvents: 'all'
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& li': {
      padding: '10px 16px',
      fontSize: 14,
      lineHeight: '16px',
      transition: 'all 0.2s linear',
      fontFamily: 'Open sans',
      fontWeight: '500',
      color: '#434343',
      '& svg': {
        height: 16,
        float: 'right',
        '& path': {
          fill: '#252427',
          transition: 'all 0.2s linear'
        }
      },

      '&:hover': {
        backgroundColor: '#252427',
        color: 'white',
        '& svg path': {
          fill: 'white',
          transition: 'all 0.2s linear'
        }
      }
    }
  }
});

const FilterMenu = ({
  placeholder,
  value,
  defaultOption,
  options,
  onChange
}) => {
  const classes = useStyles();
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const [label, setLabel] = useState(placeholder);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!value) {
      setLabel(placeholder ? placeholder : 'nothing selected');
    }
  }, [value, placeholder]);

  const selectOption = (option) => {
    let newValue;

    if (value === option.value) {
      newValue = null;
    } else {
      newValue = option.value;
    }
    setLabel(option.label);
    onChange(newValue);
    setOpen(false);
  };

  const reset = () => {
    onChange(null);
    setLabel(placeholder);
  };

  const optionIsSelected = (option) => {
    if (value === option.value) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} ref={ref}>
      <label onClick={() => toggleOpen()}>
        {value !== null && (
          <span onClick={() => reset()} className={classes.clear}>
            <Svg.XCircle />
          </span>
        )}{' '}
        {label}{' '}
        <span className='triangle'>
          <Svg.Triangle color={'#434343'} />
        </span>
      </label>

      <div className={`${classes.dropdown} ${open ? 'open' : ''}`}>
        <ul>
          {options.map((option) => (
            <li
              key={option.value}
              value={option.value}
              onClick={() => selectOption(option)}
            >
              {option.label} {optionIsSelected(option) ? <Svg.Checkmark /> : ''}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterMenu;
