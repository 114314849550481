import React from 'react';
import '../../../styles/maintenance/MaintenanceRequests.css';
import tableData from './todaysFakeData';
import * as Svg from '../../common/icons';

const TodayRequests = ({
  setReadDetails,
  setDetailsID,
  setFormOpen,
  setWorkOrderOpen
}) => {
  const handleRead = (id) => {
    setFormOpen(true);
    setReadDetails(true);
    setDetailsID(id);
    setWorkOrderOpen(false);
  };
  return (
    <table className='table-maintenance-requests'>
      <thead className='thead'>
        <tr>
          <th scope='col'></th>
          <th scope='col'>Status</th>
          <th scope='col'>Location</th>
          <th scope='col'>Created On</th>
          <th scope='col'>Due On</th>
          <th scope='col'>Created By</th>
          <th scope='col'></th>
          <th scope='col'></th>
        </tr>
      </thead>
      {tableData.length > 0 ? (
        <tbody>
          {tableData.map((data, id) => {
            return (
              <tr key={id} onClick={() => handleRead(data.id)}>
                <td>{data.title}</td>
                <td className='td-status'>
                  <p
                    className='m-0'
                    style={{
                      background:
                        data.status === 'Open'
                          ? '#47B39C'
                          : data.status === 'Not started'
                          ? '#DE6552'
                          : '#FBBE53'
                    }}
                  >
                    {data.status}
                  </p>
                </td>
                <td>{data.location}</td>
                <td>{data.createdON}</td>
                <td>{data.dueON}</td>
                <td>{data.createdBY}</td>
                <td className='p-0'>
                  <Svg.Comment />
                </td>
                <td className='p-0 text-right'>
                  <button
                    onClick={() => handleRead(data.id)}
                    style={{
                      background:
                        data.status === 'Open'
                          ? '#47B39C'
                          : data.status === 'Not started'
                          ? '#DE6552'
                          : '#FBBE53'
                    }}
                  >
                    {data.status === 'Open'
                      ? 'Accept'
                      : data.status === 'Not started'
                      ? 'Start task'
                      : 'Mark As Done'}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <p className='loading'>Loading Data...</p>
        </tbody>
      )}
    </table>
  );
};

export default TodayRequests;
