import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AntSwitch from '../../components/common/AntSwitch';
import InputTable from '../../components/managerView/efficiencyMatrics/InputTable';
import '../../styles/managerView/EfficiencyMatrics.css';
import PopUp from '../../components/managerView/efficiencyMatrics/PopUp';

const useStyles = makeStyles({
  scheduleDate: {
    width: 'auto !important',
    margin: '0 !important',
    borderBottom: '1px solid #434343 !important',
    padding: '10px 0 !important',
    color: '#47b39c  !important',
    fontFamily: 'Open Sans, sans-serif !important',
    fontSize: '14px',
    fontWeight: 500
  }
});

const EfficiencyMatrics = () => {
  const classes = useStyles();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpMSG, setPopUpMSG] = useState('');
  const [popUpType, setPopUpType] = useState(null);
  const [timeInput, setTimeInput] = useState({
    idleTime: 0,
    serviceLevelTime: 0,
    startedTaskTime: 0
  });
  const [tableTimeInput, setTableTimeInput] = useState({
    kingBed_clean: 0,
    kingBed_make: 0,
    queenBed_clean: 0,
    queenBed_make: 0,
    kingBedPatio_clean: 0,
    kingBedPatio_make: 0,
    suite_clean: 0,
    suite_make: 0,
    proSuite_clean: 0,
    proSuite_make: 0,
    twiceBed_clean: 0,
    twiceBed_make: 0,
    honeySuite_clean: 0,
    honeySuite_make: 0,
    familySuite_clean: 0,
    familySuite_make: 0
  });

  const handleTimeInput = (e) => {
    const { name, value } = e.target;
    setTimeInput((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const [dueDate, setDueDate] = useState(null);

  const [toggled, setToggled] = useState({ checked: false });

  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const handleSaveDate = () => {
    setOpenPopUp(true);
    setPopUpMSG('Efficiency metrics updated successfully');
    setPopUpType('success');
  };
  return (
    <div className='main-section efficiency-matrics'>
      <div className='efficiencyMV-head d-flex justify-content-between align-items-center'>
        <p className='main-title my-0'>Efficiency Metrics</p>
      </div>

      <div className='efficiencyMV-form'>
        <hr className='main-devider' />

        {/* Housekeeping input Table */}
        <div className='HK-table-input'>
          <p className='my-0 HK-table-title'>Housekeeping</p>
          <p className='my-0 HK-table-subTitle'>
            Maximum time housekeepers can take to clean each type of room.
          </p>
          <InputTable
            setTableTimeInput={setTableTimeInput}
            tableTimeInput={tableTimeInput}
          />
        </div>

        {/* Idle-time input */}
        <div className='efficiencyMV-input-wrap'>
          <p className='my-0 input-title'>Idle Time</p>
          <p className='my-0 input-subTitle'>
            Maximum time housekeepers can take to clean each type of room.
          </p>
          <div className='efficiencyMV-input-field'>
            <label htmlFor='Minutes'>Minutes</label>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='idleTime'
                id=''
                placeholder='e.g. 00:30'
                value={timeInput.idleTime > 0 && timeInput.idleTime}
                onChange={handleTimeInput}
              />
              {timeInput.idleTime > 0 && (
                <span className='suffix-minute'>minutes</span>
              )}
            </div>
          </div>
        </div>

        <hr className='page-form-devider' />

        {/* Service Level Assurance input */}
        <div className='efficiencyMV-input-wrap'>
          <p className='my-0 input-title'>Service Level Assurance</p>
          <p className='my-0 input-subTitle'>
            Maximum time maintenance, front desk or housekeeping can take to
            accept a guest request or maintenance request.
          </p>
          <div className='efficiencyMV-input-field'>
            <label htmlFor='Minutes'>Minutes</label>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='serviceLevelTime'
                id=''
                placeholder='e.g. 00:30'
                value={
                  timeInput.serviceLevelTime > 0 && timeInput.serviceLevelTime
                }
                onChange={handleTimeInput}
              />
              {timeInput.serviceLevelTime > 0 && (
                <span className='suffix-minute'>minutes</span>
              )}
            </div>
          </div>
        </div>

        {/* Started Task Within input */}
        <div className='efficiencyMV-input-wrap'>
          <p className='my-0 input-title'>Started Task Within</p>
          <p className='my-0 input-subTitle'>
            Maximum time maintenance can take to start working on a guest
            request or maintenance request.
          </p>
          <div className='efficiencyMV-input-field'>
            <label htmlFor='Minutes'>Minutes</label>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='startedTaskTime'
                id=''
                placeholder='e.g. 00:30'
                value={
                  timeInput.startedTaskTime > 0 && timeInput.startedTaskTime
                }
                onChange={handleTimeInput}
              />
              {timeInput.startedTaskTime > 0 && (
                <span className='suffix-minute'>minutes</span>
              )}
            </div>
          </div>
        </div>

        <hr className='page-form-devider' />

        {/* Schedule Future Changes input */}
        <div className='efficiencyMV-input-wrap'>
          <div className='input-title'>
            <Typography component='div'>
              <Grid
                component='label'
                container
                alignItems='center'
                spacing={(1, 2)}
              >
                <Grid item>
                  <span className='toggle-label'>Schedule Future Changes</span>
                </Grid>
                <Grid item>
                  <AntSwitch
                    checked={toggled.checked}
                    onChange={handleToggleChange}
                    name='checked'
                  />
                </Grid>
              </Grid>
            </Typography>
          </div>
          <p className='my-0 input-subTitle'>
            You can schedule a date to apply any changes you make in the future.
            The new metrics will be effective from that date. All Efficiencies
            will be measured based on the new updated time.
          </p>
          <div className='efficiencyMV-input-field'>
            <label htmlFor='Date'>Date</label>
            <div className='efficiencyMV-input-date'>
              <DatePicker
                className={classes.scheduleDate}
                dateFormat='MMMM d'
                placeholderText='Choose Effect Date'
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
              />
            </div>
          </div>
        </div>

        <div className='efficiencyMV-save-btn'>
          <button onClick={handleSaveDate}>Save Scheduled Date</button>
        </div>
      </div>

      {openPopUp && (
        <PopUp
          openPopUp={openPopUp}
          popUpMSG={popUpMSG}
          popUpType={popUpType}
          setOpenPopUp={setOpenPopUp}
          setPopUpMSG={setPopUpMSG}
          setPopUpType={setPopUpType}
        />
      )}
    </div>
  );
};

export default EfficiencyMatrics;
