var fakeTasks = [
  {
    id: 0,
    title: 'Third party authorization',
    category: 'Task',
    dept: 'Front Desk',
    location: '8900',
    assignedTo: 'Dwight',
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt ullam iste! Recusandae dolore et repellat praesentium, magni nihil quibusdam!'
  },
  {
    id: 1,
    title: 'Move to a different room',
    category: 'Task',
    dept: 'Front Desk',
    location: '8900',
    assignedTo: 'Dwight',
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    desc:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod, placeat eius? Vitae veniam tempora autem alias, sequi delectus nostrum deserunt optio aperiam sapiente.'
  },
  {
    id: 2,
    title: 'Want service after 12:00 PM',
    category: 'Note',
    dept: 'Housekeeping',
    location: '8900',
    assignedTo: 'Dwight',
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus suscipit praesentium quasi fugiat explicabo placeat'
  },
  {
    id: 3,
    title: 'Needs to fix AC',
    category: 'Note',
    dept: 'Maintenance',
    location: '8900',
    assignedTo: 'Dwight',
    createdBY: 'Micheals',
    dueDate: 'Dec 12',
    desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus suscipit praesentium quasi fugiat explicabo placeat'
  }
];
export default fakeTasks;
