import React from 'react';

const InputTable = ({ tableTimeInput, setTableTimeInput }) => {
  const handleTabletime = (e) => {
    const { name, value } = e.target;
    setTableTimeInput((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  return (
    <table className='table-input-HK'>
      <thead className='thead'>
        <tr>
          <th scope='col'>Room type</th>
          <th scope='col'>Maximum Time To Clean</th>
          <th scope='col'>Maximum Time To Make</th>
        </tr>
      </thead>

      <tbody>
        {/* King Bed inputs */}
        <tr>
          <td>King Bed</td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='kingBed_clean'
                id=''
                placeholder='e.g. 30 Minutes'
                value={
                  tableTimeInput.kingBed_clean > 0 &&
                  tableTimeInput.kingBed_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.kingBed_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='kingBed_make'
                id=''
                placeholder='e.g. 30 Minutes'
                value={
                  tableTimeInput.kingBed_make > 0 && tableTimeInput.kingBed_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.kingBed_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/* Queen Bed inputs */}
        <tr>
          <td>Queen Bed</td>
          <td>
            <div className='minutes-wrap'>
              {' '}
              <input
                type='number'
                name='queenBed_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.queenBed_clean > 0 &&
                  tableTimeInput.queenBed_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.queenBed_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='queenBed_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.queenBed_make > 0 &&
                  tableTimeInput.queenBed_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.queenBed_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/* King Bed With Patio inputs */}
        <tr>
          <td>King Bed With Patio</td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='kingBedPatio_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.kingBedPatio_clean > 0 &&
                  tableTimeInput.kingBedPatio_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.kingBedPatio_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='kingBedPatio_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.kingBedPatio_make > 0 &&
                  tableTimeInput.kingBedPatio_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.kingBedPatio_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/* Suite inputs */}
        <tr>
          <td>Suite</td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='suite_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.suite_clean > 0 && tableTimeInput.suite_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.suite_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='suite_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.suite_make > 0 && tableTimeInput.suite_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.suite_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/* Presidental Suite inputs */}
        <tr>
          <td>Presidental Suite</td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='proSuite_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.proSuite_clean > 0 &&
                  tableTimeInput.proSuite_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.proSuite_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='proSuite_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.proSuite_make > 0 &&
                  tableTimeInput.proSuite_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.proSuite_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/*Twice Bed inputs */}
        <tr>
          <td>Twice Bed</td>
          <td>
            <div className='minutes-wrap'>
              {' '}
              <input
                type='number'
                name='twiceBed_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.twiceBed_clean > 0 &&
                  tableTimeInput.twiceBed_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.twiceBed_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              {' '}
              <input
                type='number'
                name='twiceBed_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.twiceBed_make > 0 &&
                  tableTimeInput.twiceBed_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.twiceBed_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/*Honeymoon Suite inputs */}
        <tr>
          <td>Honeymoon Suite</td>
          <td>
            <div className='minutes-wrap'>
              {' '}
              <input
                type='number'
                name='honeySuite_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.honeySuite_clean > 0 &&
                  tableTimeInput.honeySuite_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.honeySuite_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='honeySuite_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.honeySuite_make > 0 &&
                  tableTimeInput.honeySuite_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.honeySuite_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>

        {/*Family Suite inputs */}
        <tr>
          <td>Family Suite</td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='familySuite_clean'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.familySuite_clean > 0 &&
                  tableTimeInput.familySuite_clean
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.familySuite_clean > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
          <td>
            <div className='minutes-wrap'>
              <input
                type='number'
                name='familySuite_make'
                id=''
                placeholder='Enter time here'
                value={
                  tableTimeInput.familySuite_make > 0 &&
                  tableTimeInput.familySuite_make
                }
                onChange={handleTabletime}
              />
              {tableTimeInput.familySuite_make > 0 && (
                <span className='table-suffix-minute'>minutes</span>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InputTable;
