var fakeData = [
  {
    id: 0,
    name: 'Dwight S.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 1,
    name: 'Tyler C.',
    total: 200,
    discrepancy: -10,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 2,
    name: 'Jordan N.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 3,
    name: 'Ray A.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 4,
    name: 'Connor Q.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 5,
    name: 'Shawn C.',
    total: 200,
    discrepancy: +20,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 6,
    name: 'Clyde E.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 7,
    name: 'Luke R.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 8,
    name: 'Oscar K.',
    total: 200,
    discrepancy: -10,
    cashDrop: 200,
    time: '08:12 AM'
  },
  {
    id: 9,
    name: 'Gary W.',
    total: 200,
    discrepancy: 0,
    cashDrop: 200,
    time: '08:12 AM'
  }
];
export default fakeData;
