import React, { useState } from 'react';
import oldData from './fakeSuggestions';

const AddNewForm = ({
  setFormOpen,
  setAddNewForm,
  setOpenPopUp,
  setPopUpMSG,
  setPopUpType
}) => {
  const [newRoomType, setNewRoomType] = useState({
    roomType_1: '',
    roomType_2: '',
    roomType_3: '',
    roomType_4: '',
    roomType_5: '',
    roomType_6: '',
    roomType_7: ''
  });

  //Handle Input Change
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setNewRoomType((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const matchRoomType_1 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_1.toLowerCase()
  );
  const matchRoomType_2 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_2.toLowerCase()
  );
  const matchRoomType_3 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_3.toLowerCase()
  );
  const matchRoomType_4 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_4.toLowerCase()
  );
  const matchRoomType_5 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_5.toLowerCase()
  );
  const matchRoomType_6 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_6.toLowerCase()
  );
  const matchRoomType_7 = oldData.find(
    (data) => data.title.toLowerCase() === newRoomType.roomType_7.toLowerCase()
  );

  // console.log(matchRoomType_1.title);

  //Handle Clear Button
  const handleAllClear = (e) => {
    setNewRoomType((oldState) => ({
      ...oldState,
      roomType_1: '',
      roomType_2: '',
      roomType_3: '',
      roomType_4: '',
      roomType_5: '',
      roomType_6: '',
      roomType_7: ''
    }));
  };

  //Handle Save&ADD Button
  const handleSaveAdd = (e) => {
    if (
      matchRoomType_1 !== undefined ||
      matchRoomType_2 !== undefined ||
      matchRoomType_3 !== undefined ||
      matchRoomType_4 !== undefined ||
      matchRoomType_5 !== undefined ||
      matchRoomType_6 !== undefined ||
      matchRoomType_7 !== undefined
    ) {
      setOpenPopUp(true);
      setPopUpMSG(
        'This room type already exists. You can not have two room types with same name. Please try adding a different one'
      );
      setPopUpType('danger');
    } else {
      setNewRoomType((oldState) => ({
        ...oldState,
        roomType_1: '',
        roomType_2: '',
        roomType_3: '',
        roomType_4: '',
        roomType_5: '',
        roomType_6: '',
        roomType_7: ''
      }));
      setAddNewForm(true);
      setFormOpen(true);
      setOpenPopUp(true);
      setPopUpMSG('Room type added successfully');
      setPopUpType('success');
    }
  };

  //Handle Save&Close Button
  const handleSaveClose = (e) => {
    setNewRoomType((oldState) => ({
      ...oldState,
      roomType_1: '',
      roomType_2: '',
      roomType_3: '',
      roomType_4: '',
      roomType_5: '',
      roomType_6: '',
      roomType_7: ''
    }));
    setAddNewForm(false);
    setFormOpen(false);
    setOpenPopUp(true);
    setPopUpMSG('New room type added successfully');
    setPopUpType('success');
  };
  return (
    <div>
      <div className='roomType-half-header d-flex justify-content-between align-items-center'>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='btn-save-add' onClick={handleSaveAdd}>
            Save & Add More
          </button>

          <button className='btn-save-close' onClick={handleSaveClose}>
            Save & Close
          </button>
        </div>
        <div className='btn-group' role='group' aria-label='Basic example'>
          <button className='clear-btn' onClick={handleAllClear}>
            Clear
          </button>
          <button
            type='submit'
            className='close-normal'
            onClick={() => setFormOpen(false)}
          >
            Close
          </button>
        </div>
      </div>

      {/* Field-1 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_1'
          style={{ color: newRoomType.roomType_1 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_1'
          id='roomType_1'
          placeholder='Type here'
          value={newRoomType.roomType_1}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-2 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_2'
          style={{ color: newRoomType.roomType_2 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_2'
          id='roomType_2'
          placeholder='Type here'
          value={newRoomType.roomType_2}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-3 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_3'
          style={{ color: newRoomType.roomType_3 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_3'
          id='roomType_3'
          placeholder='Type here'
          value={newRoomType.roomType_3}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-4 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_4'
          style={{ color: newRoomType.roomType_4 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_4'
          id='roomType_4'
          placeholder='Type here'
          value={newRoomType.roomType_4}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-5 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_5'
          style={{ color: newRoomType.roomType_5 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_5'
          id='roomType_5'
          placeholder='Type here'
          value={newRoomType.roomType_5}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-6 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_6'
          style={{ color: newRoomType.roomType_6 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_6'
          id='roomType_6'
          placeholder='Type here'
          value={newRoomType.roomType_6}
          onChange={handleNameChange}
        />
      </div>

      {/* Field-7 */}
      <div className='roomTypeForm-wrap'>
        <label
          htmlFor='roomType_7'
          style={{ color: newRoomType.roomType_7 !== '' && '#BDBDBD' }}
        >
          Room Type
        </label>
        <input
          type='text'
          name='roomType_7'
          id='roomType_7'
          placeholder='Type here'
          value={newRoomType.roomType_7}
          onChange={handleNameChange}
        />
      </div>
    </div>
  );
};

export default AddNewForm;
