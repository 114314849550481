import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import '../../styles/dashboard/TasksNotes.css';
import AntSwitch from '../../components/common/AntSwitch';
import TodayTasks from '../../components/dashboard/task_note/TodayTasks';
import UpcomingTasks from '../../components/dashboard/task_note/UpcomingTasks';
import OverdueTasks from '../../components/dashboard/task_note/OverdueTasks';
import TableTodaysShort from '../../components/dashboard/task_note/TableTodaysShort';
import TableOverdueShort from '../../components/dashboard/task_note/TableOverdueShort';
import TableUpcommingShort from '../../components/dashboard/task_note/TableUpcommingShort';
import AddNewForm from '../../components/dashboard/task_note/AddNewForm';
import DetailsPage from '../../components/dashboard/task_note/DetailsPage';
import FilterMenu from '../../components/common/FilterMenu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    },

    '& .MuiExpansionPanel-root': {
      padding: '0 !important',
      margin: '0 !important',
      boxShadow: 'none !important',
      '&:before': {
        display: 'none'
      },

      '& .MuiCollapse-container.MuiCollapse-entered': {
        marginTop: '-40px'
      },
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',

        '&[aria-expanded="false"] svg': {
          transform: 'rotate(-90deg)'
        }
      },

      '& .MuiExpansionPanelDetails-root': {
        padding: '0'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      bordertopLeftRadius: '4px',
      borderbottomLeftRadius: '4px',
      borderRight: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: 40
    },
    '& .more-button': {
      bordertopRightRadius: '4px',
      borderbottomRightRadius: '4px',
      borderLeft: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: 40
    }
  },
  menuItem: {
    '&.MuiListItem-button': {
      paddingRight: '45px',
      paddingLeft: '45px'
    },
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '145px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});
const options = ['Task', 'Note'];

const TasksNotes = () => {
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];

  const filterByCategoryOptions = [
    { label: 'Tasks', value: 'task' },
    { label: 'Notes', value: 'note' }
  ];

  const filterByPriorityOptions = [
    { label: 'Urgent', value: 'urgent' },
    { label: 'Important', value: 'important' },
    { label: 'Normal', value: 'normal' },
    { label: 'Complaints', value: 'complaints' },
    { label: 'Closed', value: 'closed' }
  ];
  const filterByDepartmentsOption = [
    { label: 'Front Desk', value: 'front-Desk' },
    { label: 'Housekeeping', value: 'housekeeping' }
  ];

  const classes = useStyles();
  const [pane, setPane] = useState(0);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showTodays, setShowTodays] = useState(true);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [showOverdue, setShowOverdue] = useState(true);
  const [showTodaysShort, setShowTodaysShort] = useState(true);
  const [showUpcomingShort, setShowUpcomingShort] = useState(true);
  const [showOverdueShort, setShowOverdueShort] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [addNewForm, setAddNewForm] = useState(false);
  const [readDetails, setReadDetails] = useState(false);
  const [detailsID, setDetailsID] = useState(null);
  // const [popUp, setPopUp] = useState(false);

  const [filters, setFilters] = useState({
    date: null,
    category: null,
    priority: null,
    department: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };
  const [toggled, setToggled] = useState({ checked: false });

  const handleToggleChange = (event) => {
    setToggled({ ...toggled, checked: event.target.checked });
  };

  const handleMenuItemClick = (event, index) => {
    setPane(index);
    setOpen(false);
    setFormOpen(true);
    setAddNewForm(true);
    setReadDetails(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <div className='main-section'>
      {!formOpen && (
        <>
          <div className='task-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Tasks and Notes</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                <FilterMenu
                  value={filters.category}
                  onChange={(e) => filterChange('category', e)}
                  options={filterByCategoryOptions}
                  placeholder='Filter Category'
                />
                <FilterMenu
                  value={filters.priority}
                  onChange={(e) => filterChange('priority', e)}
                  options={filterByPriorityOptions}
                  placeholder='Filter Priority'
                />
                <FilterMenu
                  value={filters.department}
                  onChange={(e) => filterChange('department', e)}
                  options={filterByDepartmentsOption}
                  placeholder='All Departments'
                />

                <FormControl className={classes.formControl}>
                  <Typography component='div' style={{ marginRight: '40px' }}>
                    <Grid
                      component='label'
                      container
                      alignItems='center'
                      spacing={1}
                    >
                      <Grid item>
                        <span className='toggle-label'>
                          Only My Tasks & Notes
                        </span>
                      </Grid>
                      <Grid item>
                        <AntSwitch
                          checked={toggled.checked}
                          onChange={handleToggleChange}
                          name='checked'
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </FormControl>
              </div>

              <div>
                <ButtonGroup
                  ref={anchorRef}
                  aria-label='split button'
                  className={classes.addNewButton}
                >
                  <Button className='main-button' onClick={handleToggle}>
                    Add New
                  </Button>
                  <Button
                    size='small'
                    className='more-button'
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label='select merge strategy'
                    aria-haspopup='menu'
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon></ArrowDropDownIcon>
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id='split-button-menu'>
                            {options.map((option, index) => (
                              <MenuItem
                                className={classes.menuItem}
                                key={option}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </div>

          {/* Announcement Table */}
          <div className='tasks-tables'>
            <hr className='main-devider' />
            <div className='task-today'>
              <p className='tasks-table-head m-0'>
                {showTodays === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodays(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodays(false)}
                  />
                )}
                Today{' '}
              </p>
              {showTodays && (
                <TodayTasks
                  setReadDetails={setReadDetails}
                  setDetailsID={setDetailsID}
                  setFormOpen={setFormOpen}
                  setAddNewForm={setAddNewForm}
                />
              )}
            </div>
            <div className='task-overdue'>
              <p className='tasks-table-head m-0'>
                {showOverdue === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdue(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdue(false)}
                  />
                )}
                Overdue{' '}
              </p>
              {showOverdue && <OverdueTasks />}
            </div>
            <div className='task-upcomming'>
              <p className='tasks-table-head m-0'>
                {showUpcoming === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcoming(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcoming(false)}
                  />
                )}
                Upcoming{' '}
              </p>
              {showUpcoming && <UpcomingTasks />}
            </div>
          </div>
        </>
      )}

      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Announcements & Memo</p>
              <hr className='short-devider' />
              <div className='task-today'>
                <p className='tasks-table-head m-0'>
                  {showTodaysShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodaysShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodaysShort(false)}
                    />
                  )}
                  Today{' '}
                </p>
                {showTodaysShort && (
                  <TableTodaysShort
                    setReadDetails={setReadDetails}
                    setDetailsID={setDetailsID}
                    setFormOpen={setFormOpen}
                    setAddNewForm={setAddNewForm}
                  />
                )}
              </div>
              <div className='task-overdue'>
                <p className='tasks-table-head m-0'>
                  {showOverdueShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueShort(false)}
                    />
                  )}
                  Overdue
                </p>
                {showOverdueShort && <TableOverdueShort />}
              </div>
              <div className='task-upcomming'>
                <p className='tasks-table-head m-0'>
                  {showUpcomingShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingShort(false)}
                    />
                  )}
                  Upcoming
                </p>
                {showUpcomingShort && <TableUpcommingShort />}
              </div>
            </div>
            <div className='forms-section'>
              {addNewForm && (
                <AddNewForm setFormOpen={setFormOpen} pane={pane} />
              )}
              {readDetails && (
                <DetailsPage
                  detailsID={detailsID}
                  setFormOpen={setFormOpen}
                  setReadDetails={setReadDetails}
                  setAddNewForm={setAddNewForm}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(TasksNotes);
