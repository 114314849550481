import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterMenu from '../../components/common/FilterMenu';
import '../../styles/maintenance/PreventiveMaintenance.css';
import TodayTable from '../../components/maintenance/preventive-maintenance/TodayTable';
import OverdueTable from '../../components/maintenance/preventive-maintenance/OverdueTable';
import UpcomingTable from '../../components/maintenance/preventive-maintenance/UpcomingTable';
import TodayTableShort from '../../components/maintenance/preventive-maintenance/TodayTableShort';
import OverdueTableShort from '../../components/maintenance/preventive-maintenance/OverdueTableShort';
import UpcomingTableShort from '../../components/maintenance/preventive-maintenance/UpcomingTableShort';
import DetailsPage from '../../components/maintenance/preventive-maintenance/DetailsPage';
import CreatePreMaintenance from '../../components/maintenance/preventive-maintenance/CreatePreMaintenance';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    '& .tables': {
      width: '100%'
    },
    '& .add-pane': {
      display: 'none',
      width: '100%',
      padding: '32px 40px !important',

      '& .MuiFormControl-root': {
        // width: '100%'
      },

      '& .header': {
        position: 'relative',
        marginBottom: '8px',
        '& .close': {
          position: 'absolute',
          right: 0,
          color: '#7F68DD',
          fontWeight: 500,
          cursor: 'pointer'
        }
      }
    },

    '&.show-add': {
      '& .tables': {
        width: '50%',
        maxWidth: '394px',
        marginRight: '24px',
        minWidth: '350px'
      },
      '& .add-pane': {
        display: 'block'
      }
    }
  },

  addNewButton: {
    '& .main-button': {
      borderRadius: '4px',
      padding: '0px 24px',
      backgroundColor: '#434343',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: 500,
      height: '45px',
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  menuItem: {
    '&.MuiListItem-button:hover': {
      textDecoration: 'none',
      backgroundColor: '#434343',
      color: '#FFFFFF'
    }
  },
  formControl: {
    display: 'inline-block',
    minWidth: '150px',
    marginRight: '0px',
    '& .MuiInputBase-root': {
      width: '100%',
      '&:after': {
        display: 'none !important'
      },
      '&:before': {
        border: 'none !important'
      }
    }
  }
});
const PreventiveMaintenance = () => {
  const classes = useStyles();
  const filterByDateOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Overdue', value: 'overdue' }
  ];
  const filterByTasksOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Not Started', value: 'not-started' },
    { label: 'In Progress', value: 'in-progress' }
  ];
  const [showTodayTable, setShowTodayTable] = useState(true);
  const [showOverdueTable, setShowOverdueTable] = useState(true);
  const [showUpcomingTable, setShowUpcomingTable] = useState(true);
  const [showTodayTableShort, setShowTodayTableShort] = useState(true);
  const [showOverdueTableShort, setShowOverdueTableShort] = useState(true);
  const [showUpcomingTableShort, setShowUpcomingTableShort] = useState(true);
  const [createMaintenanceOpen, setCreateMaintenanceOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [readDetails, setReadDetails] = useState(false);
  const [detailsID, setDetailsID] = useState(null);
  const [filters, setFilters] = useState({
    date: null,
    tasks: null,
    private: false
  });
  const filterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleAddNewClick = (event, index) => {
    setFormOpen(true);
    setCreateMaintenanceOpen(true);
    setReadDetails(false);
  };
  return (
    <div className='preventive-maintenance main-section'>
      {!formOpen && (
        <>
          {' '}
          <div className='preventive-maintenance-head d-flex justify-content-between align-items-center'>
            <p className='main-title my-0'>Preventive Maintenance</p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='filters'>
                <FilterMenu
                  value={filters.date}
                  onChange={(e) => filterChange('date', e)}
                  options={filterByDateOptions}
                  placeholder='Filter Date'
                />
                <FilterMenu
                  value={filters.tasks}
                  onChange={(e) => filterChange('tasks', e)}
                  options={filterByTasksOptions}
                  placeholder='Filter Tasks'
                />
              </div>

              <div className={classes.addNewButton}>
                <Button className='main-button' onClick={handleAddNewClick}>
                  Create Preventive Maintenance
                </Button>
              </div>
            </div>
          </div>
          {/*Preventive Maintenance Tables */}
          <div className='preventive-maintenance-tables'>
            <hr className='main-devider' />
            <div className='today-maintenance'>
              <p className='preventive-table-head m-0'>
                {showTodayTable === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodayTable(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowTodayTable(false)}
                  />
                )}
                Today{' '}
              </p>
              {showTodayTable && (
                <TodayTable
                  setReadDetails={setReadDetails}
                  setDetailsID={setDetailsID}
                  setFormOpen={setFormOpen}
                  setCreateMaintenanceOpen={setCreateMaintenanceOpen}
                />
              )}
            </div>
            <div className='overdue-maintenance'>
              <p className='preventive-table-head m-0'>
                {showOverdueTable === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdueTable(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowOverdueTable(false)}
                  />
                )}
                Overdue
              </p>
              {showOverdueTable && <OverdueTable />}
            </div>

            <div className='upcomming-maintenance mb-4'>
              <p className='preventive-table-head m-0'>
                {showUpcomingTable === false ? (
                  <ArrowDropUpIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcomingTable(true)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className='aria-expand-btn'
                    fontSize='large'
                    onClick={() => setShowUpcomingTable(false)}
                  />
                )}
                Upcoming
              </p>
              {showUpcomingTable && <UpcomingTable />}
            </div>
          </div>
        </>
      )}

      {formOpen && (
        <>
          <div className='d-flex mb-4'>
            <div className='half-tables p-4 mr-2'>
              <p className='main-title-short my-0'>Preventive Maintenance</p>
              <hr className='short-devider' />
              <div className='today-maintenance'>
                <p className='preventive-table-head m-0'>
                  {showTodayTableShort === true ? (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodayTableShort(false)}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowTodayTableShort(true)}
                    />
                  )}
                  Today
                </p>
                {showTodayTableShort && (
                  <TodayTableShort
                    setReadDetails={setReadDetails}
                    setDetailsID={setDetailsID}
                    setFormOpen={setFormOpen}
                    setCreateMaintenanceOpen={setCreateMaintenanceOpen}
                  />
                )}
              </div>
              <div className='overdue-maintenance'>
                <p className='preventive-table-head m-0'>
                  {showOverdueTableShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueTableShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowOverdueTableShort(false)}
                    />
                  )}
                  Overdue
                </p>
                {showOverdueTableShort && <OverdueTableShort />}
              </div>
              <div className='upcomming-maintenance'>
                <p className='preventive-table-head m-0'>
                  {showUpcomingTableShort === false ? (
                    <ArrowDropUpIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingTableShort(true)}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className='aria-expand-btn'
                      fontSize='large'
                      onClick={() => setShowUpcomingTableShort(false)}
                    />
                  )}
                  Upcoming
                </p>
                {showUpcomingTableShort && <UpcomingTableShort />}
              </div>
            </div>

            <div className='forms-section'>
              {createMaintenanceOpen && (
                <CreatePreMaintenance
                  setFormOpen={setFormOpen}
                  setCreateMaintenanceOpen={setCreateMaintenanceOpen}
                />
              )}
              {readDetails && (
                <DetailsPage
                  detailsID={detailsID}
                  setFormOpen={setFormOpen}
                  setReadDetails={setReadDetails}
                  detailsDrawerOpen={detailsDrawerOpen}
                  setDetailsDrawerOpen={setDetailsDrawerOpen}
                  setCreateMaintenanceOpen={setCreateMaintenanceOpen}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PreventiveMaintenance;
